import { createActor } from "~/state/actor";
import { type SystemKey, createVisualizationRootMachine, type Options } from "./machine";
import { createEpic } from "./epic";
import { actorSystem } from "~/state";
import { assign } from "xstate";
import { tracking } from "~/utils/tracking";

export const createVisualizationRootActor = createActor(
  { createMachine: createVisualizationRootMachine, createEpic },
  ({ epic, eventBus }): Options => ({
    actions: {
      updateVisualization: assign((ctx, e) => {
        return {
          visualization: {
            ...ctx.visualization,
            ...e.payload.visualization,
          },
        };
      }),
      setVisData: assign((context, event) => {
        return {
          visualization: {
            ...context.visualization,
            title: event.payload.name,
          },
        };
      }),
      persistVisualizationEpic: (ctx, e) => {
        epic.send({
          type: "VISUALIZATION.EPIC.UPDATE",
          payload: { visId: ctx.visualization.id },
        });
      },
      deleteVisEpic: (context) => {
        epic.send({
          type: "VISUALIZATION.EPIC.DELETE",
          payload: {
            visualizationId: context.visualization.id,
          },
        });
      },
      broadcastUpdate: (ctx) => {
        eventBus.send({
          type: "GLOBAL.VISUALIZATION.UPDATE",
          payload: { visualization: ctx.visualization, query: { id: ctx.visualization.queryId } },
        });
      },
      broadcastDelete: (ctx) => {
        eventBus.send({
          type: "GLOBAL.VISUALIZATION.DELETE",
          payload: { visualization: ctx.visualization, query: { id: ctx.visualization.queryId } },
        });
      },
      trackingDelete: (context) => {
        tracking("delete_visualization", "Query Editor", {
          query_id: context.visualization.queryId!,
          visualization_id: context.visualization.id,
        });
      },
    },
    guards: {
      isWorkItemId: (ctx, e) => {
        return e.id === ctx.visualization?.id;
      },
      isVisualization: (ctx, e) => {
        return e.payload.visualization.id === ctx.visualization?.id;
      },
    },
  }),
);

export type Actor = ReturnType<typeof createVisualizationRootActor>;

export const getVisualizationRootActor = (id: SystemKey) => {
  return actorSystem.get<Actor>(id);
};
