import { Button } from "@fscrypto/ui";
import { XIcon } from "lucide-react";
import { tracking } from "~/utils/tracking";

export const RemoveCellButton = ({
  cellId,
  dashboardId,
  onRemoveCell,
}: {
  cellId?: string;
  dashboardId: string;
  onRemoveCell: (id: string) => void;
}) => {
  return (
    <Button
      variant="secondary"
      size="xs"
      iconOnly
      draggable="true"
      onDragStart={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      onMouseDown={() => {
        if (cellId) {
          onRemoveCell(cellId);
          tracking("remove_cell", "Dashboard Editor", { dashboard_id: dashboardId });
        }
      }}
    >
      <XIcon className="text-content size-4" />
    </Button>
  );
};
