import { useState } from "react";

interface SelectInputProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  restrictedValues: string;
  disabled: boolean;
}

export const SelectInput = ({ name, value, onChange, restrictedValues, disabled }: SelectInputProps) => {
  const values = restrictedValues.split(",");
  const [length, setLength] = useState(values[0]?.length || 50);
  return (
    <div className="mr-2 flex items-stretch rounded-md">
      <div className="bg-gray-10 text-gray-70 dark:border-gray-80 relative -ml-px rounded-l-md border-y border-l border-r-0 p-1.5 text-xs dark:bg-gray-100">
        <span className="dark:text-gray-30 px-1 text-xs">{name}</span>
      </div>
      <select
        style={{ width: `${length * 9 + 50}px`, minWidth: "32px" }}
        className="dark:bg-gray-90 dark:border-gray-80 dark:text-gray-40 min-w-4 rounded-none rounded-r-md border pl-2 sm:text-sm"
        defaultValue={values[0]}
        onChange={(e) => {
          setLength(e.target.value.length);
          onChange(e);
        }}
        disabled={disabled}
        value={value}
      >
        {values.map((value) => (
          <option key={value}>{value}</option>
        ))}
      </select>
    </div>
  );
};
