import { query, visualization } from "@fscrypto/domain";
import type { Profile } from "@fscrypto/domain/profile";
import type { Query } from "@fscrypto/domain/query";
import type { Visualization } from "@fscrypto/domain/visualization";
import { Client } from "@fscrypto/http";
import type { Data } from "@fscrypto/viz";
import { $path } from "remix-routes";
import type { Observable } from "rxjs";
import { map, switchMap, merge, of, catchError, from } from "rxjs";
import { ofType } from "~/state/epics";

export type EpicEvent = FetchVisualization | FetchVisualizationSuccess | FetchVisualizationFailure;

type FetchVisualization = { type: "DASHBOARD_VISUALIZATION.EPIC.FETCH"; payload: { visId: string } };
type FetchVisualizationSuccess = {
  type: "DASHBOARD_VISUALIZATION.EPIC.FETCH_SUCCESS";
  payload: {
    visualization?: Visualization;
    visData?: Data[];
    owner?: Profile;
    updatedAt: Date;
    query?: Query;
  };
};
type FetchVisualizationFailure = { type: "DASHBOARD_VISUALIZATION.EPIC.FETCH_FAILURE"; error: Error };

export const createEpics = (actions$: Observable<EpicEvent>): Observable<EpicEvent> => {
  return merge(fetchVisualizationEpic(actions$));
};

const fetchVisualizationEpic = (action$: Observable<EpicEvent>) => {
  return action$.pipe(
    ofType("DASHBOARD_VISUALIZATION.EPIC.FETCH"),
    switchMap(({ payload }) =>
      from(fetchVisualization(payload.visId)).pipe(
        map((r) => ({ type: "DASHBOARD_VISUALIZATION.EPIC.FETCH_SUCCESS", payload: r }) as FetchVisualizationSuccess),
        catchError((_) =>
          of({
            type: "DASHBOARD_VISUALIZATION.EPIC.FETCH_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as FetchVisualizationFailure),
        ),
      ),
    ),
  ) as Observable<EpicEvent>;
};

const client = new Client();

const fetchVisualization = async (visId: string) => {
  try {
    const data = await client.get<{
      query: Query;
      owner: Profile;
      visualization: any;
      visData: Data[];
    }>($path("/api/dashboards/visualizations/:id/get", { id: visId }));
    if (data.visualization.version === "3") {
      return {
        query: query.schema.parse(data.query),
        owner: data.owner,
        visualization: visualization.v3.schema.parse(data.visualization),
        updatedAt: data.visualization.updatedAt,
        visData: data.visData,
      };
    }
    return {
      query: query.schema.parse(data.query),
      owner: data.owner,
      visualization: visualization.schema.parse(data.visualization),
      updatedAt: data.visualization.updatedAt,
      visData: data.visData,
    };
  } catch (e) {
    throw new Error("Error Updating last refreshed At");
  }
};
