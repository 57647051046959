import type { Cell, ComponentType } from "@fscrypto/domain/dashboard";
import { useSelector } from "@xstate/react";
import type { ActorRefFrom, StateFrom } from "xstate";
import { assign, createMachine, toActorRef } from "xstate";
import { actorSystem } from "~/state";
import deepEquals from "fast-deep-equal";
import type { Layout } from "react-grid-layout";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";
import type { DashboardActorRef } from "~/state/machines/dashboard/dashboard.machine";
import type { DashboardTabsState } from "../dashboard-tabs/dashboard-tabs.machine";
import type { CellWithRef } from "./util";
import { createLayout, filterTabCells, spawnPanelMachine } from "./util";

export const createDashboardGridMachine = (draftCells: Cell[], publishedCells: Cell[], dashboardId: string) => {
  const machine = createMachine(
    {
      id: "dashboardCellsMachine",
      tsTypes: {} as import("./dashboard-grid.machine.typegen").Typegen0,
      schema: {
        context: {} as DashboardContext,
        events: {} as DashboardGridRefEvent | GlobalEvent,
      },
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      context: {
        draftCells: [],
        publishedCells: [],
      },
      initial: "initializeMachines",
      states: {
        idle: {},
        initializeMachines: {
          always: {
            target: "idle",
            actions: "initializeMachines",
          },
        },
      },
      on: {
        "DASHBOARD.GRID.UPDATE_LAYOUT": {
          actions: ["updateLayout", "persistLayout"],
        },
        "DASHBOARD.GRID.ADD_CELL": {
          actions: ["addCell", "refetchParameters", "persistLayout"],
        },
        "DASHBOARD.GRID.REMOVE_CELL": {
          actions: ["removeCell", "refetchParameters", "persistLayout"],
        },
        "DASHBOARD.GRID.ACTIVATE_TAB_MODE": {
          actions: ["activateTabMode", "persistLayout"],
        },
        "DASHBOARD.GRID.REMOVE_TAB_CELLS": {
          actions: ["removeTabCells"],
        },
        "DASHBOARD.GRID.UPDATE_CELL_FORMULA": {
          actions: ["updateCellFormula", "persistLayout"],
        },
        "DASHBOARD.GRID.MOVE_CELL_TO_TAB": {
          actions: ["moveCellToTab"],
        },
        "DASHBOARD.GRID.UPDATE_STYLES": {
          actions: ["updateCellStyles", "persistLayout"],
        },
        "GLOBAL.DASHBOARD_REALTIME.UPDATE_DATA": {
          actions: ["updateDataFromRealtime"],
          cond: "isDashboard",
        },
        "GLOBAL.DASHBOARD_PUBLISH.PUBLISH_SUCCESS": {
          description: "When a dashboard is published, we want to save the current draft cells as the published cells",
          actions: ["createPublishedCells"],
        },
        "GLOBAL.ADD_TO_DASHBOARD.ADD_CELL": {
          actions: ["addExternalCell", "refetchParameters"],
          cond: "isDashboard",
        },
      },
    },
    {
      actions: {
        initializeMachines: assign((_) => {
          const cellsWithRef = draftCells.map((cell) => ({ ...cell, ref: spawnPanelMachine(cell, dashboardId) }));
          const publishedCellsWithRef = publishedCells.map((cell) => ({
            ...cell,
            ref: spawnPanelMachine(cell, dashboardId),
          }));
          return { draftCells: cellsWithRef, publishedCells: publishedCellsWithRef };
        }),
        updateLayout: assign((context, event) => {
          const { layout } = event.payload;
          const newCells = context.draftCells.map((cell: CellWithRef) => {
            if (cell.type === "component") {
              const layoutComponent = layout.find((component: { i: string }) => component.i === cell.id);
              if (layoutComponent) {
                let { x, y, w, h } = layoutComponent;
                return { ...cell, component: { ...cell.component, x, y, w, h, format: "grid" } };
              }
            }
            return cell;
          });
          return { draftCells: newCells };
        }),
        updateDataFromRealtime: assign((context, event) => {
          const draft = event.payload.dashboardData.draft;
          const updatedCells = context.draftCells.map((cell: CellWithRef) => {
            const updatedCell = draft.cells.find((c) => c.id === cell.id);
            if (updatedCell) {
              // determineChangesAndInformCellMachines(cell, updatedCell);
              return { ...cell, ...updatedCell };
            }
            return cell;
          });
          // Identify new cells
          const existingCellIds = new Set(context.draftCells.map((cell: CellWithRef) => cell.id));
          const newCells = draft.cells.filter((cell) => !existingCellIds.has(cell.id));
          const newCellsWithRef = newCells.map((cell) => ({ ...cell, ref: spawnPanelMachine(cell, dashboardId) }));

          // Identify cells to remove
          const newCellIds = new Set(draft.cells.map((cell) => cell.id));
          const cellsToRemove = context.draftCells.filter((cell: CellWithRef) => !newCellIds.has(cell.id));

          // Combine updated, new, and remaining cells
          const finalCells = [
            ...updatedCells.filter((cell: CellWithRef) => !cellsToRemove.includes(cell)),
            ...newCellsWithRef,
          ];
          return {
            draftCells: finalCells,
          };
        }),
        persistLayout: (context) => {
          const currentDashboard = actorSystem.get<DashboardActorRef>(`dashboard-${dashboardId}`)?.getSnapshot()
            ?.context?.dashboard;
          if (currentDashboard) {
            const { draft } = currentDashboard;
            const currentCells = draft?.cells ?? [];
            const newCells = context.draftCells.map(({ ref: _ref, ...rest }) => rest);
            if (!deepEquals(currentCells, newCells)) {
              eventBus.send({
                type: "GLOBAL.DASHBOARD.SET_DATA",
                payload: { dashboard: { draft: { ...draft, cells: newCells } } },
                dashboardId,
              });
            }
          }
        },
        addCell: assign((context, event) => {
          const { type, position, formula, layout } = event.payload;

          const activeDraftTabId = actorSystem
            .get<DashboardActorRef>(`dashboard-${dashboardId}`)
            ?.getSnapshot()
            ?.context?.dashboardTabs?.getSnapshot()?.context.activeDraftTabId;
          const cellId = type + Math.random();

          const { x, y, w, h } = position;
          const newCell: Cell = {
            id: cellId,
            type: "component",
            component: { type, x, y, w, h, i: cellId, format: "grid", t: activeDraftTabId },
            formula,
          };
          const newCellWithRef = { ...newCell, ref: spawnPanelMachine(newCell, dashboardId) };
          // get the new layout coords and add them to the existing cell in context
          const newCells: CellWithRef[] = [...context.draftCells, newCellWithRef].map((cell) => {
            if (cell.component.t !== activeDraftTabId) return cell;
            if (cell.type === "component") {
              const layoutComponent = layout.find((component) => component.i === cell.id);
              if (layoutComponent) {
                let { x, y, w, h } = layoutComponent;
                return { ...cell, component: { ...cell.component, x, y, w, h } };
              }
              const droppedComponent = layout.find((component) => component.i === "dropped_item");
              if (droppedComponent) {
                let { x, y, w, h } = droppedComponent;
                return { ...cell, component: { ...cell.component, x, y, w, h } };
              }
            }
            return cell;
          });
          return { draftCells: newCells };
        }),
        addExternalCell: assign((context, event) => {
          const { cell } = event.payload;
          const activeDraftTabId = actorSystem
            .get<DashboardActorRef>(`dashboard-${dashboardId}`)
            ?.getSnapshot()
            ?.context?.dashboardTabs?.getSnapshot()?.context.activeDraftTabId;
          cell.component.t = activeDraftTabId;
          const newCellWithRef = { ...cell, ref: spawnPanelMachine(cell, dashboardId) };
          const newCells = [...context.draftCells, newCellWithRef];
          return { draftCells: newCells };
        }),
        removeCell: assign((context, event) => {
          const { cellId } = event.payload;
          const newCells = context.draftCells.filter((cell) => cell.id !== cellId);
          return { draftCells: newCells };
        }),
        activateTabMode: assign((context, event) => {
          const newCells = context.draftCells.map((cell) => {
            const newCellComponent = { ...cell.component, t: event.payload.activeTabId };
            return { ...cell, component: newCellComponent };
          });
          return { draftCells: newCells };
        }),
        removeTabCells: assign((context, event) => {
          if (event.payload.isLast) {
            const newCells = context.draftCells.map((cell) => {
              const newCellComponent = { ...cell.component, t: undefined };
              return { ...cell, component: newCellComponent };
            });
            return { draftCells: newCells };
          }
          const newCells = context.draftCells.filter((cell) => cell.component.t !== event.payload.tabId);
          return { draftCells: newCells };
        }),
        createPublishedCells: assign((context) => {
          return { publishedCells: context.draftCells };
        }),
        updateCellFormula: assign((context, event) => {
          const { cellId, formula } = event.payload;
          const newCells = context.draftCells.map((cell) => {
            if (cell.id === cellId) {
              return { ...cell, formula };
            }
            return cell;
          });
          return { draftCells: newCells };
        }),
        moveCellToTab: assign((context, event) => {
          const { cellId, tabId } = event.payload;
          const newCells = context.draftCells.map((cell) => {
            if (cell.id === cellId) {
              return { ...cell, component: { ...cell.component, t: tabId } };
            }
            return cell;
          });
          return { draftCells: newCells };
        }),
        updateCellStyles: assign((context, event) => {
          const { cellId, styles } = event.payload;
          const newCells = context.draftCells.map((cell) => {
            if (cell.id === cellId) {
              return { ...cell, styles: { ...cell.styles, ...styles } };
            }
            return cell;
          });
          return { draftCells: newCells };
        }),
        refetchParameters: () => {
          eventBus.send({ type: "GLOBAL.DASHBOARD_PARAMETERS.REFETCH_PARAMETERS", dashboardId });
        },
      },
      services: {
        globalEvents: () => eventBus.events$,
      },
      guards: {
        isDashboard: (context, event) => {
          return event.dashboardId === dashboardId;
        },
      },
    },
  );
  return machine;
};

interface DashboardContext {
  draftCells: CellWithRef[];
  publishedCells: CellWithRef[];
}

export type DashboardGridActorRef = ActorRefFrom<ReturnType<typeof createDashboardGridMachine>>;
export type DashboardGridState = StateFrom<ReturnType<typeof createDashboardGridMachine>>;

type DashboardGridRefEvent =
  | { type: "DASHBOARD.GRID.UPDATE_LAYOUT"; payload: { layout: Layout[] } }
  | { type: "DASHBOARD.GRID.ADD_CELL"; payload: AddCellToDashboardArgs }
  | { type: "DASHBOARD.GRID.REMOVE_CELL"; payload: { cellId: string } }
  | { type: "DASHBOARD.GRID.ACTIVATE_TAB_MODE"; payload: { activeTabId: string } }
  | { type: "DASHBOARD.GRID.REMOVE_TAB_CELLS"; payload: { tabId: string; isLast: boolean } }
  | { type: "DASHBOARD.GRID.UPDATE_CELL_FORMULA"; payload: { cellId: string; formula: Cell["formula"] } }
  | { type: "DASHBOARD.GRID.MOVE_CELL_TO_TAB"; payload: { cellId: string; tabId: string } }
  | { type: "DASHBOARD.GRID.UPDATE_STYLES"; payload: { cellId: string; styles: Cell["styles"] } };

export interface AddCellToDashboardArgs {
  type: ComponentType;
  formula: Cell["formula"];
  position: Pick<ReactGridLayout.Layout, "x" | "y" | "w" | "h">;
  layout: ReactGridLayout.Layout[];
}

export const useDashboardGridMachine = (dashboardId: string, width: number, variant: "draft" | "published") => {
  const baseRef = actorSystem.get<DashboardActorRef>(`dashboard-${dashboardId}`);
  const dashboardRef = baseRef ?? toActorRef({ send: () => {} });
  const dashboardCellsRef = dashboardRef?.getSnapshot()?.context.dashboardGrid ?? toActorRef({ send: () => {} });

  const dashboardTabsRef = dashboardRef?.getSnapshot()?.context.dashboardTabs ?? toActorRef({ send: () => {} });

  const draftCells = useSelector(dashboardCellsRef, draftCellsSelector, deepEquals);
  const publishedCells = useSelector(dashboardCellsRef, publishedCellsSelector, deepEquals);
  const activeDraftTabId = useSelector(dashboardTabsRef, activeDraftTabIdSelector);
  const activePublishedTabId = useSelector(dashboardTabsRef, activePublishedTabIdSelector);

  const activeTabId = variant === "draft" ? activeDraftTabId : activePublishedTabId;
  const updateLayout = (layout: Layout[]) =>
    dashboardCellsRef.send({ type: "DASHBOARD.GRID.UPDATE_LAYOUT", payload: { layout } });
  const moveCellToTab = (cellId: string, tabId: string) =>
    dashboardCellsRef.send({ type: "DASHBOARD.GRID.MOVE_CELL_TO_TAB", payload: { cellId, tabId } });
  const addCell = (data: AddCellToDashboardArgs) =>
    dashboardCellsRef.send({ type: "DASHBOARD.GRID.ADD_CELL", payload: data });
  const removeCell = (cellId: string) =>
    dashboardCellsRef.send({ type: "DASHBOARD.GRID.REMOVE_CELL", payload: { cellId } });
  const cells = variant === "draft" ? draftCells : publishedCells;
  let layout = createLayout(cells, width, activeTabId) as Layout[];
  const tabCells = filterTabCells({ cells, activeTabId });
  const isReady = useSelector(dashboardCellsRef, isReadySelector);
  return {
    cells: tabCells,
    layout,
    updateLayout,
    moveCellToTab,
    addCell,
    activeTabId,
    removeCell,
    isReady,
  };
};

const draftCellsSelector = (state: DashboardGridState) => state?.context?.draftCells ?? [];
const publishedCellsSelector = (state: DashboardGridState) => state?.context?.publishedCells ?? [];
const isReadySelector = (state: DashboardGridState) => state?.matches("idle");
const activeDraftTabIdSelector = (state: DashboardTabsState) => state?.context?.activeDraftTabId;
const activePublishedTabIdSelector = (state: DashboardTabsState) => state?.context?.activePublishedTabId;
