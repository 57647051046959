import type { DashboardPublished } from "@fscrypto/domain/dashboard";
import { $path } from "remix-routes";
import type { Observable } from "rxjs";
import { map, switchMap, merge, of, catchError, from } from "rxjs";
import { POST } from "~/async/fetch";
import { ofType } from "~/state/epics";

export type Event =
  | PublishDashboard
  | PublishDashboardSuccess
  | PublishDashboardFailure
  | UnPublishDashboard
  | UnPublishDashboardSuccess
  | UnPublishDashboardFailure;

type PublishDashboard = { type: "DASHBOARD_PUBLISH.EPIC.PUBLISH"; payload: { dashboardId: string } };
type PublishDashboardSuccess = { type: "DASHBOARD_PUBLISH.EPIC.PUBLISH_SUCCESS"; payload: DashboardPublished };
type PublishDashboardFailure = { type: "DASHBOARD_PUBLISH.EPIC.PUBLISH_FAILURE"; error: Error };

type UnPublishDashboard = { type: "DASHBOARD_PUBLISH.EPIC.UNPUBLISH"; payload: { dashboardId: string } };
type UnPublishDashboardSuccess = { type: "DASHBOARD_PUBLISH.EPIC.UNPUBLISH_SUCCESS"; payload: DashboardPublished };
type UnPublishDashboardFailure = { type: "DASHBOARD_PUBLISH.EPIC.UNPUBLISH_FAILURE"; error: Error };

export const createEpics = (actions$: Observable<Event>): Observable<Event> => {
  return merge(publishDashboardEpic(actions$), unPublishDashboardEpic(actions$));
};

const publishDashboardEpic = (action$: Observable<Event>) => {
  return action$.pipe(
    ofType("DASHBOARD_PUBLISH.EPIC.PUBLISH"),
    switchMap(({ payload }) =>
      from(publishDashboard(payload.dashboardId)).pipe(
        map((r) => ({ type: "DASHBOARD_PUBLISH.EPIC.PUBLISH_SUCCESS", payload: r }) as PublishDashboardSuccess),
        catchError((_) =>
          of({
            type: "DASHBOARD_PUBLISH.EPIC.PUBLISH_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as PublishDashboardFailure),
        ),
      ),
    ),
  ) as Observable<Event>;
};

const unPublishDashboardEpic = (action$: Observable<Event>) => {
  return action$.pipe(
    ofType("DASHBOARD_PUBLISH.EPIC.UNPUBLISH"),
    switchMap(({ payload }) =>
      from(unPublishDashboard(payload.dashboardId)).pipe(
        map((r) => ({ type: "DASHBOARD_PUBLISH.EPIC.UNPUBLISH_SUCCESS", payload: r }) as UnPublishDashboardSuccess),
        catchError((_) =>
          of({
            type: "DASHBOARD_PUBLISH.EPIC.UNPUBLISH_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as UnPublishDashboardFailure),
        ),
      ),
    ),
  ) as Observable<Event>;
};

// ASYNC API CALLS

const publishDashboard = async (dashboardId: string): Promise<DashboardPublished> => {
  try {
    const data = await POST<DashboardPublished>($path("/api/dashboards/:id/publish", { id: dashboardId }));
    return data;
  } catch (e) {
    throw new Error("Error Publishing Dashboard");
  }
};

const unPublishDashboard = async (dashboardId: string): Promise<DashboardPublished> => {
  try {
    const data = await POST<DashboardPublished>($path("/api/dashboards/:id/unpublish", { id: dashboardId }));
    return data;
  } catch (e) {
    throw new Error("Error Publishing Dashboard");
  }
};
