import { createSimpleEpic, ofType } from "@fscrypto/state-management";
import { type Observable, merge, debounceTime, switchMap, from, map, catchError, of } from "rxjs";
import { visClient } from "../../data/visualization-client";
import { Visualization } from "@fscrypto/domain/visualization";

// Input events, triggers epics
type UpdateVisualizationRealtime = {
  type: "VISUALIZATION.EPIC.UPDATE";
  payload: { visId: string };
};

type DeleteVisualization = { type: "VISUALIZATION.EPIC.DELETE"; payload: { visualizationId: string } };

// Output events, sent to the machine
type UpdateVisualizationRealtimeSuccess = { type: "VISUALIZATION.EPIC.UPDATE_SUCCESS" };
type UpdateVisualizationRealtimeFailure = { type: "VISUALIZATION.EPIC.UPDATE_FAILURE"; payload: Error };

type DeleteVisualizationSuccess = { type: "VISUALIZATION.EPIC.DELETE_SUCCESS"; payload: Visualization };
type DeleteVisualizationFailure = { type: "VISUALIZATION.EPIC.DELETE_FAILURE"; payload: Error };

export type OutputEvent =
  | UpdateVisualizationRealtimeSuccess
  | UpdateVisualizationRealtimeFailure
  | DeleteVisualizationSuccess
  | DeleteVisualizationFailure;

type InputEvent = UpdateVisualizationRealtime | DeleteVisualization;

/**
 * Merges multiple epic functions into a single Observable<OutputEvent>.
 * Merging these epics into one observable allows using a single subject for capturing all events.
 */
export const createEpic = (actions$: Observable<InputEvent>): Observable<OutputEvent> => {
  return merge(updateVisualizationRealtimeEpic(actions$), deleteVisualizationEpic(actions$));
};

const updateVisualizationRealtimeEpic = (action$: Observable<InputEvent>): Observable<OutputEvent> => {
  return action$.pipe(
    ofType("VISUALIZATION.EPIC.UPDATE"),
    debounceTime(500),
    switchMap(({ payload }) => {
      return from(visClient.persistVisualization(payload.visId)).pipe(
        map(
          (r) =>
            ({
              type: "VISUALIZATION.EPIC.UPDATE_SUCCESS",
              payload: r,
            }) as UpdateVisualizationRealtimeSuccess,
        ),
        catchError((e) =>
          of({
            type: "VISUALIZATION.EPIC.UPDATE_FAILURE",
            payload: e,
          } as UpdateVisualizationRealtimeFailure),
        ),
      );
    }),
  ) as Observable<OutputEvent>;
};

const deleteVisualizationEpic = createSimpleEpic<
  DeleteVisualization,
  Visualization,
  DeleteVisualizationSuccess,
  DeleteVisualizationFailure
>({
  ofType: "VISUALIZATION.EPIC.DELETE",
  performTask: async (payload) => visClient.deleteVisualization(payload.visualizationId),
  onSuccess: (payload) => ({ type: "VISUALIZATION.EPIC.DELETE_SUCCESS", payload }),
  onFailure: (error) => ({
    type: "VISUALIZATION.EPIC.DELETE_FAILURE",
    payload: new Error("Unable to Delete Visualization"),
  }),
});
