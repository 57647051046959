import { Collapsible } from "@fscrypto/ui";
import { AlertTriangleIcon, PlusIcon } from "lucide-react";

export const PanelError = ({ error }: { error: Error | null }) => {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <AlertTriangleIcon className="text-warning/70 size-4 m-2" />
      <div className="dark:text-gray-40 m-2 text-sm text-gray-500">Error Rendering Panel</div>
      {error && (
        <Collapsible.Root>
          <Collapsible.Trigger>
            <div className="mx-4 flex items-center border px-2">
              <PlusIcon className="text-muted-foreground size-4" />{" "}
              <p className="text-muted-foreground p-1 text-xs">View Error Details</p>
            </div>
          </Collapsible.Trigger>
          <Collapsible.Content>
            <div className="scrollbar h-24">
              <p className="text-gray-60 dark:text-gray-40 text-left text-xl font-bold">{error.name}</p>
              <p className="text-gray-60 dark:text-gray-40 text-left text-xl font-bold">{error.message}</p>
              <p className="p-4">{error.stack}</p>
            </div>
          </Collapsible.Content>
        </Collapsible.Root>
      )}
    </div>
  );
};
