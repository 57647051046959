import type { AddToDashboardType } from "./machines/add-to-dashboard-machine";
import { useAddToDashboardMachine } from "./machines/add-to-dashboard-machine";
import AddToDashboardModal from "./components/add-to-dashboard-modal";
import { FilterInput } from "./components/filter-input";
import { DashboardItem } from "./components/dashboard-item";
import { AddNewDashboard } from "./components/add-new-dashboard";
import AddButton from "./components/add-button";
import { useSpinDelay } from "spin-delay";

interface AddToDashboardProps {
  resourceType: AddToDashboardType;
  resourceId: string;
}

export const AddToDashboard = ({ resourceType, resourceId }: AddToDashboardProps) => {
  const {
    searchTerm,
    dashboards,
    isOpen,
    openModal,
    updateSearchTerm,
    closeModal,
    addToDashboard,
    createNewDashboard,
    latestDashboards,
    filteredView,
    isLatestLoading,
    isSearchLoading,
  } = useAddToDashboardMachine();
  const isLoading = useSpinDelay(isLatestLoading || isSearchLoading, { delay: 0, minDuration: 300 });
  return (
    <>
      <AddButton
        onClick={() => openModal(resourceId, resourceType)}
        resourceType={resourceType}
        resourceId={resourceId}
      />
      <AddToDashboardModal isOpen={isOpen} onClose={() => closeModal()}>
        <FilterInput value={searchTerm} onChange={(searchTerm) => updateSearchTerm(searchTerm)} />
        {isLoading ? (
          <div className="scrollbar h-60">
            <LoadingSkeleton />
          </div>
        ) : (
          <div className="scrollbar h-60">
            {filteredView
              ? dashboards.map((dashboard) => (
                  <DashboardItem
                    key={dashboard.id}
                    dashboard={dashboard}
                    onSelect={() => addToDashboard(dashboard.id)}
                  />
                ))
              : latestDashboards.map((dashboard) => (
                  <DashboardItem
                    key={dashboard.id}
                    dashboard={dashboard}
                    onSelect={() => addToDashboard(dashboard.id)}
                  />
                ))}
          </div>
        )}

        <AddNewDashboard onSelect={() => createNewDashboard()} resourceType={resourceType} />
      </AddToDashboardModal>
    </>
  );
};

export const LoadingSkeleton = () => {
  return (
    <div className="flex animate-pulse flex-col">
      {[...Array(5)].map((_, i) => (
        <LoadingSearchRow key={i} />
      ))}
    </div>
  );
};

export const LoadingSearchRow = () => {
  return (
    <div className="mb-2 flex items-center p-2">
      <div className="bg-gray-15 mr-1 h-5 w-5 rounded-full dark:bg-gray-700"></div>
      <div className="bg-gray-15 mx-1 ml-2 h-5 flex-1 rounded-full dark:bg-gray-700"></div>
    </div>
  );
};
