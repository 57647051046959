import type { Observable } from "rxjs";
import { catchError, debounceTime, from, map, merge, of, switchMap } from "rxjs";
import { POST } from "~/async/fetch";
import { ofType } from "~/state/epics";
import type { dashboard } from "@fscrypto/domain";
import type { Dashboard } from "@fscrypto/domain/dashboard";
import { actorSystem } from "~/state";

export type EpicEvent =
  | UpdateDashboard
  | UpdateDashboardSuccess
  | UpdateDashboardFailure
  | UpdateRealtimeDashboard
  | UpdateRealtimeDashboardSuccess
  | UpdateRealtimeDashboardFailure;

type UpdateDashboard = {
  type: "DASHBOARD_PERSIST.EPIC.UPDATE";
  payload: {
    id: string;
    useRealtime: boolean;
  };
};
type UpdateDashboardSuccess = { type: "DASHBOARD_PERSIST.EPIC.UPDATE_SUCCESS"; payload: Dashboard };
type UpdateDashboardFailure = { type: "DASHBOARD_PERSIST.EPIC.UPDATE_FAILURE"; payload: Response };

type UpdateRealtimeDashboard = {
  type: "DASHBOARD_PERSIST.EPIC.REALTIME_UPDATE";
  payload: {
    id: string;
  };
};

type UpdateRealtimeDashboardSuccess = {
  type: "DASHBOARD_PERSIST.EPIC.REALTIME_UPDATE_SUCCESS";
  payload: Dashboard;
};

type UpdateRealtimeDashboardFailure = {
  type: "DASHBOARD_PERSIST.EPIC.REALTIME_UPDATE_FAILURE";
  payload: Response;
};

export const createEpics = (actions$: Observable<EpicEvent>): Observable<EpicEvent> => {
  return merge(updateDashboardEpic(actions$), updateRealtimeDashboardEpic(actions$));
};

const updateDashboardEpic = (actions$: Observable<EpicEvent>): Observable<EpicEvent> => {
  return actions$.pipe(
    ofType("DASHBOARD_PERSIST.EPIC.UPDATE"),
    debounceTime(1500),
    switchMap((action) => {
      const dashboardActor = actorSystem.get(`dashboard-${action.payload.id}`);
      const currentData = dashboardActor?.getSnapshot()?.context.dashboard as Dashboard;
      return from(updateDashboard(action.payload.id, currentData, action.payload.useRealtime)).pipe(
        map((response) => ({
          type: "DASHBOARD_PERSIST.EPIC.UPDATE_SUCCESS",
          payload: response,
        })),
        catchError((error) => {
          return of({
            type: "DASHBOARD_PERSIST.EPIC.UPDATE_FAILURE",
            payload: error,
          });
        }),
      );
    }),
  ) as Observable<EpicEvent>;
};

const updateRealtimeDashboardEpic = (actions$: Observable<EpicEvent>): Observable<EpicEvent> => {
  return actions$.pipe(
    ofType("DASHBOARD_PERSIST.EPIC.REALTIME_UPDATE"),
    debounceTime(300), // You can adjust the debounce time as per your needs
    switchMap((action) => {
      return from(updateRealtimeDashboard(action.payload.id)).pipe(
        map((response) => ({
          type: "DASHBOARD_PERSIST.EPIC.REALTIME_UPDATE_SUCCESS",
          payload: response,
        })),
        catchError((error) => {
          return of({
            type: "DASHBOARD_PERSIST.EPIC.REALTIME_UPDATE_FAILURE",
            payload: error,
          });
        }),
      );
    }),
  ) as Observable<EpicEvent>;
};

export const updateDashboard = async (dashboardId: string, update: dashboard.DashboardUpdate, useRealtime: boolean) => {
  try {
    return POST<dashboard.Dashboard>(`/api/dashboards/${dashboardId}/update?realtime=${useRealtime}`, update);
  } catch (err) {
    throw err as Response;
  }
};

export const updateRealtimeDashboard = async (dashboardId: string) => {
  try {
    return POST(`/api/realtime/dashboards/${dashboardId}/update`);
  } catch (err) {
    throw err as Response;
  }
};
