import { Button, HoverCard, Progress, Text, Tooltip } from "@fscrypto/ui";
import React from "react";
import { formatDateTimeAgo } from "~/utils/helpers";
import { useRefreshDashboardMachine } from "~/state/machines/dashboard/dashboard-refresh/dashboard-refresh.machine";
import { AlertTriangle, DotIcon, RepeatIcon } from "lucide-react";
import MarketingAuthModal from "~/features/app-shell/marketing/marketing-auth-modal";
import { useCurrentUser } from "~/features/current-user";
import { tracking } from "~/utils/tracking";

interface RefreshButtonProps {
  isOwner: boolean;
  dashboardId: string;
}

const RefreshButton = ({ isOwner, dashboardId }: RefreshButtonProps) => {
  const { currentUser } = useCurrentUser();
  const { lastRefreshedAt, refreshDashboard, refreshing, queries, hasRefreshed } = useRefreshDashboardMachine({
    dashboardId,
  });
  if (hasRefreshed) {
    console.info(`refresh query status: ${queries?.map((q, i) => `${i + 1}: ${q.status},`)}`);
  }
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [openAuthModal, setOpenAuthModal] = React.useState(false);

  const onOpenAuthModalChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpenAuthModal(false);
    }
  };

  const hasError = (queries?.filter((query) => query.status === "error").length ?? 0) > 0;
  if (hasError) {
    return <FailedButton dashboardId={dashboardId} />;
  }

  if (refreshing) {
    return <RefreshProgress dashboardId={dashboardId} />;
  }
  const noRefresh = shouldPreventRefresh(isOwner, lastRefreshedAt);
  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      data-testid="dashboard-refresh-button"
    >
      <Tooltip contentFn={() => getTooltipContent(lastRefreshedAt)} side="top" display={showTooltip}>
        <Button
          variant="secondary"
          size="sm"
          onClick={
            currentUser
              ? () => {
                  tracking(`click_refresh_dashboard`, "Dashboard Editor");
                  refreshDashboard?.();
                }
              : () => setOpenAuthModal(true)
          }
          className="mx-1"
          disabled={currentUser && noRefresh}
        >
          <RepeatIcon className=" size-4 mr-2" />
          Refresh
        </Button>
      </Tooltip>
      <MarketingAuthModal
        open={openAuthModal}
        redirectBackOnClose={false}
        defaultOpen={false}
        onOpenChange={onOpenAuthModalChange}
        persona="viewer"
        origin="dashboard-refresh"
      />
    </div>
  );
};

const RefreshProgress = ({ dashboardId }: { dashboardId: string }) => {
  const { queries } = useRefreshDashboardMachine({ dashboardId });
  const completed = queries?.filter((query) => query.status === "success").length ?? 0;
  const total = queries?.length ?? 0;
  const percent = total > 0 ? Math.floor((completed / total) * 100) : 0;

  return (
    <Button variant="secondary">
      <div>
        <Text size="xs">Refreshing {queries?.length}</Text>
        <Progress size="sm" value={percent} color={"success"} />
      </div>
    </Button>
  );
};

const FailedButton = ({ dashboardId }: { dashboardId: string }) => {
  const { queries } = useRefreshDashboardMachine({ dashboardId });
  const failed = queries?.filter((query) => query.status === "error");
  return (
    <HoverCard.Root>
      <HoverCard.Trigger>
        <Button variant="secondary" size="sm" className="mx-1">
          <AlertTriangle className="text-warning/80 h-4 w-4" />
          <Text size="xs" color="warning">
            {failed?.length} failed
          </Text>
        </Button>
      </HoverCard.Trigger>
      <HoverCard.Content side="bottom" sideOffset={10} align="end" className="p-3">
        <div>
          The following queries failed:
          <ul className="mt-3 space-y-2">
            {failed?.map((query) => (
              <li key={query.id} className="flex items-center p-1">
                <DotIcon className="text-warning/80 h-4 w-4" />
                <Text size="sm">{query.name}</Text>
              </li>
            ))}
          </ul>
        </div>
      </HoverCard.Content>
    </HoverCard.Root>
  );
};

function getTooltipContent(lastRefreshedAt: Date | undefined): React.ReactNode {
  let tooltipContent = "Refresh queries";

  if (lastRefreshedAt) {
    tooltipContent = `Last refreshed ${formatDateTimeAgo(lastRefreshedAt)}`;
  }

  return <>{tooltipContent}</>;
}

function shouldPreventRefresh(isOwner: boolean, lastRefreshedAt: Date | undefined) {
  if (isOwner) return false;
  if (!lastRefreshedAt) return false;
  const HOUR = 1000 * 60 * 60;
  const anHourAgo = Date.now() - HOUR;
  const lastRefreshedAtTime = new Date(lastRefreshedAt).getTime();
  if (lastRefreshedAtTime > anHourAgo) {
    return true;
  }
  return false;
}

export default RefreshButton;
