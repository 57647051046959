import type { ActorRefFrom, AnyActorRef, StateFrom } from "xstate";
import { assign, createMachine, spawn } from "xstate";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";
import { Subject } from "rxjs";
import type { Event as EpicEvent } from "./epics";
import { createEpics } from "./epics";
import invariant from "tiny-invariant";
import type { LiveObject, Status, StorageStatus } from "@liveblocks/client";
import { useActorFromSystem } from "~/state/system";
import type { dashboard as dashboardDomain } from "@fscrypto/domain";
import type { DashboardPresence, DashboardRoom } from "./util";
import { createDashboardRoom } from "./util";
import { actorSystem } from "~/state/system";
import { RealtimeDashboard } from "@fscrypto/domain/liveblocks";
import { liveblocks as liveblocksDomain } from "@fscrypto/domain";
import { CurrentUserActor } from "~/features/current-user";

export const createDashboardRoomMachine = (
  dashboardId: string,
  dashboard: dashboardDomain.Dashboard,
  currentUserId?: string,
) => {
  const actions$ = new Subject<EpicEvent>();
  const machine = createMachine(
    {
      id: "dashboard-room-machine",
      tsTypes: {} as import("./dashboard-room.machine.typegen").Typegen0,
      schema: {
        context: {} as DashboardRoomContext,
        events: {} as GlobalEvent | EpicEvent | DashboardRoomEvent,
      },
      context: {
        dashboardId,
      },
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      entry: ["setupEpic"],
      initial: "initLiveblocksClient",
      on: {
        "DASHBOARD_REALTIME.EPIC.STATUS_UPDATE": {
          actions: ["setStatus", "broadcastStatus"],
        },
      },
      states: {
        initLiveblocksClient: {
          always: [
            {
              actions: ["createRoomInstance"],
              target: "initializingRoom",
            },
          ],
        },
        initializingRoom: {
          entry: ["subscribeToRoomEpic"],
          on: {
            "DASHBOARD_REALTIME.EPIC.INITIALIZE_ROOM": {
              actions: ["handleInitializeRoom", "broadcastRoomReady"],
              target: "ready",
            },
          },
        },
        error: {},
        ready: {
          on: {
            "DASHBOARD_REALTIME.EPIC.DATA_UPDATE": {
              actions: ["handleDataUpdate"],
            },
            "GLOBAL.DASHBOARD.SET_DATA": {
              actions: ["handleSetData"],
              cond: "isDashboardId",
            },
            "GLOBAL.DASHBOARD.SET_TITLE": {
              actions: ["handleSetTitle"],
              cond: "isDashboardId",
            },
            "GLOBAL.DASHBOARD_PUBLISH.PUBLISH_SUCCESS": {
              actions: ["sendEventToRoom"],
              cond: "isDashboardId",
            },
            "GLOBAL.DASHBOARD_PUBLISH.UNPUBLISH_SUCCESS": {
              actions: ["sendEventToRoom"],
              cond: "isDashboardId",
            },
            "GLOBAL.ADD_TO_DASHBOARD.ADD_CELL": {
              actions: ["sendEventToRoom"],
              cond: "isDashboardId",
            },
            "DASHBOARD_REALTIME.SET_CURSOR": {
              actions: ["setCursor"],
            },
            "GLOBAL.DASHBOARD.LEAVE_DASHBOARD": {
              target: "leaveRoom",
              cond: "isDashboardId",
            },
            "GLOBAL.SHARE_SETTINGS.UPDATE_VISIBILITY": {
              actions: ["handleSetVisibility"],
              cond: "isWorkItemId",
            },
          },
        },
        leaveRoom: {
          entry: ["leaveRoom", "removeActor", "broadcastLeaveRoom"],
        },
      },
    },
    {
      actions: {
        setupEpic: assign((ctx, _e) => {
          if (ctx.epic$) return {};
          const epic$ = createEpics(actions$);
          return {
            epic$: spawn(epic$),
          };
        }),
        createRoomInstance: assign((ctx) => {
          const { room, leave } = createDashboardRoom(
            `fscrypto:dashboard-${ctx.dashboardId}`,
            dashboard,
            currentUserId,
          );
          return {
            room,
            leave,
          };
        }),
        broadcastRoomReady: (ctx) => {
          invariant(ctx.room, "room should be initialized");
          eventBus.send({
            type: "GLOBAL.DASHBOARD_REALTIME.ROOM_READY",
            payload: ctx.room,
            dashboardId: ctx.dashboardId,
          });
        },
        setStatus: assign((ctx, e) => {
          if (e.payload.statusType === "room") {
            return {
              roomStatus: e.payload.status as Status,
            };
          } else if (e.payload.statusType === "storage") {
            return {
              storageStatus: e.payload.status as StorageStatus,
            };
          }
          return {};
        }),
        broadcastStatus: (ctx, e) => {
          eventBus.send({
            type: "GLOBAL.DASHBOARD_REALTIME.STATUS_UPDATE",
            payload: e.payload,
            dashboardId: ctx.dashboardId,
          });
        },
        subscribeToRoomEpic: (ctx) => {
          invariant(ctx.room, "room should be initialized");
          actions$.next({ type: "DASHBOARD_REALTIME.EPIC.SUBSCRIBE_TO_ROOM_EVENTS", payload: { room: ctx.room } });
        },
        handleInitializeRoom: assign((ctx, e) => {
          return {
            dashboardData: e.payload.dashboardData,
          };
        }),
        handleSetTitle: (ctx, e) => {
          ctx.dashboardData?.set("title", e.payload);
        },
        handleSetData: (ctx, e) => {
          if (e.payload.dashboard.draft) {
            const draft = liveblocksDomain.dashboardStorageSchema.shape.draft.parse(e.payload.dashboard.draft);
            ctx.dashboardData?.set("draft", draft);
          }
          if (e.payload.dashboard.description) {
            ctx.dashboardData?.set("description", e.payload.dashboard.description);
          }
        },
        handleSetVisibility: (ctx, e) => {
          ctx.dashboardData?.set("visibility", e.payload.visibility);
        },
        sendEventToRoom: (ctx, e) => {
          if (e.fromRealtime) {
            return;
          }
          ctx.room?.broadcastEvent({ ...e, fromRealtime: true });
        },
        handleDataUpdate: (ctx, e) => {
          eventBus.send({
            type: "GLOBAL.DASHBOARD_REALTIME.UPDATE_DATA",
            payload: { dashboardData: e.payload.toImmutable() },
            dashboardId,
          });
        },
        // compareInitialData: (ctx) => {
        //   const dashboardData = actorSystem.get<DashboardActorRef>(`dashboard-${dashboardId}`)?.getSnapshot()
        //     ?.context.dashboard;
        //   const realtime = ctx.dashboardData?.toImmutable();
        //   determineInitialParity(dashboardData, realtime);
        // },
        leaveRoom: (ctx) => {
          ctx.leave?.();
        },
        broadcastLeaveRoom: (ctx) => {
          eventBus.send({
            type: "GLOBAL.DASHBOARD_REALTIME.LEAVE_ROOM",
            dashboardId: ctx.dashboardId,
          });
        },
        removeActor: () => {
          actorSystem.unregister(`dashboardRoom-${dashboardId}`);
          actorSystem.unregister(`dashboardRoom-${dashboardId}-others`);
        },
      },
      services: {
        globalEvents: () => eventBus.events$,
      },
      guards: {
        isDashboardId: (ctx, e) => {
          return e.dashboardId === ctx.dashboardId;
        },
        isWorkItemId: (ctx, e) => {
          return e.workItemId === ctx.dashboardId;
        },
      },
    },
  );
  return machine;
};

interface DashboardRoomContext {
  dashboardId: string;
  epic$?: AnyActorRef;
  room?: DashboardRoom;
  dashboardData?: LiveObject<RealtimeDashboard>;
  leave?: () => void;
  roomStatus?: Status;
  storageStatus?: StorageStatus;
}

export type DashboardRoomEvent = {
  type: "DASHBOARD_REALTIME.SET_CURSOR";
  payload: DashboardPresence["cursor"];
};

export type GlobalDashboardRoomEvent =
  | {
      type: "GLOBAL.DASHBOARD_REALTIME.UPDATE_DATA";
      payload: { dashboardData: RealtimeDashboard };
      dashboardId: string;
    }
  | {
      type: "GLOBAL.DASHBOARD_REALTIME.ROOM_READY";
      payload: DashboardRoom;
      dashboardId: string;
    }
  | {
      type: "GLOBAL.DASHBOARD_REALTIME.LEAVE_ROOM";
      dashboardId: string;
    }
  | {
      type: "GLOBAL.DASHBOARD_REALTIME.STATUS_UPDATE";
      payload: { statusType: "room" | "storage"; status: Status | StorageStatus };
      dashboardId: string;
    };

export type DashboardRoomActorRef = ActorRefFrom<ReturnType<typeof createDashboardRoomMachine>>;
export type DashboardRoomState = StateFrom<ReturnType<typeof createDashboardRoomMachine>>;

export const createDashboardRoomState = (dashboardId: string, dashboard: dashboardDomain.Dashboard) => {
  let ref = actorSystem.get<DashboardRoomActorRef>(`dashboardRoom-${dashboardId}`);
  if (!ref) {
    const currentUserRef = actorSystem.get<CurrentUserActor>(`currentUser`);
    const currentUser = currentUserRef?.getSnapshot()?.context.currentUser;

    const machine = createDashboardRoomMachine(dashboardId, dashboard, currentUser?.id);
    ref = actorSystem.registerMachine(machine, `dashboardRoom-${dashboardId}`);
  }
  return ref;
};

export const useDashboardRoom = (dashboardId: string) => {
  const [state, dashboardRoomRef] = useActorFromSystem<DashboardRoomActorRef>(`dashboardRoom-${dashboardId}`);
  if (!dashboardRoomRef || !state)
    return {
      isReady: false,
    } as const;
  return {
    isReady: true,
  };
};
