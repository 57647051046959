import type { ActorRefFrom, AnyActorRef, StateFrom } from "xstate";
import { assign, createMachine, spawn } from "xstate";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";
import { Subject } from "rxjs";
import type { OthersEpicEvent } from "./epics";
import { createOthersEpics } from "./epics";
import type { User as LiveblocksUser } from "@liveblocks/client";
import { useActorFromSystem } from "~/state/system";
import type { DashboardPresence, DashboardRoom, RoomUserInfo } from "./util";
import { getInfoFromOthers } from "./util";
import { actorSystem } from "~/state/system";
import invariant from "tiny-invariant";
import type { UserStateRef } from "../../user-state/user-state";
import { DashboardRoomActorRef } from "./dashboard-room.machine";
import { RealtimeDashboard } from "@fscrypto/domain/liveblocks";

export const createDashboardOthersMachine = (dashboardId: string) => {
  const actions$ = new Subject<OthersEpicEvent>();
  const machine = createMachine(
    {
      id: "dashboard-others-machine",
      tsTypes: {} as import("./dashboard-others.machine.typegen").Typegen0,
      schema: {
        context: {} as DashboardRoomContext,
        events: {} as GlobalEvent | OthersEpicEvent | DashboardOthersRefEvent,
      },
      context: {
        dashboardId,
        others: [],
      },
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      entry: ["setupEpic", "setCurrentUser"],
      initial: "initRoom",

      states: {
        initRoom: {
          description: "this will either wait for the liveblocks room to be initialized",
          always: [
            {
              cond: "isDashboardRoomReady",
              target: "ready",
              actions: ["setRoomFromRef"],
            },
          ],
          on: {
            "GLOBAL.DASHBOARD_REALTIME.ROOM_READY": {
              actions: ["setRoom"],
              target: "ready",
              cond: "isDashboardId",
            },
          },
        },
        ready: {
          entry: ["subscribeToOthersEpic"],
          on: {
            "DASHBOARD_OTHERS.EPIC.UPDATE": {
              actions: ["handleOthersUpdate"],
            },
            "DASHBOARD_OTHERS.SET_CURSOR": {
              actions: ["setCursor"],
            },
          },
        },
      },
    },
    {
      actions: {
        setupEpic: assign((ctx, _e) => {
          if (ctx.epic$) return {};
          const epic$ = createOthersEpics(actions$);
          return {
            epic$: spawn(epic$),
          };
        }),
        setCurrentUser: assign((_) => {
          const service = actorSystem?.get<UserStateRef>("userState");
          invariant(service, "userState service must be defined");
          const userId = service.getSnapshot()?.context.userId;
          return {
            userId,
          };
        }),
        setRoom: assign((ctx, e) => {
          return {
            room: e.payload,
          };
        }),
        setRoomFromRef: assign((ctx, e) => {
          const dashboardRoomRef = actorSystem.get<DashboardRoomActorRef>(`dashboardRoom-${ctx.dashboardId}`);
          const room = dashboardRoomRef?.getSnapshot()?.context.room;
          return {
            room,
          };
        }),
        subscribeToOthersEpic: (ctx) => {
          invariant(ctx.room, "room must be defined");
          actions$.next({ type: "DASHBOARD_OTHERS.EPIC.SUBSCRIBE_TO_OTHERS", payload: { room: ctx.room } });
        },
        handleOthersUpdate: assign((ctx, e) => {
          return {
            others: e.payload,
          };
        }),
        setCursor: (ctx, e) => {
          ctx.room?.updatePresence({ cursor: e.payload });
        },
      },
      services: {
        globalEvents: () => eventBus.events$,
      },
      guards: {
        isDashboardId: (ctx, e) => {
          return e.dashboardId === ctx.dashboardId;
        },
        isDashboardRoomReady: (ctx) => {
          const dashboardRoomRef = actorSystem.get<DashboardRoomActorRef>(`dashboardRoom-${ctx.dashboardId}`);
          return !!dashboardRoomRef?.getSnapshot()?.context.room;
        },
      },
    },
  );
  return machine;
};

interface DashboardRoomContext {
  epic$?: AnyActorRef;
  room?: DashboardRoom;
  dashboardId: string;
  userId?: string | undefined;
  others?: readonly LiveblocksUser<DashboardPresence, RoomUserInfo>[];
}

export type DashboardOthersRefEvent = {
  type: "DASHBOARD_OTHERS.SET_CURSOR";
  payload: DashboardPresence["cursor"];
};

export type GlobalDashboardRoomEvent = {
  type: "GLOBAL.DASHBOARD_REALTIME.UPDATE_DATA";
  payload: { dashboardData: RealtimeDashboard };
  dashboardId: string;
};

export type DashboardOthersActorRef = ActorRefFrom<ReturnType<typeof createDashboardOthersMachine>>;
export type DashboardOthersState = StateFrom<ReturnType<typeof createDashboardOthersMachine>>;

export const createDashboardRoomOthersState = (dashboardId: string) => {
  let ref = actorSystem.get<DashboardOthersActorRef>(`dashboardRoom-${dashboardId}-others`);
  if (!ref) {
    const machine = createDashboardOthersMachine(dashboardId);
    ref = actorSystem.registerMachine(machine, `dashboardRoom-${dashboardId}-others`);
  }
  return ref;
};

export const useDashboardOthers = (dashboardId: string) => {
  const [state, dashboardRoomRef] = useActorFromSystem<DashboardOthersActorRef>(`dashboardRoom-${dashboardId}-others`);

  if (!dashboardRoomRef || !state)
    return {
      isReady: false,
    } as const;
  // filter others to remove the current user, this will be needed when a user has multiple tabs open
  const others = state.context.others?.filter((o) => o.id !== state.context.userId) ?? [];
  return {
    isReady: true,
    others: getInfoFromOthers(others),
    setCursor: (cursor: DashboardPresence["cursor"]) =>
      dashboardRoomRef.send({ type: "DASHBOARD_OTHERS.SET_CURSOR", payload: cursor }),
  };
};
