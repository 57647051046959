import type { dashboard } from "@fscrypto/domain";
import { formatRelative } from "date-fns";
import type { user } from "@fscrypto/domain";
import { Avatar, Text } from "@fscrypto/ui";
import React from "react";
import DashboardDescription from "./dashboard-description";
import DashboardTitle from "./dashboard-title";
import { Link } from "@remix-run/react";
import { useEmbedded } from "~/hooks/useEmbedded";
import { ContributorList } from "./contributor-list";
import type { Profile } from "@fscrypto/domain/profile";

interface TitleDescriptionProps {
  owner: Profile;
  published?: boolean;
  dashboard: dashboard.Dashboard;
  isEditable: boolean;
  onTitleChange?: (title: string) => void;
  onDescriptionChange?: (description: string) => void;
  rightSlot?: React.ReactNode;
  isSaving?: boolean;
  contributors: user.User[];
  variant?: "draft" | "published";
}

const DashboardHeader = ({
  owner,
  isEditable,
  onTitleChange,
  dashboard,
  onDescriptionChange,
  rightSlot,
  isSaving,
  contributors,
  variant,
}: TitleDescriptionProps) => {
  const { linkTarget } = useEmbedded();
  const lastUpdated = isSaving ? "Saving..." : `Saved: ${formatRelative(new Date(dashboard.updatedAt), new Date())}`;
  return (
    <div className="flex w-full flex-col items-start p-4">
      <div className="w-full">
        <div className="flex w-full items-center justify-between">
          <div className="flex w-full items-center py-2">
            <div className="flex items-center">
              <div data-testid="dashboard-owner-avatar" className="mr-4">
                <Link to={owner.profileUrl ?? ""} target={linkTarget}>
                  <Avatar size="xl" src={owner.avatarUrl!} variant="square" alt={owner.name} />
                </Link>
              </div>
              <div>
                <Link to={owner.profileUrl!} target={linkTarget}>
                  <Text size="xl">{owner.name}</Text>
                </Link>
                {!isEditable && owner.type === "team" && variant !== "draft" && (
                  <ContributorList contributors={contributors} />
                )}
              </div>
            </div>
            {variant === "draft" && (
              <div className="bg-gray-15 ml-2 rounded-full px-2 py-1 text-xs dark:bg-gray-50">
                {isEditable ? "Draft" : "loading"}
              </div>
            )}
            {isEditable && <div className="dark:text-gray-40 ml-2 text-xs">{lastUpdated}</div>}
          </div>
          {rightSlot}
        </div>
        <div className="w-full overflow-hidden truncate">
          {isEditable ? (
            <DashboardTitle
              value={dashboard.title}
              onChange={onTitleChange}
              id={dashboard.id}
              className="text-gray-90 hover:border-gray-30 dark:bg-gray-90 dark:text-gray-30 dark:border-gray-80 mb-2 w-full truncate rounded-lg border  border-transparent p-1 text-[40px]"
            />
          ) : (
            <h1 className="text-gray-90 dark:text-gray-30 w-full overflow-hidden truncate p-1 text-[40px]">
              {dashboard.title}
            </h1>
          )}
        </div>
        <DashboardDescription
          value={dashboard.description}
          onChange={onDescriptionChange}
          id={dashboard.id}
          isEditable={isEditable}
        />
      </div>
    </div>
  );
};

export default DashboardHeader;
