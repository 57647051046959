import { tracking } from "~/utils/tracking";

interface DashboardDescriptionProps {
  value: string;
  onChange?: (value: string) => void;
  isEditable: boolean;
  id: string;
}

const DashboardDescription = ({ value, onChange, isEditable, id }: DashboardDescriptionProps) => {
  if (!isEditable) {
    return value.length ? (
      <p className="dark:bg-gray-90 dark:text-gray-40 text-gray-70 my-2 w-full rounded  border border-transparent p-1">
        {value}
      </p>
    ) : null;
  }
  return (
    <textarea
      name="description"
      value={value}
      rows={4}
      placeholder="Add a description..."
      className={
        "hover:border-gray-30 dark:bg-gray-90 dark:text-gray-30 text-gray-70 dark:border-gray-80 w-full  rounded border border-transparent bg-transparent p-1"
      }
      onChange={(e) => {
        onChange && onChange(e.target.value);
      }}
      onBlur={() => {
        onChange && tracking("change_dashboard_description", "Dashboard Editor", { dashboard_id: id });
      }}
    />
  );
};

export default DashboardDescription;
