import type { Room, User as LiveblocksUser, Status, StorageStatus } from "@liveblocks/client";
import type { Observable } from "rxjs";
import {
  from,
  fromEventPattern,
  merge,
  mergeMap,
  startWith,
  map,
  of,
  tap,
  catchError,
  switchMap,
  interval,
} from "rxjs";
import { ofType } from "~/state/epics";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";
import type { DashboardPresence, DashboardRoom, DashboardRoomStorage, RoomUserInfo } from "./util";

type SubscribeToRoomEvents = {
  type: "DASHBOARD_REALTIME.EPIC.SUBSCRIBE_TO_ROOM_EVENTS";
  payload: { room: DashboardRoom };
};
type InitializeRoom = {
  type: "DASHBOARD_REALTIME.EPIC.INITIALIZE_ROOM";
  payload: {
    dashboardData: DashboardRoomStorage["dashboardData"];
    others: readonly LiveblocksUser<DashboardPresence, RoomUserInfo>[];
  };
};

type DashboardDataInit = { type: "DASHBOARD_REALTIME.EPIC.DATA_INIT"; payload: DashboardRoomStorage["dashboardData"] };
type DashboardDataUpdate = {
  type: "DASHBOARD_REALTIME.EPIC.DATA_UPDATE";
  payload: DashboardRoomStorage["dashboardData"];
};

type StatusUpdate = {
  type: "DASHBOARD_REALTIME.EPIC.STATUS_UPDATE";
  payload: { statusType: "room" | "storage"; status: Status | StorageStatus };
};

export type Event =
  | InitializeRoom
  | SubscribeToRoomEvents
  | DashboardDataInit
  | DashboardDataUpdate
  | GlobalEvent
  | StatusUpdate;

export const createEpics = (actions$: Observable<Event>): Observable<Event> => {
  return merge(subscribeToRoomEventsEpic(actions$));
};

const subscribeToRoomEventsEpic = (action$: Observable<Event>) => {
  return action$.pipe(
    ofType("DASHBOARD_REALTIME.EPIC.SUBSCRIBE_TO_ROOM_EVENTS"),
    mergeMap((action) =>
      merge(
        subscribeToStatus(action),
        subscribeToEvents(action),
        from(getRoomData(action.payload.room)).pipe(
          mergeMap(({ dashboardData }) => {
            return merge(
              of({
                type: "DASHBOARD_REALTIME.EPIC.INITIALIZE_ROOM",
                payload: { dashboardData },
              }) as Observable<Event>,
              subscribeToDashboardData(action, dashboardData),
            );
          }),
        ),
      ),
    ),
    catchError(
      (error) =>
        of({
          type: "DASHBOARD_REALTIME.EPIC.STATUS_UPDATE",
          payload: { statusType: "room", status: "disconnected" },
        }) as Observable<Event>,
    ),
  );
};

const getRoomData = async (room: Room<DashboardPresence, DashboardRoomStorage, RoomUserInfo, {}>) => {
  const { root } = await room.getStorage();
  if (!root) {
    throw new Error("unable to connect to room");
  }
  // helper to mimic slow connections
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  return {
    dashboardData: root.get("dashboardData"),
  };
};

const subscribeToDashboardData = (
  action: SubscribeToRoomEvents,
  dashboardData: DashboardRoomStorage["dashboardData"],
): Observable<Event> => {
  return fromEventPattern<DashboardRoomStorage["dashboardData"]>(
    (handler) => action.payload.room.subscribe(dashboardData, handler),
    (_, unsubscribe) => unsubscribe(),
  ).pipe(map((event) => ({ type: "DASHBOARD_REALTIME.EPIC.DATA_UPDATE", payload: event }) as Event));
};

const subscribeToEvents = (action: SubscribeToRoomEvents) =>
  fromEventPattern<{ connectionId: string; event: GlobalEvent }>(
    (handler) => action.payload.room.subscribe("event", handler),
    (_, unsubscribe) => unsubscribe(),
  ).pipe(
    tap(({ event }) => {
      eventBus.send(event);
    }),
    map(({ event }) => event),
  );

// --------------> OTHERS <----------------

export const createOthersEpics = (actions$: Observable<OthersEpicEvent>): Observable<OthersEpicEvent> => {
  return merge(subscribeToOthersEpic(actions$));
};

type SubscribeToOthersEvents = {
  type: "DASHBOARD_OTHERS.EPIC.SUBSCRIBE_TO_OTHERS";
  payload: { room: DashboardRoom };
};

type OthersUpdate = {
  type: "DASHBOARD_OTHERS.EPIC.UPDATE";
  payload: readonly LiveblocksUser<DashboardPresence, RoomUserInfo>[];
};

export type OthersEpicEvent = SubscribeToOthersEvents | OthersUpdate;

export const subscribeToOthersEpic = (action$: Observable<OthersEpicEvent>) => {
  return action$.pipe(
    ofType("DASHBOARD_OTHERS.EPIC.SUBSCRIBE_TO_OTHERS"),
    mergeMap((action) => {
      return subscribeToOthers(action, action.payload.room.getOthers());
    }),
  );
};

const subscribeToOthers = (
  action: SubscribeToOthersEvents,
  initialOthers: readonly LiveblocksUser<DashboardPresence, RoomUserInfo>[],
) =>
  fromEventPattern<[LiveblocksUser<DashboardPresence, RoomUserInfo>[], OthersEpicEvent]>(
    (handler) => action.payload.room.subscribe("others", handler),
    (_, unsubscribe) => unsubscribe(),
  ).pipe(
    mergeMap(([othersUpdate, _event]) => {
      // const handledEvent = handleEvent(event); // Call your handleEvent function
      return merge(
        // of(handledEvent), // Emit the handled event if you need to
        of({ type: "DASHBOARD_OTHERS.EPIC.UPDATE", payload: othersUpdate } as OthersUpdate), // Emit the required event
      );
    }),
    startWith({ type: "DASHBOARD_OTHERS.EPIC.UPDATE", payload: initialOthers } as OthersUpdate),
  );

const subscribeToStatus = (action: SubscribeToRoomEvents): Observable<StatusUpdate> => {
  const createObservable = (type: "room" | "storage", getStatus: () => Status | StorageStatus) =>
    merge(
      fromEventPattern(
        (handler) => action.payload.room.subscribe(type === "room" ? "status" : "storage-status", handler),
        (_, unsubscribe) => unsubscribe(),
      ),
      interval(10000).pipe(switchMap(() => [getStatus()])),
    ).pipe(
      map(
        (status) =>
          ({
            type: "DASHBOARD_REALTIME.EPIC.STATUS_UPDATE",
            payload: { statusType: type, status },
          }) as StatusUpdate,
      ),
    );

  const roomStatusObservable = createObservable("room", () => action.payload.room.getStatus());
  const storageStatusObservable = createObservable("storage", () => action.payload.room.getStorageStatus());

  return merge(roomStatusObservable, storageStatusObservable);
};
