import type { dashboard } from "@fscrypto/domain";
import { DownloadingResults } from "~/features/current-user/ui/loading-results";
import TablePanelContainer from "./table-panel-container";
import { Results } from "~/features/results-panel";
import { AddTable } from "./add-table";
import type { TablePanelActorRef } from "../../../state/machines/dashboard/dashboard-panel-table/dashboard-table-panel.machine";
import { useTablePanelMachine } from "../../../state/machines/dashboard/dashboard-panel-table/dashboard-table-panel.machine";
import { LogoIcon } from "@fscrypto/ui";
import { ViewportListener } from "../dashboard-panel-visualizations/viewport-listener";
import type { CellWithRef } from "~/state/machines/dashboard/dashboard-grid/util";

export type CellWithTablePanelActorRef = dashboard.Cell & { ref: TablePanelActorRef };

export const DashboardTablePanel = ({ cell, isEditable }: { cell: CellWithRef; isEditable: boolean }) => {
  const {
    isLoading,
    isFetchingEphemeralQuery,
    isReady,
    isRefreshing,
    isSelecting,
    data,
    enteredViewport,
    selectItem,
    cancelAdd,
    onEnterViewport,
  } = useTablePanelMachine(cell.ref as TablePanelActorRef);

  if (isLoading || isFetchingEphemeralQuery) return <DownloadingResults />;
  const { query, queryRun, owner } = data;
  return (
    <ViewportListener hasEntered={enteredViewport} onEnterViewport={onEnterViewport}>
      {() => {
        if (isLoading || isReady || isRefreshing) return <DownloadingResults />;
        if (isSelecting) return <AddTable onCancel={cancelAdd} onSelect={(id: string) => selectItem(id)} />;
        return (
          <TablePanelContainer query={query!} title={query?.name} owner={owner} isEditable={isEditable}>
            {(isFetchingEphemeralQuery || isReady || isRefreshing) && <DownloadingResults />}

            {queryRun?.status === "failed" ? (
              <div>An error occurred while executing this query</div>
            ) : queryRun?.columns ? (
              <Results results={queryRun} queryId={query?.id!} dashboardView />
            ) : (
              <NoData />
            )}
          </TablePanelContainer>
        );
      }}
    </ViewportListener>
  );
};

export const NoData = () => (
  <div className="flex h-full w-full items-center justify-center">
    <LogoIcon className="text-gray-40 h-10 w-10  opacity-40" />
    <div className="ml-2 mt-2 text-sm text-gray-400">Run the query to see results</div>
  </div>
);
