import clsx from "clsx";
import { useEffect, useRef } from "react";

interface HeadingEditorProps {
  value: string;
  onChange: (value: string) => void;
  toggleEditing: () => void;
  isEditable: boolean;
  hAlign: string;
}
export const HeadingEditor = ({ onChange, hAlign, value, toggleEditing, isEditable }: HeadingEditorProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const hAlignClass = `text-${hAlign}`;

  useEffect(() => {
    // When the component mounts, focus the input
    isEditable && inputRef.current?.focus();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <input
      ref={inputRef}
      onFocus={(e) => {
        e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
        e.currentTarget.scrollTop = e.currentTarget.scrollHeight;
      }}
      onBlur={(e) => {
        toggleEditing();
        if (isEditable) {
          onChange(e.target.value);
        }
      }}
      className={clsx("w-full overflow-auto border-none bg-transparent outline-none", hAlignClass)}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      defaultValue={value}
      onKeyDown={(e) => {
        switch (e.key) {
          case "Escape":
            toggleEditing();
            break;
        }
      }}
    />
  );
};
