import type { ActorRefFrom } from "xstate";
import { assign, createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import type { WorkItem, WorkItemSearchType } from "@fscrypto/domain/work-item";
import { searchWorkItems } from "~/features/add-to-dashboard/async/search-work-items";
import { recentWorkItems } from "~/features/add-to-dashboard/async/recent-work-items";
import { useSpinDelay } from "spin-delay";

// const numberToShow = 5;

export const createSelectWorkItemMachine = ({ type }: { type: WorkItemSearchType }) => {
  return createMachine(
    {
      id: "SelectWorkItem",
      tsTypes: {} as import("./work-items-select.machine.typegen").Typegen0,
      schema: {
        context: {} as SelectWorkItemMachineContext,
        events: {} as SelectWorkItemEvents,
      },
      context: {
        searchTerm: "",
        searchedWorkItems: [],
        recentWorkItems: [],
        fetchedRecentItems: false,
      },

      initial: "recentResults",
      states: {
        idle: {},
        recentResults: {
          initial: "idle",
          states: {
            idle: {
              always: [
                {
                  target: "fetchRecent",
                  cond: "hasNotFetchedRecentItems",
                },
              ],
            },
            fetchRecent: {
              entry: ["markRecentItemsAsFetched"],
              invoke: {
                id: "recentWorkItems",
                src: "recentWorkItems",
                onDone: {
                  target: "idle",
                  actions: ["updateRecentWorkItemsList"],
                },
              },
            },
          },
        },
        searchResults: {
          states: {
            idle: {},
            debounce: {
              after: {
                500: {
                  target: "searchWorkItems",
                },
              },
            },
            searchWorkItems: {
              invoke: {
                id: "searchWorkItems",
                src: "searchWorkItems",
                onDone: {
                  target: "idle",
                  actions: ["updateSearchedList"],
                },
              },
            },
          },
        },
      },
      on: {
        CLOSE_MODAL: "idle",
        UPDATE_SEARCH_TERM: [
          {
            target: "searchResults.debounce",
            actions: ["updateSearchTerm"],
            cond: "isOverSearchThreshold",
          },
          {
            actions: ["updateSearchTerm", "clearSearchedList"],
            target: "recentResults",
          },
        ],
      },
    },
    {
      actions: {
        updateSearchTerm: assign((context, { searchTerm }) => {
          return {
            searchTerm,
          };
        }),
        clearSearchedList: assign((_) => {
          return {
            searchedWorkItems: [],
          };
        }),
        updateSearchedList: assign((context, event) => {
          return {
            searchedWorkItems: event.data,
          };
        }),
        updateRecentWorkItemsList: assign((context, event) => {
          return {
            recentWorkItems: event.data,
          };
        }),
        markRecentItemsAsFetched: assign((_) => {
          return {
            fetchedRecentItems: true,
          };
        }),
      },
      guards: {
        isOverSearchThreshold: (context, event) => event.searchTerm.length >= 3,
        hasNotFetchedRecentItems: (context) => context.fetchedRecentItems === false,
      },
      services: {
        searchWorkItems: (context) => {
          return searchWorkItems({
            term: context.searchTerm,
            type,
          });
        },
        recentWorkItems: () => {
          return recentWorkItems({
            type,
            limit: 10,
          });
        },
      },
    },
  );
};

interface SelectWorkItemMachineContext {
  searchTerm: string;
  searchedWorkItems: WorkItem[];
  recentWorkItems: WorkItem[];
  fetchedRecentItems: boolean;
}

type SelectWorkItemEvents =
  | { type: "OPEN_MODAL" }
  | { type: "CLOSE_MODAL" }
  | { type: "UPDATE_SEARCH_TERM"; searchTerm: string }
  | {
      type: "LATEST_WORK_ITEMS";
      dashboards: WorkItem[];
    }
  | {
      type: "done.invoke.searchWorkItems";
      data: WorkItem[];
    }
  | {
      type: "done.invoke.recentWorkItems";
      data: WorkItem[];
    };

export type SelectWorkItemRef = ActorRefFrom<ReturnType<typeof createSelectWorkItemMachine>>;

export const useSelectWorkItemMachine = (type: WorkItemSearchType) => {
  const [state, send] = useMachine(() => createSelectWorkItemMachine({ type }));
  const isSearchLoading = state.matches("searchResults.searchWorkItems") || state.matches("searchResults.debounce");
  const isLatestLoading = state.matches("recentResults.fetchRecent");
  const isLoading = useSpinDelay(isLatestLoading || isSearchLoading, { delay: 0, minDuration: 300 });
  return {
    searchTerm: state.context.searchTerm,
    updateSearchTerm: (searchTerm: string) => send({ type: "UPDATE_SEARCH_TERM", searchTerm }),
    recentWorkItems: state.context.recentWorkItems,
    searchedWorkItems: state.context.searchedWorkItems,
    isLoading,
    recentView: state.matches("recentResults"),
    searchView: state.matches("searchResults"),
    isSearchLoading,
    isLatestLoading,
  };
};
