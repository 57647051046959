import type { dashboard } from "@fscrypto/domain";
import clsx from "clsx";
import type { CellWithRef } from "~/state/machines/dashboard/dashboard-grid/util";
import type { HeadingPanelActorRef } from "~/state/machines/dashboard/dashboard-panel-heading/dashboard-panel-heading.machine";
import { useHeadingPanelMachine } from "~/state/machines/dashboard/dashboard-panel-heading/dashboard-panel-heading.machine";
import { HeadingDisplay } from "./heading-display";
import { HeadingEditor } from "./heading-editor";
import { headingColorStyles, headingHorizontalAlignStyles, headingSizeStyles } from "./heading-style-picker";

interface HeadingCellProps {
  cell: CellWithRef;
  isEditable?: boolean;
}
export type CellWithHeadingPanelActorRef = dashboard.Cell & { ref: HeadingPanelActorRef };

export const DashboardHeadingPanel = ({ cell, isEditable }: HeadingCellProps) => {
  const { isEditing, styles, toggleEditing, text, updateText } = useHeadingPanelMachine(
    cell.ref as HeadingPanelActorRef,
  );
  const editMode = isEditable && isEditing;
  const colorClassName = styles.colorKey ? headingColorStyles[styles.colorKey] : headingColorStyles["blue-fill"];
  const sizeClassName = styles.sizeKey ? headingSizeStyles[styles.sizeKey] : headingSizeStyles["h2"];
  const hAlignClassName = styles.hAlignKey
    ? headingHorizontalAlignStyles[styles.hAlignKey]
    : headingHorizontalAlignStyles["left"];

  return (
    <div
      className={clsx("flex h-full w-full flex-row items-center px-6", colorClassName, sizeClassName, hAlignClassName)}
      onMouseDown={(e) => {
        if (editMode) {
          e.stopPropagation();
        }
      }}
      onClick={(e) => {
        if (!isEditing) {
          e.stopPropagation();
          toggleEditing();
        }
      }}
    >
      {isEditing ? (
        <HeadingEditor
          value={text}
          onChange={updateText}
          toggleEditing={toggleEditing}
          isEditable={!!isEditable}
          hAlign={styles.hAlignKey ?? "left"}
        />
      ) : (
        <HeadingDisplay value={text} toggleEditing={toggleEditing} />
      )}
    </div>
  );
};
