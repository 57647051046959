import type { dashboard } from "@fscrypto/domain";
import { CodeIcon, HeadingIcon, ImageIcon, LucideProps, PieChartIcon, TableIcon, TextIcon } from "lucide-react";
import type { DragEvent } from "react";
import type ReactGridLayout from "react-grid-layout";
import { tracking } from "~/utils/tracking";
import type { AddCellToDashboardArgs } from "./dashboard-grid-layout";
import { useDashboardPanelDragType } from "../util/dashboardDragType";
import { getDefaultPanelXYHW } from "../util/default-panel-dimensions";
import { useFeatureFlag } from "~/state/feature-flags";

export const CommandBar = ({
  layout,
  onAddCellClick,
}: {
  layout: ReactGridLayout.Layout[];
  onAddCellClick: (cell: AddCellToDashboardArgs) => void;
}) => {
  const isEmbedEnabled = useFeatureFlag("dashboard-embed");
  return (
    <div className="dark fixed bottom-4 left-0 right-0 z-20 mx-auto hidden justify-center md:inline-flex">
      <div className=" bg-popover/90 flex justify-between space-x-6 rounded-lg p-2 shadow-lg backdrop-blur">
        <CommandBarItem Icon={PieChartIcon} type="chart" layout={layout} onAddCellClick={onAddCellClick} />
        <CommandBarItem Icon={TableIcon} type="table" layout={layout} onAddCellClick={onAddCellClick} />
        <CommandBarItem Icon={HeadingIcon} type="heading" layout={layout} onAddCellClick={onAddCellClick} />
        <CommandBarItem Icon={TextIcon} type="text" layout={layout} onAddCellClick={onAddCellClick} />
        <CommandBarItem Icon={ImageIcon} type="image" layout={layout} onAddCellClick={onAddCellClick} />
        {isEmbedEnabled && (
          <CommandBarItem Icon={CodeIcon} type="embed" layout={layout} onAddCellClick={onAddCellClick} />
        )}
      </div>
    </div>
  );
};

type DragType = "chart" | "text" | "heading" | "image" | "table" | "embed";

interface CommandBarItemProps {
  Icon: React.ComponentType<LucideProps>;
  type: DragType;
  layout?: ReactGridLayout.Layout[] | undefined;
  onAddCellClick: (cell: AddCellToDashboardArgs) => void;
}
export const CommandBarItem = ({ Icon, type, layout, onAddCellClick }: CommandBarItemProps) => {
  const [, setItem] = useDashboardPanelDragType();
  const onDragStart = (e: DragEvent) => {
    const componentType = typeMapper[type].type;
    setItem(componentType);
    e.stopPropagation();
    e.dataTransfer.setData("dashboard-drag", JSON.stringify(typeMapper[type]));
    tracking(`add_cell_drag_${type.toLowerCase()}`, "Dashboard Editor");
  };
  return (
    <div
      className={"hover:bg-foreground/10 flex cursor-grab flex-col items-center justify-center rounded-lg px-3 py-1"}
      onClick={() => {
        const { type: addType, ...rest } = typeMapper[type] as dashboard.Cell["formula"] & {
          type: dashboard.ComponentType;
        };
        if (layout) {
          onAddCellClick({
            type: addType,
            position: getDefaultPanelXYHW(addType),
            formula: rest,
            layout,
          });
          tracking(`add_cell_click_${addType.toLowerCase()}`, "Dashboard Editor");
        }
      }}
    >
      <div draggable={true} onDragStart={onDragStart}>
        <Icon className="text-contrast mb-1 h-4 w-4" />
      </div>
      <span className="text-foreground/80 text-xs capitalize">{type}</span>
    </div>
  );
};

export const defaultTextPanelValue = `Add text here. <a href="https://docs.flipsidecrypto.xyz/products/data-studio-sql-analysts/dashboard-editor/markdown-reference" target="_blank">Markdown</a> formatting is supported.`;

const typeMapper: Record<DragType, dashboard.Cell["formula"] & { type: dashboard.ComponentType }> = {
  heading: { type: "Heading", text: "Add heading here" },
  text: {
    type: "Text",
    text: defaultTextPanelValue,
  },
  image: { type: "Image", imageName: "" },
  chart: { type: "QueryVisual", visId: "" },
  table: { type: "QueryTable", queryId: "" },
  embed: { type: "Embed", url: "" },
};
