import type { ActorRefFrom, AnyActorRef, StateFrom } from "xstate";
import { assign, createMachine, spawn } from "xstate";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";
import { Subject } from "rxjs";
import type { EpicEvent } from "./epics";
import { createEpics } from "./epics";

export const createPersistDataMachine = (id: string) => {
  const actions$ = new Subject<EpicEvent>();
  const machine = createMachine(
    {
      id: "persist-data-machine",
      tsTypes: {} as import("./dashboard-persist-data.machine.typegen").Typegen0,
      schema: {
        context: {} as PersistDataContext,
        events: {} as GlobalEvent | EpicEvent,
      },
      context: {
        id,
      },
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      entry: ["setupEpic"],
      initial: "initial",
      states: {
        initial: {
          always: {
            target: "idle",
            actions: "initializeObservable",
          },
        },
        idle: {},
        complete: {
          after: {
            500: "idle",
          },
        },
        saving: {},
      },
      on: {
        "DASHBOARD_PERSIST.EPIC.UPDATE_SUCCESS": {
          actions: ["broadcastUpdateSuccess"],
          target: "complete",
        },
        "DASHBOARD_PERSIST.EPIC.UPDATE_FAILURE": {
          actions: ["handleError"],
          target: "idle",
        },
        "GLOBAL.DASHBOARD.PERSIST_UPDATES": {
          target: "saving",
          actions: ["updateDashboardEpic"],
          cond: "isDashboardId",
        },
        "DASHBOARD_PERSIST.EPIC.REALTIME_UPDATE_SUCCESS": {
          target: "idle",
        },
      },
    },
    {
      actions: {
        setupEpic: assign((ctx, _e) => {
          if (ctx.epic$) return {};
          const epic$ = createEpics(actions$);
          return {
            epic$: spawn(epic$),
          };
        }),
        broadcastUpdateSuccess: (context, event) => {
          eventBus.send({
            type: "GLOBAL.DASHBOARD.UPDATE_SUCCESS",
            payload: { dashboard: event.payload },
            dashboardId: context.id,
          });
        },
        updateDashboardEpic: (context) => {
          actions$.next({
            type: "DASHBOARD_PERSIST.EPIC.REALTIME_UPDATE",
            payload: { id: context.id },
          });
        },
        handleError: (context, event) => {
          if (event.payload?.status === 409) {
            eventBus.send({
              type: "TOAST.NOTIFY",
              notif: {
                type: "warning",
                timeout: 10000,
                title: "Your dashboard is out of date. Please refresh this page to get the latest dashboard",
              },
            });
          } else {
            eventBus.send({
              type: "TOAST.NOTIFY",
              notif: { title: "Unable to save dashboard", type: "error" },
            });
          }
        },
      },
      services: {
        globalEvents: () => eventBus.events$,
      },
      guards: {
        isDashboardId: (context, event) => {
          return event.dashboardId === context.id;
        },
      },
    },
  );
  return machine;
};

interface PersistDataContext {
  id: string;
  epic$?: AnyActorRef;
}

export type PersistDataActorRef = ActorRefFrom<ReturnType<typeof createPersistDataMachine>>;
export type PersistDataState = StateFrom<ReturnType<typeof createPersistDataMachine>>;
