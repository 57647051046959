import { $path } from "remix-routes";
import type { Observable } from "rxjs";
import { map, switchMap, merge, of, catchError, from } from "rxjs";
import { GET } from "~/async/fetch";
import { ofType } from "~/state/epics";
import type { CustomParameter } from "./dashboard-parameters.machine";

export type Event = FetchParameters | FetchParametersSuccess | FetchParametersFailure;

type FetchParameters = { type: "DASHBOARD_PARAMETERS.EPIC.FETCH"; payload: { dashboardId: string } };
type FetchParametersSuccess = { type: "DASHBOARD_PARAMETERS.EPIC.FETCH_SUCCESS"; payload: CustomParameter[] };
type FetchParametersFailure = { type: "DASHBOARD_PARAMETERS.EPIC.FETCH_FAILURE"; error: Error };

export const createEpics = (actions$: Observable<Event>): Observable<Event> => {
  return merge(fetchParametersEpic(actions$));
};

const fetchParametersEpic = (action$: Observable<Event>) => {
  return action$.pipe(
    ofType("DASHBOARD_PARAMETERS.EPIC.FETCH"),
    switchMap(({ payload }) =>
      from(fetchParameters(payload.dashboardId)).pipe(
        map((r) => ({ type: "DASHBOARD_PARAMETERS.EPIC.FETCH_SUCCESS", payload: r }) as FetchParametersSuccess),
        catchError((_) =>
          of({
            type: "DASHBOARD_PARAMETERS.EPIC.FETCH_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as FetchParametersFailure),
        ),
      ),
    ),
  ) as Observable<Event>;
};

// ASYNC API CALLS

const fetchParameters = async (dashboardId: string): Promise<CustomParameter[]> => {
  try {
    const data = await GET<{ parameters: CustomParameter[] }>(
      $path("/api/dashboards/:id/parameters/get", { id: dashboardId }),
    );
    return data.parameters;
  } catch (e) {
    throw new Error("Error FETCHing Dashboard");
  }
};
