import type { dashboard } from "@fscrypto/domain";
import invariant from "tiny-invariant";
import type { CellWithRef } from "~/state/machines/dashboard/dashboard-grid/util";
import {
  EmbedPanelActorRef,
  useEmbedPanelMachine,
} from "~/state/machines/dashboard/dashboard-panel-embed/dashboard-panel-embed.machine";
import { EmbedSelector } from "./embed-selector";

export type CellWithEmbedPanelActorRef = dashboard.Cell & { ref: EmbedPanelActorRef };

export const DashboardEmbedPanel = ({ cell }: { cell: CellWithRef }) => {
  const { url, shouldShowModal, cancelEmbed, onChange, persistEmbed } = useEmbedPanelMachine(
    cell.ref as EmbedPanelActorRef,
  );
  if (shouldShowModal) {
    return <EmbedSelector onCancel={cancelEmbed} onChangeValue={onChange} persistEmbed={persistEmbed} />;
  }
  invariant(url, "Image panel should have a url");
  return (
    <div className="h-full w-full p-2">
      <iframe
        title={cell.id}
        src={appendEmbedTrue(url)}
        style={{ width: "100%", border: "none", height: "100%" }}
      ></iframe>
    </div>
  );
};

function appendEmbedTrue(url: string) {
  // Check if the URL already has query parameters
  if (url.includes("?")) {
    // If 'embed=true' is not already part of the URL, append it with '&embed=true'
    if (!url.includes("embed=true")) {
      url += "&embed=true";
    }
  } else {
    // If the URL has no query parameters, add '?embed=true'
    url += "?embed=true";
  }
  return url;
}
