import { spawn } from "xstate";
import { createDashboardGridMachine } from "./dashboard-grid/dashboard-grid.machine";
import type { Dashboard } from "@fscrypto/domain/dashboard";
import { createParametersMachine } from "./dashboard-parameters/dashboard-parameters.machine";
import { createDashboardRefreshState } from "./dashboard-refresh/dashboard-refresh.machine";
import { createDashboardPublishMachine } from "~/state/machines/dashboard/dashboard-publish/dashboard-publish.machine";
import { createDashboardRoomState } from "~/state/machines/dashboard/dashboard-room/dashboard-room.machine";
import { createDashboardTabsMachine } from "~/state/machines/dashboard/dashboard-tabs/dashboard-tabs.machine";
import { createDashboardRoomOthersState } from "~/state/machines/dashboard/dashboard-room/dashboard-others.machine";
import { createPersistDataMachine } from "~/state/machines/dashboard/dashboard-persist/dashboard-persist-data.machine";

export const spawnInitialMachines = (dashboard: Dashboard, tabIndex: number, useRealtime: boolean) => {
  // these machines use the actorSystem, so we can initiate them here
  if (useRealtime) {
    createDashboardRoomState(dashboard.id, dashboard);
    createDashboardRoomOthersState(dashboard.id);
  }
  createDashboardRefreshState({
    queries: dashboard.queries.map((query) => ({ id: query.id, statement: query.statement, name: query.name })),
    dashboardId: dashboard.id,
    lastRefreshedAt: dashboard.lastRefreshedAt ? new Date(dashboard.lastRefreshedAt) : undefined,
  });

  //these machines don't use the actorSystem, so we can spawn them here
  return {
    dashboard: dashboard,
    persistDashboardData: spawn(createPersistDataMachine(dashboard.id)),
    dashboardGrid: spawn(
      createDashboardGridMachine(dashboard.draft.cells, dashboard.published?.cells ?? [], dashboard.id),
    ),
    dashboardTabs: spawn(
      createDashboardTabsMachine({
        tabs: dashboard.draft.tabs ?? [],
        dashboardId: dashboard.id,
        publishedTabs: dashboard.published?.tabs ?? [],
        tabIndex,
      }),
    ),
    dashboardPublish: spawn(createDashboardPublishMachine(dashboard.publishedAt, dashboard.id)),
    dashboardParameters: spawn(createParametersMachine({ dashboardId: dashboard.id })),
  };
};
