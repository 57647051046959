import { queryRun } from "@fscrypto/domain";
import type { Profile } from "@fscrypto/domain/profile";
import type { Query } from "@fscrypto/domain/query";
import { $path } from "remix-routes";
import type { Observable } from "rxjs";
import { map, switchMap, merge, of, catchError, from } from "rxjs";
import { GET } from "~/async/fetch";
import { ofType } from "~/state/epics";
import { fetchQueryRunResult } from "../../query-run/epics";

export type EpicEvent = FetchQueryData | FetchQueryDataSuccess | FetchQueryDataFailure;

type FetchQueryData = { type: "DASHBOARD_TABLE.EPIC.FETCH"; payload: { queryId: string } };
type FetchQueryDataSuccess = {
  type: "DASHBOARD_TABLE.EPIC.FETCH_SUCCESS";
  payload: { query: Query; queryRun?: queryRun.QueryRunResult; owner: Profile };
};
type FetchQueryDataFailure = { type: "DASHBOARD_TABLE.EPIC.FETCH_FAILURE"; error: Error };

export const createEpics = (actions$: Observable<EpicEvent>): Observable<EpicEvent> => {
  return merge(fetchQueryDataEpic(actions$));
};

const fetchQueryDataEpic = (action$: Observable<EpicEvent>) => {
  return action$.pipe(
    ofType("DASHBOARD_TABLE.EPIC.FETCH"),
    switchMap(({ payload }) =>
      from(fetchQueryData(payload.queryId)).pipe(
        map((r) => ({ type: "DASHBOARD_TABLE.EPIC.FETCH_SUCCESS", payload: r }) as FetchQueryDataSuccess),
        catchError((_) =>
          of({
            type: "DASHBOARD_TABLE.EPIC.FETCH_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as FetchQueryDataFailure),
        ),
      ),
    ),
  ) as Observable<EpicEvent>;
};

const fetchQueryData = async (queryId: string) => {
  try {
    const data = await GET<{ query: Query; owner: Profile }>($path("/api/dashboards/queries/:id/get", { id: queryId }));
    const queryRun = await fetchQueryRunResult(queryId);

    return {
      query: data.query,
      owner: data.owner,
      queryRun,
    };
  } catch (e) {
    throw new Error("Error retrieving query");
  }
};
