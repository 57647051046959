import type { DashboardTab } from "@fscrypto/domain/dashboard";
import { useSelector } from "@xstate/react";
import { nanoid } from "nanoid";
import type { ActorRefFrom, StateFrom } from "xstate";
import { assign, createMachine, spawn, toActorRef } from "xstate";
import { actorSystem } from "~/state";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";
import type { DashboardTabActorRef } from "./dashboard-tab.machine";
import { createTabMachine } from "./dashboard-tab.machine";
import type { DashboardActorRef } from "~/state/machines/dashboard/dashboard.machine";

interface CreateTabsMachineProps {
  tabs: DashboardTab[];
  dashboardId: string;
  publishedTabs: DashboardTab[];
  tabIndex: number;
}

export const createDashboardTabsMachine = ({ tabs, dashboardId, publishedTabs, tabIndex }: CreateTabsMachineProps) => {
  const machine = createMachine(
    {
      id: "dashboardTabsMachine",
      tsTypes: {} as import("./dashboard-tabs.machine.typegen").Typegen0,
      schema: {
        context: {} as DashboardTabsContext,
        events: {} as DashboardTabsEvent | GlobalEvent,
      },
      predictableActionArguments: true,
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      context: {
        activeDraftTabId: undefined,
        activePublishedTabId: undefined,
        draftTabs: [] as TabWithRef[],
        publishedTabs: [] as TabWithRef[],
      },
      initial: "initializingTabs",
      states: {
        initializingTabs: {
          always: [{ actions: ["setInitialTabs"], target: "ready" }],
        },
        ready: {
          on: {
            "DASHBOARD.TABS.ACTIVATE_TABS": {
              actions: ["createTab", "activateTabs", "persistTabs"],
            },
            "DASHBOARD.TABS.CREATE_TAB": {
              actions: ["createTab", "persistTabs"],
            },
            "DASHBOARD.TABS.SET_ACTIVE_TAB_ID": {
              actions: ["setActiveTabId", "setSearchParams", "broadcastActiveTabId"],
            },
            "DASHBOARD.TABS.REORDER_ITEMS": {
              actions: ["reorderItems", "persistTabs"],
            },
            "DASHBOARD.TABS.UPDATE_TAB": {
              actions: ["updateTab", "persistTabs"],
            },
            "DASHBOARD.TABS.REMOVE_TAB": {
              actions: ["removeTab", "removeCells", "persistTabs", "broadcastActiveTabId"],
            },
            // global events
            "GLOBAL.DASHBOARD_PUBLISH.PUBLISH_SUCCESS": {
              description:
                "When a dashboard is published, we want to save the current draft cells as the published cells",
              actions: ["createPublishedTabs"],
            },
            "GLOBAL.DASHBOARD_REALTIME.UPDATE_DATA": {
              actions: ["updateDraftFromRealtime"],
              cond: "isDashboardId",
            },
          },
        },
      },
    },
    {
      services: {
        globalEvents: () => eventBus.events$,
      },
      actions: {
        setActiveTabId: assign((context, event) => ({
          [event.variant === "draft" ? "activeDraftTabId" : "activePublishedTabId"]: event.id,
        })),
        setSearchParams: (_, event) => {
          const indexOfTab = tabs.findIndex((tab) => tab.id === event.id);
          const newUrl =
            indexOfTab === 0 ? window.location.pathname : `${window.location.pathname}?tabIndex=${indexOfTab}`;
          // this allows us to replace the current history state with the new url
          window.history.replaceState({ ...window.history.state, idx: window.history.state.idx ?? 0 }, "", newUrl);
        },
        setInitialTabs: assign((_) => {
          const initTabs = (sourceTabs: DashboardTab[]) =>
            sourceTabs.map((tab) => ({ ...tab, ref: spawn(createTabMachine({ ...tab })) }));

          const draft = initTabs(tabs);
          const published = initTabs(publishedTabs);

          return {
            draftTabs: draft,
            activeDraftTabId: draft[0]?.id,
            publishedTabs: published,
            activePublishedTabId: published[tabIndex]?.id ? published[tabIndex]?.id : published[0]?.id,
          };
        }),
        activateTabs: (context) => {
          const dashboardCellsRef = actorSystem.get<DashboardActorRef>(`dashboard-${dashboardId}`)?.getSnapshot()
            ?.context?.dashboardGrid;
          if (dashboardCellsRef) {
            dashboardCellsRef.send({
              type: "DASHBOARD.GRID.ACTIVATE_TAB_MODE",
              payload: {
                activeTabId: context.activeDraftTabId!,
              },
            });
          }
        },
        removeCells: (context, event) => {
          const dashboardCellsRef = actorSystem.get<DashboardActorRef>(`dashboard-${dashboardId}`)?.getSnapshot()
            ?.context?.dashboardGrid;
          if (dashboardCellsRef) {
            dashboardCellsRef.send({
              type: "DASHBOARD.GRID.REMOVE_TAB_CELLS",
              payload: {
                tabId: event.id,
                isLast: context.draftTabs.length === 0,
              },
            });
          }
        },
        persistTabs: (context) => {
          const currentDashboard = actorSystem.get<DashboardActorRef>(`dashboard-${dashboardId}`)?.getSnapshot()
            ?.context?.dashboard;
          if (currentDashboard) {
            const { draft } = currentDashboard;
            eventBus.send({
              type: "GLOBAL.DASHBOARD.SET_DATA",
              payload: {
                dashboard: {
                  draft: { ...draft, tabs: context.draftTabs.map(({ id, url, title }) => ({ id, url, title })) },
                },
              },
              dashboardId,
            });
          }
        },
        createTab: assign((context) => {
          const newId = nanoid(6);
          const newTabData = {
            title: "New Tab",
            url: "",
            id: newId,
          };
          return {
            activeDraftTabId: newId,
            draftTabs: [...context.draftTabs, { ...newTabData, ref: spawn(createTabMachine({ ...newTabData })) }],
          };
        }),
        reorderItems: assign((context, event) => {
          const { ids } = event;
          const newTabs = ids.map((id) => context.draftTabs.find((tab) => tab.id === id));
          return {
            draftTabs: newTabs as TabWithRef[],
          };
        }),
        updateTab: assign((context, event) => {
          const newTabs = context.draftTabs.map((tab) => {
            if (tab.id !== event.payload.tab.id) return tab;
            return {
              ...tab,
              ...event.payload.tab,
            };
          });
          return {
            draftTabs: newTabs,
          };
        }),
        removeTab: assign((context, event) => {
          const newTabs = context.draftTabs.filter((tab) => tab.id !== event.id);
          const newId =
            event.id === context.activeDraftTabId ? newTabs[newTabs.length - 1]?.id : context.activeDraftTabId;
          return {
            draftTabs: newTabs,
            activeDraftTabId: newId,
          };
        }),
        createPublishedTabs: assign((context) => {
          const newTabs = context.draftTabs.map(({ id, url, title }) => ({
            id,
            url,
            title,
            ref: spawn(createTabMachine({ id, url, title })),
          }));
          return {
            publishedTabs: newTabs,
            activePublishedTabId: newTabs[0]?.id,
          };
        }),
        updateDraftFromRealtime: assign((context, event) => {
          const { draft } = event.payload.dashboardData;

          // If there are no tabs and no draftTabs, return
          if (!draft.tabs && !context.draftTabs.length) return {};

          const existingTabMap = new Map(context.draftTabs.map((tab: TabWithRef) => [tab.id, tab]));

          // Use map to iterate through draft.tabs and preserve order
          const finalTabs: TabWithRef[] = (draft.tabs ?? []).map((tab) => {
            const existingTab = existingTabMap.get(tab.id);
            if (existingTab) {
              // Update existing tab
              return { ...existingTab, ...tab };
            } else {
              // Spawn a new machine for new tabs
              return {
                ...tab,
                ref: spawn(createTabMachine({ ...tab })),
              };
            }
          });
          return {
            draftTabs: finalTabs,
            // only set activeDraftTabId if there is only one tab
            ...(finalTabs.length === 1 && { activeDraftTabId: finalTabs[0]?.id }),
          };
        }),
      },
      guards: {
        isDashboardId: (_, event) => {
          return event.dashboardId === dashboardId;
        },
      },
    },
  );
  return machine;
};

export type TabWithRef = DashboardTab & { ref: DashboardTabActorRef };

interface DashboardTabsContext {
  activeDraftTabId?: string;
  draftTabs: TabWithRef[];
  publishedTabs: TabWithRef[];
  activePublishedTabId?: string;
}

export type DashboardTabsActorRef = ActorRefFrom<ReturnType<typeof createDashboardTabsMachine>>;
export type DashboardTabsState = StateFrom<ReturnType<typeof createDashboardTabsMachine>>;

type DashboardTabsEvent =
  | {
      type: "DASHBOARD.EVENT";
    }
  | {
      type: "DASHBOARD.TABS.ACTIVATE_TABS";
    }
  | {
      type: "DASHBOARD.TABS.CREATE_TAB";
    }
  | {
      type: "DASHBOARD.TABS.SET_ACTIVE_TAB_ID";
      id: string;
      variant: "draft" | "published";
    }
  | {
      type: "DASHBOARD.TABS.REORDER_ITEMS";
      ids: string[];
    }
  | {
      type: "DASHBOARD.TABS.UPDATE_TAB";
      payload: {
        tab: DashboardTab;
      };
    }
  | {
      type: "DASHBOARD.TABS.REMOVE_TAB";
      id: string;
    };

export const useDashboardTabsMachine = (id: string, variant: "draft" | "published") => {
  const dashboardTabsRef =
    actorSystem.get<DashboardActorRef>(`dashboard-${id}`)?.getSnapshot()?.context.dashboardTabs ??
    toActorRef({ send: () => {} });

  const activeDraftTabId = useSelector(dashboardTabsRef, activeDraftIdSelector);
  const activePublishedTabId = useSelector(dashboardTabsRef, activePublishedIdSelector);

  // Set activeTabId based on variant
  const activeTabId = variant === "draft" ? activeDraftTabId : activePublishedTabId;

  const draftTabs = useSelector(dashboardTabsRef, draftTabsSelector);
  const publishedTabs = useSelector(dashboardTabsRef, publishedTabsSelector);

  const tabs = variant === "draft" ? draftTabs : publishedTabs;

  return {
    tabs,
    publishedTabs: useSelector(dashboardTabsRef, publishedTabsSelector),
    activeTabId,
    activateTabMode: () => dashboardTabsRef.send({ type: "DASHBOARD.TABS.ACTIVATE_TABS" }),
    createTab: () => dashboardTabsRef.send({ type: "DASHBOARD.TABS.CREATE_TAB" }),
    setActiveTabId: (id: string) => dashboardTabsRef.send({ type: "DASHBOARD.TABS.SET_ACTIVE_TAB_ID", id, variant }),
    reorderItems: (ids: string[]) => dashboardTabsRef.send({ type: "DASHBOARD.TABS.REORDER_ITEMS", ids }),
    activePublishedTabId: useSelector(dashboardTabsRef, activePublishedIdSelector),
  };
};

const draftTabsSelector = (state: DashboardTabsState) => state?.context.draftTabs ?? [];
const activeDraftIdSelector = (state: DashboardTabsState) => state?.context.activeDraftTabId ?? undefined;
const publishedTabsSelector = (state: DashboardTabsState) => state?.context.publishedTabs ?? [];
const activePublishedIdSelector = (state: DashboardTabsState) => state?.context.activePublishedTabId ?? undefined;
