import { useEffect, useRef } from "react";
import { throttle } from "lodash-es";

interface ViewportListenerProps {
  children: () => React.ReactNode;
  onEnterViewport: () => void;
  hasEntered: boolean;
}

export const ViewportListener = ({ children, onEnterViewport }: ViewportListenerProps) => {
  const entered = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.addEventListener(
      "scroll",
      throttle(() => {
        if (ref.current && isDivInViewport(ref.current) && !entered.current) {
          entered.current = true;
          onEnterViewport();
        }
      }, 1000),
      true,
    );
    if (ref.current) {
      window.removeEventListener("scroll", () => {}, true);
    }
    return () => {
      window.removeEventListener("scroll", () => {}, true);
    };
  }, [entered, onEnterViewport]);
  useEffect(() => {
    if (ref.current && isDivInViewport(ref.current)) {
      entered.current = true;
      onEnterViewport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div ref={ref} className="h-full w-full">
      {children()}
    </div>
  );
};

const isDivInViewport = (el: HTMLDivElement) => {
  const rect = el?.getBoundingClientRect();
  if (!rect || !rect.height) return false;
  return (
    rect.height > 0 &&
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
    rect.top < (window.innerHeight || document.documentElement.clientHeight)
  );
};
