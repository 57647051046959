import type { query } from "@fscrypto/domain";
import { Avatar, LogoIcon, Text, Tooltip } from "@fscrypto/ui";
import React from "react";
import { Link } from "@remix-run/react";
import { $path } from "remix-routes";
import clsx from "clsx";
import { formatDateTimeAgo } from "~/utils/helpers";
import { useEmbedded } from "~/hooks/useEmbedded";
import { useTabs } from "~/state/machines/explorer-tabs/explorer-tabs-machine";
import type { Profile } from "@fscrypto/domain/profile";
import { useCurrentUser } from "~/features/current-user";
import { Visualization } from "@fscrypto/domain/visualization";
import { CheckCircleIcon } from "lucide-react";
import { tracking } from "~/utils/tracking";

interface PanelContainerProps {
  children: React.ReactNode;
  title?: string;
  owner?: Profile;
  id: string;
  isEditable: boolean;
  query?: query.Query;
  type?: Visualization["chart"]["type"];
  version?: "3";
}

const VisualizationPanelContainer = ({
  children,
  title,
  owner,
  id,
  query,
  isEditable,
  type,
  version,
}: PanelContainerProps) => {
  const { currentUser } = useCurrentUser();
  const [isHovering, setIsHovering] = React.useState(false);
  const isOwner = currentUser?.currentProfileId === owner?.id;
  const { linkTarget } = useEmbedded();
  const { addTab, isReady } = useTabs();
  if (!isReady) return null;

  const link = isOwner
    ? $path("/studio/queries/:id/visualizations/:vizId", { id: query?.id!, vizId: id })
    : $path("/:owner/q/:slugId/:slug", {
        owner: owner?.slug!,
        slugId: query?.slugId!,
        slug: query?.latestSlug!,
      });

  const isPublic = query?.visibility === "public";
  return (
    <div
      className="flex h-full w-full flex-col"
      data-testid="dashboard-visualization-panel"
      onMouseEnter={() => isPublic && setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="flex h-10 w-full items-center justify-between px-3">
        {(isPublic || isOwner) && query?.id && id ? (
          <Link
            className="flex items-center overflow-hidden"
            onClick={() => {
              tracking(`click_query_link_on_chart`, "Dashboard Editor");
              isOwner && addTab({ name: title!, id: query.id!, type: "query" });
            }}
            target={linkTarget}
            to={link}
          >
            <Tooltip content={title} side="top">
              <Text size="base" weight="medium" color="contrast" className="truncate">
                {(title ?? "").length > 0 || type === "big_number" ? title : "Untitled Chart"}
              </Text>
            </Tooltip>
          </Link>
        ) : (
          <p className={clsx("select-none truncate px-2 text-sm dark:text-white", { underline: isHovering })}>
            {title}
          </p>
        )}
        <div className="ml-4 flex flex-shrink-0 items-center justify-end">
          {owner && <OwnerPanel owner={owner} />}
          {/* this provides space if in edit mode for closing the panel */}
          {isEditable && <div className="w-12"></div>}
        </div>
      </div>
      <div className="h-[calc(100%_-_68px)] flex-1 p-2">{children}</div>

      <footer className="flex h-10 w-full items-center justify-between px-3">
        <p className="mr-auto flex space-x-1">
          <LogoIcon className="fill-contrast h-4 w-4" />
          <Text size="xs" color="muted">
            powered by flipside
          </Text>
        </p>
        {query?.lastSuccessfulExecutionAt && (
          <Text className="flex items-center space-x-1" size="xs" color="muted">
            <CheckCircleIcon className="text-muted-foreground size-3" />
            {formatDateTimeAgo(new Date(query.lastSuccessfulExecutionAt))}
          </Text>
        )}
        {/* this provides space if in edit mode for closing the panel */}
        {isEditable && <div className="hidden w-[32px] md:block"></div>}
      </footer>
    </div>
  );
};

export default VisualizationPanelContainer;

export const OwnerPanel = ({ owner }: { owner: Profile }) => {
  const { linkTarget } = useEmbedded();

  return (
    <Link className="max-w-1/2 flex items-center overflow-hidden" to={`${owner.profileUrl}`} target={linkTarget}>
      <Avatar size="sm" src={owner.avatarUrl ?? ""} alt={owner.name} />
      <span className="text-gray-70 hidden truncate pl-2 text-sm dark:text-gray-50 md:block">{owner.name}</span>
    </Link>
  );
};
