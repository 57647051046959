import type { dashboard } from "@fscrypto/domain";
import GridLayout from "react-grid-layout";
import { tracking } from "~/utils/tracking";
import { CommandBar } from "./command-bar";

import type { DashboardTab } from "@fscrypto/domain/dashboard";
import { useDashboardPanelDragType } from "../util/dashboardDragType";
import { getDefaultPanelHW } from "../util/default-panel-dimensions";
import { useDashboardGridMachine } from "../../../state/machines/dashboard/dashboard-grid/dashboard-grid.machine";
import { MovePanelButton } from "./move-panel-button";

import { RemoveCellButton } from "./remove-panel-button";
import { HeadingStylePicker } from "../dashboard-panel-heading/heading-style-picker";
import type { CellWithRef } from "~/state/machines/dashboard/dashboard-grid/util";
import { determineShouldPersist } from "~/state/machines/dashboard/dashboard-grid/util";
import { cardVariants } from "@fscrypto/ui";

interface DashboardGridLayoutProps {
  dashboardId: string;
  isEditable?: boolean;
  width: number;
  variant: "draft" | "published";
  children: (cell: CellWithRef) => React.ReactNode;
  tabs?: DashboardTab[];
}

//TODO: this is a crude way of determining the width threshold for the dashboard. We should use the theme breakpoints instead.
const WIDTH_THRESHOLD = 768;

export const DashboardGridLayout = ({ dashboardId, children, width, variant, tabs }: DashboardGridLayoutProps) => {
  const [item, setItem] = useDashboardPanelDragType();
  const hw = getDefaultPanelHW(item);
  const { layout, cells, updateLayout, addCell, removeCell, moveCellToTab } = useDashboardGridMachine(
    dashboardId,
    width,
    variant,
  );

  const isEditable = variant === "draft" && (width > WIDTH_THRESHOLD || width === 0);
  return (
    <>
      <GridLayout
        isDraggable={isEditable}
        isResizable={isEditable}
        isDroppable={isEditable}
        isBounded={true}
        className="layout h-full w-full pb-20"
        layout={layout}
        cols={12}
        resizeHandles={["se"]}
        droppingItem={{ ...hw, i: "dropped_item" }}
        onResizeStop={() => tracking("resize_cell", "Dashboard Editor", { dashboardId })}
        onLayoutChange={(newLayout) => {
          //The layout change is also changed when we drop a component.
          //This does not require a backend update until the component is added to the dashboard state
          const shouldPersist = determineShouldPersist({ newLayout, oldLayout: layout, cells });
          if (!shouldPersist || !isEditable) {
            return;
          }
          updateLayout(newLayout);
        }}
        onDragStop={() => tracking("reposition_cell", "Dashboard Editor", { dashboardId })}
        onDrop={async (layout, layoutItem, event: DragEvent) => {
          event.preventDefault();
          event.stopPropagation();
          const data = event.dataTransfer?.getData("dashboard-drag");
          if (!data) return;

          const { type, ...rest } = JSON.parse(data);
          addCell({ type, formula: rest, position: layoutItem, layout });
          setItem(null);
        }}
        onDropDragOver={() => {
          return hw;
        }}
        style={{ minHeight: "600px", marginBottom: "120px" }}
        rowHeight={96}
        width={width}
      >
        {cells.map((cell) => {
          return (
            <div className={cardVariants()} data-grid={cell.component} key={cell.id} style={{ overflow: "hidden" }}>
              {variant === "draft" && (
                <div className="absolute right-0 top-0 z-50 mt-1 flex h-8 flex-row items-center justify-end space-x-2 p-2">
                  {cell.component.type === "Heading" && <HeadingStylePicker cell={cell} />}
                  <MovePanelButton
                    tabs={tabs ?? []}
                    cell={cell}
                    onMoveCell={({ cellId, tabId }) => moveCellToTab(cellId, tabId)}
                  />
                  <RemoveCellButton
                    cellId={cell.id}
                    onRemoveCell={() => removeCell(cell.id!)}
                    dashboardId={dashboardId}
                  />
                </div>
              )}
              {children(cell)}
            </div>
          );
        })}
      </GridLayout>
      {variant === "draft" && (
        <>
          <div className="h-[170px]"></div>
          <CommandBar layout={layout} onAddCellClick={(cell) => addCell(cell)} />
        </>
      )}
    </>
  );
};

export interface AddCellToDashboardArgs {
  type: dashboard.ComponentType;
  formula: dashboard.Cell["formula"];
  position: Pick<ReactGridLayout.Layout, "x" | "y" | "w" | "h">;
  layout: ReactGridLayout.Layout[];
}
