import { UploadIcon } from "lucide-react";
import PulseLoader from "react-spinners/PulseLoader";

interface ImageEditorProps {
  onSelectFile: (file: File) => void;
  url?: string | null;
  isUploading: boolean;
}

export const ImageEditor = ({ onSelectFile, url, isUploading }: ImageEditorProps) => {
  return (
    <div className="flex items-center justify-center p-4">
      <label htmlFor="file" className="flex h-16 w-16 items-center justify-center rounded-full border-2 border-dashed">
        {isUploading ? (
          <PulseLoader loading={true} size={8} color="#ccc" />
        ) : url ? (
          <img src={url} alt="db_img" className="h-16 w-16 rounded-full object-contain" />
        ) : (
          <UploadIcon className="text-muted-foreground size-7" />
        )}
        <input
          id="file"
          name="file"
          type="file"
          accept="image/*"
          className="hidden"
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e?.target?.files?.[0];
            if (file) {
              onSelectFile(file);
            }
          }}
        />
      </label>
    </div>
  );
};
