import { Button, Input, Label, Modal } from "@fscrypto/ui";

interface EmbedSelectorProps {
  onCancel: () => void;
  onChangeValue: (value: string) => void;
  persistEmbed: () => void;
}

export const EmbedSelector = ({ onCancel, onChangeValue, persistEmbed }: EmbedSelectorProps) => {
  return (
    <Modal open={true} setOpen={(e) => !e && onCancel()} className="sm:w-md sm:max-w-md">
      <div className="p-6">
        <h2 className="mb-4 font-medium text-gray-600 dark:text-white">Embed Iframe</h2>
        <Label className="mb-2">Streamlit Embed Url</Label>
        <Input onChange={(e) => onChangeValue(e.target.value)} placeholder="paste embed url here" />
        <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
          You can get the embed URL from the share dialog in Streamlit.
        </p>
        <div className="flex space-x-2 pt-8">
          <Button
            variant="secondary"
            size="md"
            className="w-full"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" size="md" className="w-full disabled:opacity-20" onClick={persistEmbed}>
            Embed
          </Button>
        </div>
      </div>
    </Modal>
  );
};
