import { memo, useCallback, useMemo, useState } from "react";
import type { MouseEvent } from "react";
import { isObject } from "lodash-es";
import { Table, createColumnHelper, SortingState, OnChangeFn } from "@fscrypto/table";
import { getDateTimeDurationFromDates, getObjectSizeAsString, isValidURL } from "~/utils/helpers";
import { Tooltip, HoverCard } from "@fscrypto/ui";
import { truncate } from "lodash-es";
import { AnimatePresence, motion } from "framer-motion";
import { tracking } from "~/utils/tracking";
import clsx from "clsx";
import { ErrorBoundary } from "react-error-boundary";
import { useToaster } from "../toasts/toast.machine";
import { queryRun } from "@fscrypto/domain";
import { ChevronDownIcon, ChevronUpIcon, RotateCcwIcon } from "lucide-react";

const JsonResults = ({ data, type }: { data: object; type: string }) => {
  const [expanded, setExpanded] = useState(false);

  if (type === "array") {
    return <div>{JSON.stringify(data, undefined, 2)}</div>;
  } else {
    // check for object, as non-objects can be returned via the variant type
    if (typeof data === "object" && data !== null) {
      return (
        <AnimatePresence>
          {expanded ? (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
            >
              <div className={"items-top flex gap-x-1"}>
                <ChevronUpIcon
                  className={"text-muted-foreground h-5 w-5  cursor-pointer"}
                  onClick={() => setExpanded(false)}
                />
                <pre>{JSON.stringify(data, null, 2)}</pre>
              </div>
            </motion.div>
          ) : (
            <div className={"items-top flex gap-x-1"}>
              <ChevronDownIcon
                className={" text-muted-foreground h-5 w-5  cursor-pointer"}
                onClick={() => setExpanded(true)}
              />
              <span>{truncate(JSON.stringify(data), { length: 75 })}</span>
            </div>
          )}
        </AnimatePresence>
      );
    } else {
      return <div>{data}</div>;
    }
  }
};

const JsonResultsTranspose = ({ data }: { data: object }) => {
  const { notify } = useToaster();
  const copyClipboard = async (e: MouseEvent<HTMLElement>) => {
    const data = (e.target as HTMLElement).textContent;
    await navigator.clipboard.writeText(data ?? "");
    notify?.({ type: "success", title: "Copied to clipboard", timeout: 1000 });
  };

  // check for object, as non-objects can be returned via the variant type
  if (typeof data === "object" && data !== null) {
    return (
      <HoverCard.Root openDelay={300}>
        <HoverCard.Trigger>
          <div className={"hover:bg-gray-10"}>
            <span>{truncate(JSON.stringify(data), { length: 75 })}</span>
          </div>
        </HoverCard.Trigger>
        <HoverCard.Content side="bottom" sideOffset={10} align="start">
          <p className="text-gray-60 whitespace-pre p-2 text-sm font-normal shadow" onClick={copyClipboard}>
            {JSON.stringify(data, null, 2)}
          </p>
        </HoverCard.Content>
      </HoverCard.Root>
    );
  } else {
    return <div>{data}</div>;
  }
};

const LongString = ({ data }: { data: string }) => {
  const { notify } = useToaster();
  const copyClipboard = async (e: MouseEvent<HTMLElement>) => {
    const data = (e.target as HTMLElement).textContent;
    await navigator.clipboard.writeText(data ?? "");
    notify?.({ type: "success", title: "Copied to clipboard", timeout: 1000 });
  };

  if (data == null) return null;
  if (data.length > 75) {
    return (
      <HoverCard.Root openDelay={300}>
        <HoverCard.Trigger>
          <div className={"hover:bg-gray-10"}>
            <span>{truncate(data, { length: 75 })}</span>
          </div>
        </HoverCard.Trigger>
        <HoverCard.Content side="bottom" sideOffset={10} align="start">
          <p
            className="text-gray-60 min-h-min max-w-xl break-words p-2 text-sm font-normal shadow"
            onClick={copyClipboard}
          >
            {isValidURL(data) ? (
              <a target="_blank" href={data} rel="noreferrer" className="underline">
                {data}
              </a>
            ) : (
              <>{data}</>
            )}
          </p>
        </HoverCard.Content>
      </HoverCard.Root>
    );
  } else {
    return isValidURL(data) ? (
      <a target="_blank" href={data} rel="noreferrer" className="underline">
        {data}
      </a>
    ) : (
      <span>{data}</span>
    );
  }
};

interface ResultsProps {
  results: queryRun.QueryRunResult;
  dashboardView?: boolean;
  previewView?: boolean;
  isTransposed?: boolean;
  toggleTransposed?: () => void;
  onSortingChange?: OnChangeFn<SortingState>;
  sorting?: SortingState;
  queryId: string;
}

const ResultsComponent = ({
  results,
  dashboardView,
  previewView,
  isTransposed,
  toggleTransposed,
  onSortingChange,
  sorting,
  queryId,
}: ResultsProps) => {
  const startedAt = results.startedAt ? new Date(results.startedAt) : new Date();
  const endedAt = results.endedAt ? new Date(results.endedAt) : new Date();
  const duration = results.startedAt && results.endedAt ? getDateTimeDurationFromDates(startedAt, endedAt) : "";
  const transposeMode = !!isTransposed;
  const columns = useMemo(
    () => [
      createColumnHelper<number>().display({
        cell: (t) => <div className="text-center">{t.row.index + 1}</div>,
        id: "row_num",
        enableSorting: false,
        maxSize: 45,
        size: 45,
      }),
      ...results.columns.map((column, i) => {
        // Need to disable for this line as we don't know the types ahead of time
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return createColumnHelper<any>().accessor(
          (d) => {
            // this is for search
            if (typeof d[i] === "object") {
              return JSON.stringify(d[i]);
            }
            return d[i];
          },
          {
            header: column,
            cell: (props) => {
              // get the original value here so we're not using the json stringified value from up above
              const originalValue = props.row.original[i];
              if (
                ["variant", "json", "object", "array", "binary"].includes(results.types[i] as string) ||
                isObject(originalValue)
              ) {
                return transposeMode ? (
                  <JsonResultsTranspose data={originalValue} />
                ) : (
                  <JsonResults data={originalValue} type={results.types[i] as string} />
                );
              } else if (results.types[i] === "boolean") {
                return props.getValue() ? "true" : "false";
              } else {
                return <LongString data={originalValue} />;
              }
            },
          },
        );
      }),
    ],
    [results.columns, results.types, transposeMode],
  );

  const handleTranspose = () => {
    tracking("transpose_table", "Query Editor");
    toggleTransposed?.();
  };

  const TableData = useCallback(
    () => {
      return (
        <div className="dark:bg-gray-90 dark:text-gray-30 flex justify-between p-3 text-sm text-gray-50">
          <div className="flex items-center">
            <div className="items center mr-1 flex items-center">
              <span className="text-green-60 mr-1">Success</span>
              <div>{results.csvData.length} rows |</div>
            </div>
            <div>
              {getObjectSizeAsString(results.csvData)} in {duration}
            </div>
          </div>
          <div className="pr-0.5">
            <Tooltip content="Rotate" side="top">
              <span>
                <RotateCcwIcon
                  className="hover:bg-gray-15 dark:hover:bg-gray-80 mr-2 inline h-5 w-5 cursor-pointer rounded text-sm"
                  onClick={handleTranspose}
                />
              </span>
            </Tooltip>
          </div>
        </div>
      );
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [results],
  );
  return (
    <div
      className={clsx("absolute w-full max-w-full overflow-hidden", {
        "h-[calc(100%_-_10px)]": !dashboardView,
        "h-[calc(100%_-_36px)]": dashboardView,
      })}
    >
      <ErrorBoundary FallbackComponent={ErrorResults}>
        <Table
          tableId={queryId}
          columns={columns}
          data={results.csvData}
          paginate
          initialPageSize={20}
          headerInfo={TableData}
          dashboardView={dashboardView}
          previewView={previewView}
          transpose={transposeMode}
          onSortingChange={onSortingChange}
          sorting={sorting}
        />
      </ErrorBoundary>
    </div>
  );
};

const ErrorResults = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      Something went wrong while rendering your results
    </div>
  );
};

const Results = memo(ResultsComponent);

export { Results };
