const colors = [
  "#FF65A2", // Pink
  "#FF563F", // Orange
  "#31B967", // Green
  "#00FFFF", // Cyan
  "#5C77FF", // Blue
  "#4845D4", // Indigo
  "#8E0FC9", // Purple
];

export const getColorFromUUID = (uuid: string) => {
  let hash = 0;
  for (let i = 0; i < uuid.length; i++) {
    hash = ((hash << 5) - hash + uuid.charCodeAt(i)) | 0; // Convert to 32bit integer
  }
  const index = Math.abs(hash) % colors.length;
  return colors[index];
};
