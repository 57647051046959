import { cleanHtml } from "~/state/machines/dashboard/dashboard-panel-heading/util/clean-html";

interface HeadingDisplayProps {
  toggleEditing: () => void;
  value: string;
}

export const HeadingDisplay = ({ value, toggleEditing }: HeadingDisplayProps) => {
  return (
    <div
      className="scrollbar border-none bg-transparent outline-none"
      onBlur={() => toggleEditing()}
      onKeyDown={(e) => {
        switch (e.key) {
          case "Escape":
            toggleEditing();
            break;
        }
      }}
    >
      {cleanHtml(value) ?? "Add heading here"}
    </div>
  );
};
