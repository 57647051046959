import { HoverCard } from "@fscrypto/ui";
import clsx from "clsx";
import { AlignCenterIcon, AlignLeftIcon, AlignRightIcon, LucideIcon } from "lucide-react";
import type { CellWithRef } from "~/state/machines/dashboard/dashboard-grid/util";
import type { HeadingPanelActorRef } from "~/state/machines/dashboard/dashboard-panel-heading/dashboard-panel-heading.machine";
import { useHeadingPanelMachine } from "~/state/machines/dashboard/dashboard-panel-heading/dashboard-panel-heading.machine";

export type HeadingColorKey = keyof typeof headingColorStyles;
export type HeadingSizekey = keyof typeof headingSizeStyles;

export const headingColorStyles: Record<string, string> = {
  "black-outline":
    "bg-[#F6F6F6] border-[#EDEDED] text-[#1A1919] dark:bg-[#1A1919] dark:border-[#4D4D4D] dark:text-gray-30",
  "red-outline": "bg-white border-[#EDEDED] text-[#E3066A] dark:bg-[#252525] dark:border-[#4D4D4D] dark:text-[#E3066A]",
  "orange-outline":
    "bg-white border-[#EDEDED] text-[#FF5D2D] dark:bg-[#252525] dark:border-[#4D4D4D] dark:text-[#FF5D2D]",
  "yellow-outline":
    "bg-white border-[#EDEDED] text-[#CA8501] dark:bg-[#252525] dark:border-[#4D4D4D] dark:text-[#FFE620]",
  "green-outline":
    "bg-white border-[#EDEDED] text-[#64803D] dark:bg-[#252525] dark:border-[#4D4D4D] dark:text-[#BAEC70]",
  "blue-outline":
    "bg-white border-[#EDEDED] text-[#1B96FF] dark:bg-[#252525] dark:border-[#4D4D4D] dark:text-[#1B96FF]",
  "purple-outline":
    "bg-white border-[#EDEDED] text-[#BA01FF] dark:bg-[#252525] dark:border-[#4D4D4D] dark:text-[#BA01FF]",
  "black-fill": "bg-[#1A1919] text-white dark:bg-[#FFFFFF] dark:text-[#1A1919]",
  "red-fill": "bg-[#E3066A] text-white dark:text-gray-30",
  "orange-fill": "bg-[#FF5D2D] text-white dark:text-gray-30",
  "yellow-fill": "bg-[#FFE620] text-[#1A1919]",
  "green-fill": "bg-[#BAEC70] text-[#1A1919]",
  "blue-fill": "bg-[#1B96FF] text-white dark:text-gray-30",
  "purple-fill": "bg-[#BA01FF] text-white dark:text-gray-30",
  "black-gradient": "bg-gradient-to-r from-[#1A1919] via-[#3D0157] to-[#1F0974] text-white dark:text-gray-30",
  "red-gradient": "bg-gradient-to-r from-[#E3066A] to-[#3A49DA] text-white dark:text-gray-30",
  "orange-gradient": "bg-gradient-to-r from-[#FF5D2D] to-[#CB65FF] text-white dark:text-gray-30",
  "yellow-gradient": "bg-gradient-to-r from-[#FFE620] to-[#E5B9FE] text-[#1A1919]",
  "green-gradient": "bg-gradient-to-r from-[#ACF3E4] via-[#BAEC70] to-[#FCC003] text-[#1A1919]",
  "blue-gradient": "bg-gradient-to-r from-[#1B96FF] to-[#2E844A] text-white dark:text-gray-30",
  "purple-gradient": "bg-gradient-to-r from-[#902FE5] to-[#3069FE] text-white dark:text-gray-30",
};

export const headingSizeStyles: Record<string, string> = {
  h1: "text-[24px] lg:text-[44px] md:text-[30px] sm:text-[24px] font-weight-600",
  h2: "text-[20px] lg:text-[38px] md:text-[30px] sm:text-[20px] font-weight-500",
  h3: "text-[16px] lg:text-[32px] md:text-[24px] sm:text-[16px] font-weight-500",
};

export const headingHorizontalAlignStyles: Record<string, string> = {
  left: "justify-start",
  center: "justify-center",
  right: "justify-end",
};
export const headingHorizontalAlignIcons: Record<string, LucideIcon> = {
  left: AlignLeftIcon,
  center: AlignCenterIcon,
  right: AlignRightIcon,
};

type HeadingStylePickerProps = {
  cell: CellWithRef;
};

export const HeadingStylePicker = ({ cell }: HeadingStylePickerProps) => {
  const { styles, updateStyles } = useHeadingPanelMachine(cell.ref as HeadingPanelActorRef);
  const activeSizeKey = styles.sizeKey;
  const activeHAlignKey = styles.hAlignKey;
  return (
    <HoverCard.Root openDelay={300}>
      <HoverCard.Trigger>
        <div className="text-gray-70 flex h-6 w-8 cursor-pointer items-center justify-center rounded-md border bg-white px-1 text-xs shadow-sm">
          A
        </div>
      </HoverCard.Trigger>
      <HoverCard.Content
        side="left"
        sideOffset={10}
        align="start"
        draggable={true}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrag={(e) => e.preventDefault()}
        onPointerMove={(e) => {
          e.preventDefault();
        }}
      >
        <div className="z-50 p-4">
          <p className="dark:text-gray-30 text-md mb-4 font-light text-gray-600">Heading block color</p>
          <div className="grid grid-cols-7 justify-between gap-2.5">
            {Object.keys(headingColorStyles).map((key) => {
              const colorStyle = headingColorStyles[key];
              return (
                <button
                  key={key}
                  className={clsx(
                    "border-gray-20 inline-flex h-7 items-center justify-center rounded-md border px-1.5 text-sm font-medium tracking-wide shadow shadow-black/5",
                    colorStyle,
                  )}
                  onClick={() => {
                    updateStyles({
                      colorKey: key,
                      sizeKey: styles.sizeKey,
                      hAlignKey: styles.hAlignKey,
                    });
                  }}
                >
                  A
                </button>
              );
            })}
          </div>

          <p className="dark:text-gray-30 text-md mb-3 mt-4 font-light text-gray-600">Heading size</p>
          <div className="grid grid-cols-7 justify-between gap-2.5 align-bottom">
            {Object.keys(headingSizeStyles).map((key) => {
              return (
                <button
                  key={key}
                  className={clsx(
                    "border-gray-20 dark:border-gray-70 dark:text-gray-30 inline-flex h-7 items-center justify-center rounded-md border px-3 text-sm font-medium tracking-wide shadow shadow-black/5",
                    { "bg-gray-15 dark:text-gray-600": activeSizeKey === key },
                  )}
                  onClick={() => {
                    updateStyles({
                      sizeKey: key,
                      colorKey: styles.colorKey,
                      hAlignKey: styles.hAlignKey,
                    });
                  }}
                >
                  {key}
                </button>
              );
            })}
          </div>

          <p className="dark:text-gray-30 text-md mb-3 mt-4 font-light text-gray-600">Alignment</p>
          <div className="grid grid-cols-7 justify-between gap-2.5 align-bottom">
            {Object.keys(headingHorizontalAlignIcons).map((key) => {
              const Icon = headingHorizontalAlignIcons[key] as React.FC<{ className: string }>;
              return (
                <button
                  key={key}
                  className={clsx(
                    "border-gray-20 dark:border-gray-70 inline-flex h-7 items-center justify-center rounded-md border px-3 shadow shadow-black/5",
                    { "bg-gray-15 dark:text-gray-600": activeHAlignKey === key },
                  )}
                  onClick={() => {
                    updateStyles({
                      sizeKey: styles.sizeKey,
                      colorKey: styles.colorKey,
                      hAlignKey: key,
                    });
                  }}
                >
                  <Icon
                    className={clsx("text-gray-70 dark:text-gray-30 h-5 w-5", {
                      "dark:text-gray-600": activeHAlignKey === key,
                    })}
                  />
                </button>
              );
            })}
          </div>
        </div>
      </HoverCard.Content>
    </HoverCard.Root>
  );
};
