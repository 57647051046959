import { Input, Popover, TabsSquare } from "@fscrypto/ui";
import { ImageEditor } from "./imageEditor";
import clsx from "clsx";
import type { DashboardTabActorRef } from "~/state/machines/dashboard/dashboard-tabs/dashboard-tab.machine";
import { useTabMachine } from "~/state/machines/dashboard/dashboard-tabs/dashboard-tab.machine";
import { SettingsIcon, TrashIcon } from "lucide-react";
import { tracking } from "~/utils/tracking";

interface DashboardTabProps {
  tabRef: DashboardTabActorRef;
  activeId?: string;
  onSetActive: (id: string) => void;
  tabId: string | number;
  isEditable: boolean;
}

export const DashboardTab = ({ tabRef, activeId, onSetActive, isEditable }: DashboardTabProps) => {
  const { mouseEnter, mouseLeave, context, editTab, isHovering, removeTab, setImage, isUploading, updateTitle } =
    useTabMachine(tabRef);

  const { url, id, title } = context;
  const isActive = activeId == id;
  return (
    <TabsSquare.Tab
      key={id}
      tabId={id}
      isActive={isActive}
      onClick={(id) => {
        tracking(`click_dashboard_tab`, "Dashboard Editor");
        onSetActive(id);
      }}
      disableDrag={!isEditable}
    >
      <div className="flex w-full items-center" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <TabContent url={url} title={title} isActive={isActive} />

        {isEditable && (
          <div className="ml-2 h-5 w-5">
            <EditTab
              trigger={<EditButton onClick={editTab} isHovering={isHovering} />}
              onDelete={() => {
                if (!confirm("Are you sure you want to delete this tab?")) return;
                removeTab();
              }}
            >
              <ImageEditor onSelectFile={setImage} url={url} isUploading={isUploading} />
              <TitleInput title={title} onChange={updateTitle} />
            </EditTab>
          </div>
        )}
      </div>
    </TabsSquare.Tab>
  );
};

interface EditButtonProps {
  onClick: () => void;
  isHovering: boolean;
}

const EditButton = ({ onClick, isHovering }: EditButtonProps) => {
  return (
    <div className={clsx("h-5 w-5", { "opacity-100": isHovering, "opacity-0": !isHovering })}>
      <SettingsIcon className="text-muted-foreground size-5 cursor-pointer" onClick={onClick} />
    </div>
  );
};

const TitleInput = ({ onChange, title }: { onChange: (val: string) => void; title: string }) => {
  return (
    <Input
      size="sm"
      defaultValue={title}
      className="w-full"
      onBlur={(e) => {
        if (title !== e.target.value) {
          onChange(e.target.value);
        }
      }}
    />
  );
};

interface EditTabProps {
  trigger: React.ReactNode;
  children: React.ReactNode;
  onDelete: () => void;
}

const EditTab = ({ trigger, children, onDelete }: EditTabProps) => {
  return (
    <Popover.Root>
      <Popover.Trigger>{trigger}</Popover.Trigger>
      <Popover.Content side="right" onClick={(e) => e.stopPropagation()} overrideClickToClose={true}>
        <div className="w-[240px] p-2">
          <div className="flex w-full items-center justify-between">
            <p className="text-content/70 text-sm">Tab details</p>
            <TrashIcon onClick={onDelete} className="text-muted-foreground" />
          </div>
          {children}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

const TabContent = ({ url, title, isActive }: { url?: string; title: string; isActive: boolean }) => {
  return (
    <div className="dark:text-gray-30 flex flex-grow items-center">
      {url && (
        <img
          src={url}
          alt="db_img"
          className="ml-1 mr-2 h-5 w-5 rounded-full object-contain"
          style={{ opacity: isActive ? 1 : 0.4 }}
        />
      )}
      <p className="text-sm">{title}</p>
    </div>
  );
};
