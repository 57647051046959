import type { User } from "@fscrypto/domain/user";
import { Avatar, Text, Tooltip } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import { $path } from "remix-routes";

export const ContributorList = ({ contributors = [] }: { contributors: User[] }) => {
  const firstFiveElements = contributors.slice(0, 5);
  const restOfElements = contributors.slice(5);
  return (
    <div className="flex items-center gap-x-4">
      <Text>Contributors</Text>
      <div className="flex -space-x-3">
        {firstFiveElements.map((contributor) => {
          return (
            <Tooltip content={contributor.username} key={contributor.id} side="top">
              <div className="h-10 rounded-full border-4 border-white dark:border-gray-800">
                <Link to={$path("/:owner/dashboards", { owner: contributor.username })}>
                  <Avatar
                    size="md"
                    variant="circle"
                    src={contributor.avatarUrl}
                    key={contributor.id}
                    className=""
                    alt={contributor.username}
                  />
                </Link>
              </div>
            </Tooltip>
          );
        })}
        {restOfElements.length > 0 && (
          <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-gray-700 text-xs font-medium text-white hover:bg-gray-600 dark:border-gray-800">
            +{restOfElements.length}
          </div>
        )}
      </div>
    </div>
  );
};
