import type { Dashboard } from "@fscrypto/domain/dashboard";
import { $path } from "remix-routes";
import type { Observable } from "rxjs";
import { map, switchMap, merge, of, catchError, from } from "rxjs";
import { POST } from "~/async/fetch";
import { ofType } from "~/state/epics";

export type EpicEvent = UpdateRefreshedAt | UpdateRefreshedAtSuccess | UpdateRefreshedAtFailure;

type UpdateRefreshedAt = { type: "DASHBOARD_REFRESH.EPIC.UPDATE_LATEST"; payload: { dashboardId: string } };
type UpdateRefreshedAtSuccess = { type: "DASHBOARD_REFRESH.EPIC.UPDATE_LATEST_SUCCESS"; payload: Dashboard };
type UpdateRefreshedAtFailure = { type: "DASHBOARD_REFRESH.EPIC.UPDATE_LATEST_FAILURE"; error: Error };

export const createEpics = (actions$: Observable<EpicEvent>): Observable<EpicEvent> => {
  return merge(updateRefreshedAtEpic(actions$));
};

const updateRefreshedAtEpic = (action$: Observable<EpicEvent>) => {
  return action$.pipe(
    ofType("DASHBOARD_REFRESH.EPIC.UPDATE_LATEST"),
    switchMap(({ payload }) =>
      from(updateRefreshedAt(payload.dashboardId)).pipe(
        map((r) => ({ type: "DASHBOARD_REFRESH.EPIC.UPDATE_LATEST_SUCCESS", payload: r }) as UpdateRefreshedAtSuccess),
        catchError((_) =>
          of({
            type: "DASHBOARD_REFRESH.EPIC.UPDATE_LATEST_FAILURE",
            error: new Error("Unable to fetch live query data"),
          } as UpdateRefreshedAtFailure),
        ),
      ),
    ),
  ) as Observable<EpicEvent>;
};

const updateRefreshedAt = async (dashboardId: string): Promise<Dashboard> => {
  try {
    const data = await POST<Dashboard>($path("/api/dashboards/:id/set-last-refreshed-at", { id: dashboardId }));
    return data;
  } catch (e) {
    throw new Error("Error Updating last refreshed At");
  }
};
