import type { Observable } from "rxjs";
import {
  filter,
  map,
  switchMap,
  merge,
  interval,
  take,
  of,
  catchError,
  debounceTime,
  share,
  takeUntil,
  throttle,
  from,
} from "rxjs";
import { GET, POST } from "~/async/fetch";
import { ofType } from "~/state/epics";
import type { CustomParameter } from "../dashboard/dashboard-parameters/dashboard-parameters.machine";
import { isCustomParameter } from "../dashboard/dashboard-parameters/dashboard-parameters.machine";
import { compass } from "@fscrypto/compass";
import { queryRun } from "@fscrypto/domain";
import { EditorParam } from "~/features/query/data/query-param-engine";

export type EpicEvent =
  | CreateEphemeralQueryRun
  | CreateEphemeralQueryRunSuccess
  | CreateEphemeralQueryRunError
  | PollEphemeralQueryRun
  | PollEphemeralQueryRunSuccess
  | PollEphemeralQueryRunFailure
  | PollEphemeralQueryRunStatus;

type CreateEphemeralQueryRun = {
  type: "EPHEMERAL_QUERY_RUN.EPIC.CREATE";
  payload: {
    queryId: string;
    statement: string;
    parameters: CustomParameter[];
    executionType: compass.EphemeralExecutionType;
    dashboardId?: string;
  };
};
type CreateEphemeralQueryRunSuccess = { type: "EPHEMERAL_QUERY_RUN.EPIC.CREATE_SUCCESS"; payload: { token: string } };
type CreateEphemeralQueryRunError = { type: "EPHEMERAL_QUERY_RUN.EPIC.CREATE_ERROR"; error: string };
type PollEphemeralQueryRun = { type: "EPHEMERAL_QUERY_RUN.EPIC.POLL"; payload: { token: string } };
type PollEphemeralQueryRunStatus = { type: "EPHEMERAL_QUERY_RUN.EPIC.POLL_STATUS"; payload: queryRun.QueryRunResult };
type PollEphemeralQueryRunSuccess = { type: "EPHEMERAL_QUERY_RUN.EPIC.POLL_SUCCESS"; payload: queryRun.QueryRunResult };
type PollEphemeralQueryRunFailure = { type: "EPHEMERAL_QUERY_RUN.EPIC.POLL_FAILURE"; payload: queryRun.QueryRunResult };

/**
 * Merges multiple epic functions into a single Observable<Event>.
 * Merging these epics into one observable allows using a single subject for capturing all events.
 */
export const createEpics = (actions$: Observable<EpicEvent>): Observable<EpicEvent> => {
  return merge(createEphemeralQueryRunEpic(actions$), PollEphemeralQueryRunEpic(actions$));
};

/**
 * Epic to create a query run
 * @param {Observable<EpicEvent>} action$ - Stream of actions to listen to and dispatch other actions.
 * @returns {Observable<EpicEvent>} - Returns a stream of actions.
 */
const createEphemeralQueryRunEpic = (action$: Observable<EpicEvent>) => {
  return action$.pipe(
    ofType("EPHEMERAL_QUERY_RUN.EPIC.CREATE"),
    debounceTime(500),
    switchMap((action) => {
      return from(executeEphemeralQueryRun(action.payload)).pipe(
        map((r) => ({ type: "EPHEMERAL_QUERY_RUN.EPIC.CREATE_SUCCESS", payload: r }) as CreateEphemeralQueryRunSuccess),
        catchError((e) =>
          of({
            type: "EPHEMERAL_QUERY_RUN.EPIC.CREATE_ERROR",
            error: e.message ?? "unknown error",
          } as CreateEphemeralQueryRunError),
        ),
      );
    }),
  ) as Observable<EpicEvent>;
};

const PollEphemeralQueryRunEpic = (action$: Observable<EpicEvent>) => {
  return action$.pipe(
    // Only process poll events
    ofType("EPHEMERAL_QUERY_RUN.EPIC.POLL"),
    // Switch to an inner observable that polls the query run every second
    switchMap((a) => {
      const poll$ = interval(1000).pipe(
        // Throttle the poll to slow down over time
        throttle((x) => interval(Math.min(500 * x, 5000))),
        // Fetch the state of the query run
        switchMap(() => fetchResults(a.payload.token)),
        // Share the result with all subscribers
        share(),
      );
      // Create an observable that emits once the query run is finished
      const finished$ = poll$.pipe(
        filter((q) => q.status === "failed" || q.status === "finished"),
        take(1),
        share(),
      );
      // Map failed query runs to failure events
      const failure$ = finished$.pipe(
        filter((q) => q.status === "failed"),
        map((q) => ({ type: "EPHEMERAL_QUERY_RUN.EPIC.POLL_FAILURE", payload: q }) as PollEphemeralQueryRunFailure),
      );
      // Map successful query runs to success events
      const success$ = finished$.pipe(
        filter((q) => q.status === "finished"),
        map((q) => ({ type: "EPHEMERAL_QUERY_RUN.EPIC.POLL_SUCCESS", payload: q }) as PollEphemeralQueryRunSuccess),
      );
      // Map all query runs to status events until the query run is finished
      const status$ = poll$.pipe(
        map((q) => ({ type: "EPHEMERAL_QUERY_RUN.EPIC.POLL_STATUS", payload: q }) as PollEphemeralQueryRunStatus),
        takeUntil(finished$),
      );
      return merge(status$, failure$, success$);
    }),
  );
};

// ASYNC API Calls

const executeEphemeralQueryRun = async ({
  queryId,
  parameters,
  statement,
  executionType,
  dashboardId,
}: {
  queryId: string;
  parameters: CustomParameter[];
  statement: string;
  executionType: compass.EphemeralExecutionType;
  dashboardId?: string;
}) => {
  try {
    //todo: this looks like some velocity legacy stuff. should be cleaned up
    const params = convertParamsWithCommas(parameters);
    const p = (params ?? []).map((param) => ({
      name: param.name,
      value: isCustomParameter(param) ? param.customValue : param.value,
      type: param.type,
    }));
    const data = await POST<{ result: { token: string } }>(`/api/queries/${queryId}/ephemeral/execute`, {
      statement,
      parameters: p,
      executionType,
      dashboardId,
    });
    return data.result;
  } catch (e) {
    let message = "Failed to execute ephemeral query run";
    if (e instanceof Error) message = e.message;
    throw new Error(message);
  }
};

const fetchResults = async (token: string) => {
  try {
    const data = await GET<queryRun.QueryRunResult>(`/api/query-runs/ephemeral/${token}`);
    return data;
  } catch (e) {
    let message = "Failed to fetch query run";
    if (e instanceof Error) message = e.message;
    throw new Error(message);
  }
};

export const convertParamsWithCommas = (params: EditorParam[] | CustomParameter[]) => {
  return params.map((param) => {
    const { id, type, name, value, queryId } = param;
    if (value.includes(",")) {
      const values = value.split(",").map((val) => val.trim());
      return {
        ...param,
        id,
        type,
        name,
        value: values[0],
        restrictedValues: values.join(","),
        queryId,
      };
    }
    return { ...param, restrictedValues: null };
  });
};
