import type { ActorRefFrom, StateFrom } from "xstate";
import { createMachine, assign, sendParent } from "xstate";
import type { dashboard } from "@fscrypto/domain";
import { useSelector } from "@xstate/react";
import { eventBus } from "~/state/events";
import type { GlobalDashboardRoomEvent } from "~/state/machines/dashboard/dashboard-room/dashboard-room.machine";
import { cleanHtml } from "./util/clean-html";

interface HeadingPanelProps {
  cellId: string;
  text: string;
  styles: dashboard.Styles;
  dashboardId: string;
}

export const createHeadingPanelMachine = ({ cellId, text, styles, dashboardId }: HeadingPanelProps) => {
  const machine = createMachine(
    {
      id: `headingPanel-${cellId}`,
      tsTypes: {} as import("./dashboard-panel-heading.machine.typegen").Typegen0,
      schema: {
        context: {} as HeadingPanelContext,
        events: {} as HeadingPanelEvent | GlobalDashboardRoomEvent,
      },
      context: {
        text,
        cellId,
        styles,
      },
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      initial: "idle",
      states: {
        edit: {
          on: {
            "DASHBOARD.HEADER_PANEL.UPDATE_TEXT": {
              target: "idle",
              actions: ["updateCellFormula", "updateParentCellFormula"],
            },
          },
        },
        idle: {
          on: {
            "DASHBOARD.HEADER_PANEL.TOGGLE": "edit",
            "DASHBOARD.HEADER_PANEL.UPDATE_STYLES": {
              target: "idle",
              actions: ["updateCellStyles", "updateParentCellStyles"],
            },
          },
        },
      },
      on: {
        "GLOBAL.DASHBOARD_REALTIME.UPDATE_DATA": {
          actions: ["updateData"],
          cond: "isDashboard",
        },
      },
    },
    {
      actions: {
        updateParentCellFormula: sendParent((context, event) => {
          return {
            type: "DASHBOARD.GRID.UPDATE_CELL_FORMULA",
            payload: {
              cellId: context.cellId,
              formula: {
                text: cleanHtml(event.value),
              },
            },
          };
        }),
        updateCellFormula: assign((context, event) => {
          return {
            text: cleanHtml(event.value),
          };
        }),
        updateParentCellStyles: sendParent((context, event) => {
          return {
            type: "DASHBOARD.GRID.UPDATE_STYLES",
            payload: {
              cellId: context.cellId,
              styles: event.value,
            },
          };
        }),
        updateCellStyles: assign((context, event) => {
          return {
            styles: event.value,
          };
        }),
        updateData: assign((context, event) => {
          const cellInUpdate = event.payload.dashboardData.draft.cells.find((cell) => cell.id === context.cellId);
          if (cellInUpdate) {
            const { formula, styles } = cellInUpdate;
            const updatedContext = {
              //@ts-expect-error
              ...(formula?.text ? { text: formula.text } : {}),
              ...(styles ? { styles } : {}),
            };

            return updatedContext;
          }
          return {};
        }),
      },
      services: {
        globalEvents: () => eventBus.events$,
      },
      guards: {
        isDashboard: (context, event) => {
          return event.dashboardId === dashboardId;
        },
      },
    },
  );
  return machine;
};

interface HeadingPanelContext {
  text: string;
  cellId: string;
  styles: dashboard.Styles;
}

type HeadingPanelEvent =
  | {
      type: "DASHBOARD.HEADER_PANEL.TOGGLE";
    }
  | {
      type: "DASHBOARD.HEADER_PANEL.UPDATE_TEXT";
      value: string;
    }
  | {
      type: "DASHBOARD.HEADER_PANEL.UPDATE_STYLES";
      value: dashboard.Styles;
    }
  | {
      type: "DASHBOARD.HEADER_PANEL.UPDATE_TEXT_REALTIME";
      value: string;
    };

export type HeadingPanelActorRef = ActorRefFrom<ReturnType<typeof createHeadingPanelMachine>>;
export type HeadingPanelState = StateFrom<ReturnType<typeof createHeadingPanelMachine>>;

export const useHeadingPanelMachine = (cellRef: HeadingPanelActorRef) => {
  const isEditing = useSelector(cellRef, isEditingSelector);
  const styles = useSelector(cellRef, stylesSelector);
  const text = useSelector(cellRef, testSelector);

  const toggleEditing = () => cellRef.send("DASHBOARD.HEADER_PANEL.TOGGLE");
  const updateText = (value: string) => cellRef.send({ type: "DASHBOARD.HEADER_PANEL.UPDATE_TEXT", value });
  const updateStyles = (value: dashboard.Styles) =>
    cellRef.send({ type: "DASHBOARD.HEADER_PANEL.UPDATE_STYLES", value });

  return {
    isEditing,
    toggleEditing,
    updateText,
    updateStyles,
    styles,
    text,
  };
};

const isEditingSelector = (state: HeadingPanelState) => {
  return state.matches("edit");
};

const stylesSelector = (state: HeadingPanelState) => {
  return state.context.styles;
};

const testSelector = (state: HeadingPanelState) => {
  return state.context.text;
};
