import { useSelector } from "@xstate/react";
import type { ActorRefFrom, AnyActorRef, StateFrom } from "xstate";
import { assign, createMachine, spawn, toActorRef } from "xstate";
import { actorSystem } from "~/state";
import type { DashboardPublished } from "@fscrypto/domain/dashboard";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";
import type { DashboardActorRef, DashboardState } from "~/state/machines/dashboard/dashboard.machine";
import type { Event as EpicEvent } from "./epics";
import { createEpics } from "./epics";
import { Subject } from "rxjs";

export const createDashboardPublishMachine = (publishedAt: Date | null, dashboardId: string) => {
  const actions$ = new Subject<EpicEvent>();
  const machine = createMachine(
    {
      id: `dashboardPublish-${dashboardId}`,
      tsTypes: {} as import("./dashboard-publish.machine.typegen").Typegen0,
      schema: {
        context: {} as DashboardContext,
        events: {} as DashboardPublishRefEvent | EpicEvent | GlobalEvent,
      },
      context: {
        publishedAt,
      },
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      entry: ["setupEpic"],
      initial: "idle",
      states: {
        idle: {
          on: {
            "DASHBOARD_PUBLISH.PUBLISH": {
              description: "transition to publishing state",
              target: "publishing",
            },
            "DASHBOARD_PUBLISH.UNPUBLISH": {
              description: "transition to unpublishing state",
              target: "unpublishing",
            },
            "GLOBAL.DASHBOARD_PUBLISH.UNPUBLISH_SUCCESS": {
              description: "this is used to set data from a client published elsewhere",
              target: "idle",
              actions: ["handleUnpublish"],
              cond: "isDashboard",
            },
            "GLOBAL.DASHBOARD_PUBLISH.PUBLISH_SUCCESS": {
              description: "this is used to set data from a client published elsewhere",
              target: "idle",
              actions: ["handlePublishSuccess"],
              cond: "isDashboard",
            },
          },
        },
        publishingComplete: {
          on: {
            "DASHBOARD_PUBLISH.CLOSE_MODAL": "idle",
          },
        },
        publishing: {
          description:
            "when entering this state we publish the dashboard and broadcast to the global system that the dashboard is publishing",
          entry: ["publishDashboardEpic", "broadcastPublishing"],
          on: {
            "DASHBOARD_PUBLISH.EPIC.PUBLISH_SUCCESS": {
              description:
                "once the publishing request is complete we set the published data and broadcast that the publishing was a success",
              target: "publishingComplete",
              actions: ["handlePublishSuccess", "broadcastPublished"],
            },
            "DASHBOARD_PUBLISH.EPIC.PUBLISH_FAILURE": "error",
          },
        },
        unpublishing: {
          description:
            "when entering this state we unpublish the dashboard and broadcast to the global system that the dashboard is being unpublished.",
          entry: ["unpublishDashboardEpic"],
          on: {
            "DASHBOARD_PUBLISH.EPIC.UNPUBLISH_SUCCESS": {
              description:
                "once the unpublishing request is complete we remove published data and broadcast that the unpublishing was a success",
              target: "idle",
              actions: ["handleUnpublish", "broadcastUnpublished"],
            },
            "DASHBOARD_PUBLISH.EPIC.UNPUBLISH_FAILURE": "error",
          },
        },
        error: {},
      },
    },
    {
      actions: {
        setupEpic: assign((ctx, _e) => {
          if (ctx.epic$) return {};
          const epic$ = createEpics(actions$);
          return {
            epic$: spawn(epic$),
          };
        }),
        publishDashboardEpic: (_) => {
          actions$.next({
            type: "DASHBOARD_PUBLISH.EPIC.PUBLISH",
            payload: {
              dashboardId: dashboardId,
            },
          });
        },
        broadcastPublishing: (_) => {
          eventBus.send({
            type: "GLOBAL.DASHBOARD_PUBLISH.PUBLISHING",
            dashboardId: dashboardId,
          });
        },
        handlePublishSuccess: assign((_, event) => {
          return {
            publishedDashboard: event.payload,
            publishedAt: new Date(),
          };
        }),
        broadcastPublished: (_, event) => {
          eventBus.send({
            type: "GLOBAL.DASHBOARD_PUBLISH.PUBLISH_SUCCESS",
            payload: event.payload,
            dashboardId: dashboardId,
          });
        },
        handleUnpublish: assign((_) => {
          return {
            publishedAt: null,
            publishedDashboard: undefined,
          };
        }),
        unpublishDashboardEpic: (_) => {
          actions$.next({
            type: "DASHBOARD_PUBLISH.EPIC.UNPUBLISH",
            payload: {
              dashboardId: dashboardId,
            },
          });
        },
        broadcastUnpublished: (_, event) => {
          eventBus.send({
            type: "GLOBAL.DASHBOARD_PUBLISH.UNPUBLISH_SUCCESS",
            dashboardId: dashboardId,
            payload: event.payload,
          });
        },
      },
      services: {
        globalEvents: (_) => eventBus.events$,
      },
      guards: {
        isDashboard: (context, event) => {
          return event.dashboardId === dashboardId;
        },
      },
    },
  );
  return machine;
};

interface DashboardContext {
  publishedAt: Date | null;
  publishedDashboard?: DashboardPublished;
  epic$?: AnyActorRef;
}

export type DashboardPublishActorRef = ActorRefFrom<ReturnType<typeof createDashboardPublishMachine>>;
export type DashboardPublishState = StateFrom<ReturnType<typeof createDashboardPublishMachine>>;

type DashboardPublishRefEvent =
  | { type: "DASHBOARD_PUBLISH.PUBLISH" }
  | { type: "DASHBOARD_PUBLISH.UNPUBLISH" }
  | { type: "DASHBOARD_PUBLISH.CLOSE_MODAL" };

export type GlobalDashboardPublishEvent =
  | { type: "GLOBAL.DASHBOARD_PUBLISH.PUBLISHING"; dashboardId: string }
  | { type: "GLOBAL.DASHBOARD_PUBLISH.PUBLISH_SUCCESS"; payload: DashboardPublished; dashboardId: string }
  | { type: "GLOBAL.DASHBOARD_PUBLISH.UNPUBLISHING"; dashboardId: string }
  | { type: "GLOBAL.DASHBOARD_PUBLISH.UNPUBLISH_SUCCESS"; payload: DashboardPublished; dashboardId: string };

export const useDashboardPublishMachine = ({ dashboardId }: { dashboardId: string }) => {
  const dashboardRef = actorSystem.get<DashboardActorRef>(`dashboard-${dashboardId}`) ?? toActorRef({ send: () => {} });
  const publishMachineRef = dashboardRef.getSnapshot()?.context.dashboardPublish ?? toActorRef({ send: () => {} });
  return {
    dashboard: useSelector(dashboardRef, dashboardDataSelector),
    isPublished: useSelector(publishMachineRef, isPublishedSelector),
    isPublishing: useSelector(publishMachineRef, isPublishingSelector),
    isPublishingComplete: useSelector(publishMachineRef, isPublishingCompleteSelector),
    onPublish: () => publishMachineRef.send({ type: "DASHBOARD_PUBLISH.PUBLISH" }),
    onUnpublish: () => publishMachineRef.send({ type: "DASHBOARD_PUBLISH.UNPUBLISH" }),
    onCloseModal: () => publishMachineRef.send({ type: "DASHBOARD_PUBLISH.CLOSE_MODAL" }),
    publishedData: useSelector(publishMachineRef, publishedDataSelector),
  };
};

const isPublishedSelector = (state: DashboardPublishState) => {
  return state?.context.publishedAt;
};

const isPublishingSelector = (state: DashboardPublishState) => {
  return state?.matches("publishing");
};

const isPublishingCompleteSelector = (state: DashboardPublishState) => {
  return state?.matches("publishingComplete");
};

const publishedDataSelector = (state: DashboardPublishState) => {
  return state?.context.publishedDashboard;
};

const dashboardDataSelector = (state: DashboardState) => {
  return state?.context.dashboard;
};
