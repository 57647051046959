import type { ActorRefFrom, AnyActorRef, StateFrom } from "xstate";
import { spawn } from "xstate";
import { createMachine, assign } from "xstate";
import type { queryRun } from "@fscrypto/domain";
import {
  createQueryRun,
  type GlobalQueryRunEvent,
  type Ref as QueryRunActorRef,
} from "~/state/machines/query-run/query-run.machine";
import { actorSystem } from "~/state";
import { eventBus } from "~/state/events";
import type { Dashboard } from "@fscrypto/domain/dashboard";
import type { EpicEvent } from "./epics";
import { createEpics } from "./epics";
import { Subject } from "rxjs";
import { useActorFromSystem } from "~/state/system";

interface CreateRefreshMachineProps {
  queries: RefreshQuery[];
  dashboardId: string;
  lastRefreshedAt?: Date;
}

type GlobalEvent = GlobalQueryRunEvent;

export const createRefreshMachine = ({ queries, dashboardId, lastRefreshedAt }: CreateRefreshMachineProps) => {
  const actions$ = new Subject<EpicEvent>();
  const machine = createMachine(
    {
      id: `RefreshMachine`,
      predictableActionArguments: true,
      tsTypes: {} as import("./dashboard-refresh.machine.typegen").Typegen0,
      schema: {
        context: {} as RefreshContext,
        events: {} as RefreshEvent | GlobalEvent | EpicEvent,
      },
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      entry: ["setupEpic"],
      context: {
        queries,
        dashboardId,
        lastRefreshedAt,
        hasRefreshed: false,
      },
      initial: "idle",
      states: {
        idle: {
          on: {
            "DASHBOARD_REFRESH.REFRESH_DASHBOARD": "refreshing",
          },
        },
        refreshing: {
          entry: ["createQueryRunsForEachQuery", "executeRefresh"],
        },
        updateLastRefreshedAt: {
          entry: ["updateLastRefreshedEpic"],
          on: {
            "DASHBOARD_REFRESH.EPIC.UPDATE_LATEST_SUCCESS": {
              target: "idle",
              actions: ["informDashboardAllQueryRunsFinished"],
            },
            "DASHBOARD_REFRESH.EPIC.UPDATE_LATEST_FAILURE": {
              target: "idle",
            },
          },
        },
      },
      on: {
        "GLOBAL.QUERY_RUN.EXECUTED": {
          actions: ["queryRunExecuted", "informDashboardQueryRunExecuted"],
        },
        "GLOBAL.QUERY_RUN.FINISHED": [
          {
            actions: ["queryRunFinished"],
            cond: "allQueryRunsAreFinished",
            target: "updateLastRefreshedAt",
          },
          {
            actions: ["queryRunFinished"],
          },
        ],
        "GLOBAL.QUERY_RUN.FAILED": [
          {
            actions: ["queryRunFailed"],
            cond: "allQueryRunsAreFinished",
            target: "updateLastRefreshedAt",
          },
          {
            actions: ["queryRunFailed"],
          },
        ],
      },
    },
    {
      actions: {
        setupEpic: assign((ctx, _e) => {
          if (ctx.epic$) return {};
          const epic$ = createEpics(actions$);
          return {
            epic$: spawn(epic$),
          };
        }),
        updateLastRefreshedEpic: () => {
          actions$.next({
            type: "DASHBOARD_REFRESH.EPIC.UPDATE_LATEST",
            payload: {
              dashboardId,
            },
          });
        },
        queryRunExecuted: assign((context, event) => {
          return {
            queries: context.queries.map((query) => {
              if (query.id === event.payload.queryId) {
                return {
                  ...query,
                  status: "running" as RefreshQuery["status"],
                };
              }
              return query;
            }),
          };
        }),
        queryRunFinished: assign((context, event) => {
          return {
            queries: context.queries.map((query) => {
              if (query.id === event.payload.queryId) {
                return {
                  ...query,
                  status: "success" as RefreshQuery["status"],
                };
              }
              return query;
            }),
          };
        }),
        queryRunFailed: assign((context, event) => {
          return {
            queries: context.queries.map((query) => {
              if (query.id === event.payload.queryId) {
                return {
                  ...query,
                  status: "error" as RefreshQuery["status"],
                };
              }
              return query;
            }),
          };
        }),
        informDashboardAllQueryRunsFinished: (context, event) => {
          eventBus.send({
            type: "GLOBAL.DASHBOARD.REFRESH.REFRESH_FINISHED",
            payload: {
              dashboard: event.payload,
              dashboardId,
            },
            dashboardId,
          });
        },
        informDashboardQueryRunExecuted: (_) => {
          eventBus.send({
            type: "GLOBAL.DASHBOARD.REFRESH.QUERY_RUN_EXECUTED",
            payload: {
              dashboardId,
            },
          });
        },
        createQueryRunsForEachQuery: assign((context) => {
          return {
            queries: context.queries.map((query) => {
              return {
                ...query,
                queryRunRef: createQueryRun(query.id, undefined, query.statement),
              };
            }),
            hasRefreshed: true,
          };
        }),
        executeRefresh: (context) => {
          context.queries.forEach((q) => {
            q.queryRunRef?.send({ type: "QUERY_RUN.RUN_QUERY" });
          });
        },
      },
      guards: {
        allQueryRunsAreFinished: (context, event) => {
          const remainingQueries = context.queries.filter((query) => query.id !== event.payload.queryId);
          if (remainingQueries.length === 0) return true;
          return remainingQueries.every((query) => {
            return query.status === "success" || query.status === "error";
          });
        },
      },
      services: {
        globalEvents: (_) => eventBus.events$,
      },
    },
  );
  return machine;
};

interface RefreshContext {
  queries: RefreshQuery[];
  dashboardId: string;
  lastRefreshedAt?: Date;
  epic$?: AnyActorRef;
  hasRefreshed: boolean;
}

type RefreshEvent = {
  type: "DASHBOARD_REFRESH.REFRESH_DASHBOARD";
};

export type GlobalRefreshEvent =
  | {
      type: "GLOBAL.DASHBOARD.REFRESH.REFRESH_FINISHED";
      payload: {
        dashboardId: string;
        dashboard: Dashboard;
      };
      dashboardId: string;
    }
  | {
      type: "GLOBAL.DASHBOARD.REFRESH.QUERY_RUN_EXECUTED";
      payload: {
        dashboardId: string;
      };
    };

export type DashboardRefreshActorRef = ActorRefFrom<ReturnType<typeof createRefreshMachine>>;
export type DashboardRefreshActorState = StateFrom<ReturnType<typeof createRefreshMachine>>;

interface RefreshQuery {
  id: string;
  statement: string;
  queryRunRef?: QueryRunActorRef;
  latestData?: queryRun.QueryRun;
  status?: "idle" | "running" | "error" | "success";
  name: string;
}

//This is the old way of creating actor system machines, using it here because the dashboard machines are old
export const createDashboardRefreshState = (args: CreateRefreshMachineProps) => {
  let ref = actorSystem.get<DashboardRefreshActorRef>(`dashboardRefresh-${args.dashboardId}`);
  if (!ref) {
    const machine = createRefreshMachine(args);
    ref = actorSystem.registerMachine(machine, `dashboardRefresh-${args.dashboardId}`);
  }
  return ref;
};

export const useRefreshDashboardMachine = ({ dashboardId }: { dashboardId: string }) => {
  const [state, ref] = useActorFromSystem<DashboardRefreshActorRef>(`dashboardRefresh-${dashboardId}`);

  if (!state || !ref) return {} as const;
  return {
    lastRefreshedAt: state?.context.lastRefreshedAt,
    refreshing: state.matches("refreshing"),
    refreshDashboard: () => ref.send("DASHBOARD_REFRESH.REFRESH_DASHBOARD"),
    queries: state.context.queries,
    hasRefreshed: state.context.hasRefreshed,
  };
};
