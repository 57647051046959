import type { ActorRefFrom, StateFrom } from "xstate";
import { createMachine, assign, sendParent } from "xstate";
import { useSelector } from "@xstate/react";

interface EmbedPanelProps {
  cellId: string;
  url: string;
}

export const createEmbedPanelMachine = ({ cellId, url }: EmbedPanelProps) => {
  const machine = createMachine(
    {
      id: `imagePanel-${cellId}`,
      tsTypes: {} as import("./dashboard-panel-embed.machine.typegen").Typegen0,
      schema: {
        context: {} as EmbedPanelContext,
        events: {} as EmbedPanelEvent,
      },
      context: {
        url,
        cellId,
      },
      initial: "idle",
      on: {
        "DASHBOARD.EMBED_PANEL.UPDATE_URL": {
          target: "complete",
        },
      },
      states: {
        complete: {
          id: "complete",
        },
        idle: {
          always: [
            {
              target: "selecting",
              cond: "hasUrl",
            },
          ],
        },
        selecting: {
          on: {
            "DASHBOARD.EMBED_PANEL.UPDATE_URL": {
              actions: ["updateUrl"],
            },
            "DASHBOARD.EMBED_PANEL.CANCEL": {
              target: "cancel",
              actions: ["removeCell"],
            },
            "DASHBOARD.EMBED_PANEL.SAVE_CHANGES": {
              target: "complete",
              actions: ["persistValue"],
            },
          },
        },
        cancel: {},
      },
    },
    {
      actions: {
        updateUrl: assign((context, event) => {
          const url = event.url;
          return {
            url,
          };
        }),
        removeCell: sendParent((context) => {
          return {
            type: "DASHBOARD.GRID.REMOVE_CELL",
            payload: { cellId: context.cellId },
          };
        }),
        persistValue: sendParent((context, event) => {
          const url = context.url;
          return {
            type: "DASHBOARD.GRID.UPDATE_CELL_FORMULA",
            payload: {
              cellId: context.cellId,
              formula: { url },
            },
          };
        }),
      },
      guards: {
        hasUrl: (context) => !context.url,
      },
    },
  );
  return machine;
};

interface EmbedPanelContext {
  url?: string;
  cellId: string;
  selectedFile?: File;
}

type EmbedPanelEvent =
  | {
      type: "DASHBOARD.EMBED_PANEL.SAVE_CHANGES";
    }
  | {
      type: "DASHBOARD.EMBED_PANEL.UPDATE_URL";
      url: string;
    }
  | {
      type: "DASHBOARD.EMBED_PANEL.CANCEL";
    };

export type EmbedPanelActorRef = ActorRefFrom<ReturnType<typeof createEmbedPanelMachine>>;
export type EmbedPanelActorState = StateFrom<ReturnType<typeof createEmbedPanelMachine>>;

export const useEmbedPanelMachine = (cellRef: EmbedPanelActorRef) => {
  const url = useSelector(cellRef, urlSelector);

  return {
    url,
    onChange: (url: string) => {
      cellRef.send({ type: "DASHBOARD.EMBED_PANEL.UPDATE_URL", url });
    },
    shouldShowModal: useSelector(cellRef, shouldShowEmbed),
    cancelEmbed: () => {
      cellRef.send("DASHBOARD.EMBED_PANEL.CANCEL");
    },
    persistEmbed: () => {
      cellRef.send("DASHBOARD.EMBED_PANEL.SAVE_CHANGES");
    },
  };
};

const urlSelector = (state: EmbedPanelActorState) => state.context.url;
const shouldShowEmbed = (state: EmbedPanelActorState) => state.matches("selecting");
