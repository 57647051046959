import { Dropdown, Tooltip } from "@fscrypto/ui";
import type { dashboard } from "@fscrypto/domain";
import type { DashboardTab } from "@fscrypto/domain/dashboard";
import { ArrowLeftRightIcon } from "lucide-react";

type MovePanelButtonProps = {
  cell?: dashboard.Cell;
  onMoveCell: ({ tabId, cellId }: { tabId: string; cellId: string }) => void;
  tabs: DashboardTab[];
};

export const MovePanelButton = ({ cell, tabs, onMoveCell }: MovePanelButtonProps) => {
  const tabId = cell?.component.t;
  if (!tabId) {
    return null;
  }
  const otherTabs = tabs.filter((t) => t.id !== tabId);
  if (!otherTabs.length) {
    return null;
  }
  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <Tooltip content="Switch Tab" side="top">
          <div className="dark:border-gray-80 flex h-6 w-8 cursor-pointer items-center justify-center rounded-md border bg-white px-1 text-xs shadow-sm dark:bg-gray-100 ">
            <ArrowLeftRightIcon className="text-content/70 size-4" />
          </div>
        </Tooltip>
      </Dropdown.Trigger>
      <Dropdown.Content className="z-50">
        <Dropdown.Label>Select tab</Dropdown.Label>
        {otherTabs.map((tab) => {
          return (
            <Dropdown.Item
              key={tab.id}
              onClick={() => {
                onMoveCell({ tabId: tab.id, cellId: cell?.id! });
              }}
            >
              {tab?.title}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
