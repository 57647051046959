import type { workItem } from "@fscrypto/domain";
import type { WorkItem } from "@fscrypto/domain/work-item";
import { $path } from "remix-routes";
import { GET } from "~/async/fetch";

interface SearchWorkItemsParams {
  limit?: number;
  type?: workItem.WorkItemSearchType;
  version?: workItem.WorkItemVersion;
}

export const recentWorkItems = async ({ limit, version, type = "all" }: SearchWorkItemsParams) => {
  const url = $path("/api/work-items/recent", {
    limit: limit?.toString() ?? "",
    type: type ?? "",
    version: version ?? "",
  });
  const { items } = await GET<{ items: WorkItem[] }>(url);
  return items;
};
