import { Client, LiveObject, LsonObject, Room } from "@liveblocks/client";
import { BehaviorSubject, Observable, Subject, map } from "rxjs";
import { BaseUserMeta, Status } from "@liveblocks/core";
import { Json, JsonObject } from "../lib/json";
import { RoomMember, createInitialMemberData } from "../lib/members";
import {
  EnterRoomArgs,
  RealtimeClient,
  RealtimeObjectData,
  RealtimeRoom,
  RealtimeRoomMembers,
  YDocProvider,
} from "../core";
import { GenericEvent, RoomEvents, RoomMemberEvent, StorageEvent, YjsEvent } from "../lib/events";
import LiveblocksProvider from "@liveblocks/yjs";
import { Doc, UndoManager, Text } from "yjs";
import { createClient } from "@liveblocks/client";

/**
 * Implements the RealtimeClient interface to manage entry into real-time rooms with specific initial data and event handling.
 * @template TInitialData - The type of the initial data for the room, constrained to records of string keys and JsonObject values.
 * @template TRoomEvent - The type of events that can be emitted in the room, extending Json.
 */
export class RealtimeClientImp<
  TInitialData extends Record<string, JsonObject>,
  TRoomEvent extends GenericEvent,
  TInitialYjsData extends Record<string, string> = {},
> implements RealtimeClient<TInitialData, TRoomEvent, TInitialYjsData>
{
  private client: Client;
  private authEndpoint: string;
  private roomImp: RealtimeRoomImp<
    TInitialData,
    TRoomEvent,
    Record<keyof TInitialData, LiveObject<TInitialData[keyof TInitialData]>>,
    TInitialYjsData
  > | null = null;
  /**
   * Constructs a new instance of the RealtimeClient implementation.
   * @param {string} authEndpoint - The endpoint liveblocks needs to authenticate the user.
   */
  constructor(authEndpoint: string) {
    this.authEndpoint = authEndpoint;
    this.client = createClient({ authEndpoint: this.authenticateRoom.bind(this) });
  }
  /**
   * Allows a user to enter a room, initializing the room's state and subscribing to events.
   */
  enterRoom({ roomId, initialData, initialYjsData, userData }: EnterRoomArgs<TInitialData, TInitialYjsData>) {
    /**
     * This is the initial storage for the room, which is a record of LiveObjects for each key in the initial data.
     * This is a tricky part of the implementation, as it requires converting the initial data to LiveObjects.
     */
    const initialStorage = convertInitialDataToLiveStorage<TInitialData>(initialData);
    const { userId, username, avatarUrl } = userData;
    // Enter the room and return the room instance
    const { room, leave } = this.client.enterRoom<RoomMember, typeof initialStorage, {}, TRoomEvent>(roomId, {
      initialPresence: createInitialMemberData(userId, username, avatarUrl),
      initialStorage,
    });

    // Create a new instance of RealtimeRoom and return it
    const roomImp = new RealtimeRoomImp(room, leave, initialData, initialYjsData);
    this.roomImp = roomImp;
    return roomImp;
  }
  /**
   * Liveblocks will call this method to authenticate the user and allow them to enter the room.
   */
  async authenticateRoom(room: string) {
    try {
      const response = await fetch(this.authEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ room }),
      });
      return await response.json();
    } catch (e) {
      if (this.roomImp) {
        this.roomImp.sendAuthErrorEvent();
      }
    }
  }
}

/**
 * Implementation of the RealtimeRoom interface for managing interactions within a real-time room.
 * @template TInitialData - The type of the initial data for the room.
 * @template TRoomEvent - The type of events that can be emitted in the room.
 * @template TStorage - The type of storage used in the room, mapping initial data keys to LiveObject instances.
 * @template TInitialYjsData - The type of initial yjs dat fro the room.
 */
export class RealtimeRoomImp<
  TInitialData extends Record<string, JsonObject>,
  TRoomEvent extends GenericEvent,
  TStorage extends Record<keyof TInitialData, LiveObject<TInitialData[keyof TInitialData]>> = Record<
    keyof TInitialData,
    LiveObject<TInitialData[keyof TInitialData]>
  >,
  TInitialYjsData extends Record<string, string> = {},
> implements RealtimeRoom<TRoomEvent, TInitialData, TInitialYjsData>
{
  private eventSubject$: BehaviorSubject<RoomEvents<TInitialData>>;
  private customEventSubject$: Subject<TRoomEvent>;
  public getStatus: () => Status;
  private others: RoomMember[] = [];
  public room: Room<RoomMember, TStorage, {}, TRoomEvent>;
  public getOthers = () => this.others;
  public leaveRoom: () => void;
  private initialData: TInitialData;
  public storage: Record<keyof TInitialData, RealtimeObjectDataImp<TInitialData, TRoomEvent, TStorage>> = {} as Record<
    keyof TInitialData,
    RealtimeObjectDataImp<TInitialData, TRoomEvent, TStorage>
  >;
  public yjs: Record<keyof TInitialYjsData, YDocProviderImp<TRoomEvent>> | null = null;
  public members: RealtimeRoomMembers;

  /**
   * Constructs a new instance of the RealtimeRoom implementation.
   * @param {Room<Presence, TStorage, {}, TRoomEvent>} room - The Liveblocks Room instance for real-time communication.
   * @param {() => void} leaveRoom - Function to leave the room.
   * @param {TInitialData} initialData - The initial data for the room.
   * @param {TInitialYjsData} initialYjsData - initial yjs data values
   */
  constructor(
    room: Room<RoomMember, TStorage, {}, TRoomEvent>,
    leaveRoom: () => void,
    initialData: TInitialData,
    initialYjsData: TInitialYjsData = {} as TInitialYjsData,
  ) {
    this.eventSubject$ = new BehaviorSubject<RoomEvents<TInitialData>>({
      type: "ROOM.STATUS.INITIAL",
    });
    this.customEventSubject$ = new Subject<TRoomEvent>();
    this.room = room;
    this.initialData = initialData;
    this.subscribeToStatusEvents(room);
    this.subscribeToCustomEvents(room);
    this.getStatus = () => room.getStatus();
    this.leaveRoom = leaveRoom;
    this.members = new RealtimeRoomMembersImp(room);
    this.createStorage(room);

    ///!!!wait for room to be synced before setting up yjs
    this.room.events.storageStatus.subscribe((status) => {
      if (status === "synchronized") {
        this.initializeYjsData(initialYjsData);
      }
    });
  }
  // Observable stream of events for the room, including both system and custom events.
  public get roomEvent$(): Observable<RoomEvents<TInitialData>> {
    return this.eventSubject$.asObservable();
  }

  public get customEvent$(): Observable<TRoomEvent> {
    return this.customEventSubject$.asObservable();
  }

  public sendAuthErrorEvent(): void {
    this.eventSubject$.next({ type: "ROOM.STATUS.AUTH_ERROR" });
  }

  public sendRoomEvent(event: TRoomEvent): void {
    this.room.broadcastEvent({ ...event, topic: "realtime" });
  }

  // Subscribe to status events from the room and emit them as events
  private subscribeToStatusEvents(room: Room<RoomMember, TStorage, {}, TRoomEvent>) {
    room.events.status.subscribe((status) => {
      switch (status) {
        case "connected":
          this.eventSubject$.next({ type: "ROOM.STATUS.CONNECTED" });
          break;
        case "disconnected":
          this.eventSubject$.next({ type: "ROOM.STATUS.DISCONNECTED" });
          break;
        case "reconnecting":
          this.eventSubject$.next({ type: "ROOM.STATUS.RECONNECTING" });
          break;
        case "initial":
          this.eventSubject$.next({ type: "ROOM.STATUS.INITIAL" });
          break;
      }
    });
  }

  // Subscribe to custom events from the room and emit them as events
  private subscribeToCustomEvents(room: Room<RoomMember, TStorage, {}, TRoomEvent>) {
    room.events.customEvent.subscribe(({ event }) => {
      this.customEventSubject$.next(event);
    });
  }

  updatePresence(presence: RoomMember) {
    this.room.updatePresence(presence);
  }

  // Create the storage for the room, mapping initial data keys to RealtimeObjectData instances
  createStorage(room: Room<RoomMember, TStorage, {}, TRoomEvent>) {
    const storageMapping = {} as Record<keyof TInitialData, RealtimeObjectDataImp<TInitialData, TRoomEvent, TStorage>>;
    for (const key in this.initialData) {
      const realtimeObject = new RealtimeObjectDataImp<TInitialData, TRoomEvent, TStorage>(
        room,
        key,
        this.initialData[key],
      );
      storageMapping[key] = realtimeObject;

      realtimeObject.event$.subscribe((event) => {
        this.eventSubject$.next(event);
      });
    }
    this.storage = storageMapping;
  }
  private initializeYjsData(initialYjsData: TInitialYjsData) {
    const yjsMapping = {} as Record<keyof TInitialYjsData, YDocProviderImp<TRoomEvent>>;
    for (const key in initialYjsData) {
      const yjsProvider = new YDocProviderImp<TRoomEvent>(this.room, key, initialYjsData[key]);
      yjsMapping[key] = yjsProvider;

      yjsProvider.event$.subscribe((event) => {
        this.eventSubject$.next(event);
      });
    }
    this.yjs = yjsMapping;
  }
}

export class RealtimeRoomMembersImp<TRoomEvent extends GenericEvent, TStorage extends LsonObject = LsonObject>
  implements RealtimeRoomMembers
{
  public eventSubject$: Subject<RoomMemberEvent>;
  private room: Room<RoomMember, TStorage, {}, TRoomEvent>;
  private members: RoomMember[] = [];
  constructor(room: Room<RoomMember, TStorage, {}, TRoomEvent>) {
    this.eventSubject$ = new Subject<RoomMemberEvent>();
    this.room = room;
    this.subscribeToOthersEvents(room);
    this.updateMember = this.updateMember.bind(this);
  }

  // Subscribe to presence events from the room and emit them as events
  private subscribeToOthersEvents(room: Room<RoomMember, TStorage, {}, TRoomEvent>) {
    room.events.others.subscribe((othersEvent) => {
      const members = othersEvent.others.map((other) => other.presence);
      this.members = members;
      this.eventSubject$.next({
        type: "ROOM.MEMBERS.UPDATE",
        payload: {
          members,
          type: othersEvent.type,
        },
      });
    });
  }

  updateMember(presence: Partial<RoomMember>) {
    this.room.updatePresence(presence);
  }

  getOthers() {
    return this.room.getOthers().map((other) => other.presence);
  }
  // Observable stream of events for the room, including both system and custom events.
  public get event$(): Observable<RoomMemberEvent> {
    return this.eventSubject$.asObservable();
  }
}

/**
 * Implementation of the RealtimeObjectData interface for managing a specific piece of data within a real-time room.
 * @template TInitialData - The type of the initial data for the room.
 * @template TRoomEvent - The type of events that can be emitted in the room.
 * @template TStorage - The type of the storage, typically a live object.
 */
export class RealtimeObjectDataImp<
  TInitialData extends Record<string, JsonObject>,
  TRoomEvent extends Json = Json,
  TStorage extends LsonObject = LsonObject,
> implements RealtimeObjectData<TInitialData>
{
  private liveObject?: LiveObject<TInitialData[keyof TInitialData]>;

  public event$: Subject<StorageEvent<TInitialData>>;
  public root?: LiveObject<TStorage>;
  private room: Room<RoomMember, TStorage, {}, TRoomEvent>;
  private initialData: TInitialData[keyof TInitialData];
  private status: "connecting" | "connected" = "connecting";

  /**
   * Converts initial data into a format suitable for live synchronization within a room.
   * @template TInitialData - The type of the initial data for the room.
   * @param {TInitialData} initialData - The initial data to convert.
   * @returns {Record<keyof TInitialData, LiveObject<TInitialData[keyof TInitialData]>>} The converted storage object.
   */

  constructor(
    room: Room<RoomMember, TStorage, {}, TRoomEvent>,
    key: keyof TStorage,
    initialData: TInitialData[keyof TInitialData],
  ) {
    this.room = room;
    this.getStorage(key);
    this.event$ = new Subject<StorageEvent<TInitialData>>();
    this.initialData = initialData;
  }

  // this method is Liveblocks' way to get the storage and subscribe to it. Subscribing to the storage is necessary to listen to updates.
  // those updates are then emitted as events.
  async getStorage(key: keyof TStorage) {
    const { root } = await this.room.getStorage();
    this.status = "connected";
    const liveObject = root.get(key) as unknown as LiveObject<TInitialData[keyof TInitialData]>;
    if (liveObject) {
      this.liveObject = liveObject;
      //send current data as an event
      this.event$.next({
        type: "ROOM.STORAGE.UPDATE",
        payload: {
          data: liveObject.toImmutable() as TInitialData[keyof TInitialData],
          nodeKey: key as keyof TInitialData,
        },
      });
      // send updates
      this.room.subscribe(this.liveObject, (event) => {
        this.event$.next({
          type: "ROOM.STORAGE.UPDATE",
          payload: {
            data: event.toImmutable() as TInitialData[keyof TInitialData],
            nodeKey: key as keyof TInitialData,
          },
        });
      });
    }
  }
  // this is a simple method to get the immutable data from the live object.
  public getData<U extends keyof TInitialData = keyof TInitialData>(): TInitialData[U] {
    const data = this.liveObject?.toImmutable();
    if (this.status === "connected" && data) {
      return data as TInitialData[U];
    }
    return this.initialData as TInitialData[U];
  }
  // this is a simple method to update the live object with new data.
  public update(data: TInitialData[keyof TInitialData]) {
    this.liveObject?.update(data);
  }

  public get data$(): Observable<TInitialData[keyof TInitialData]> {
    return this.event$.pipe(map((event) => event.payload.data));
  }
}

// This is a complex method that converts the initial data to a storage object. This is necessary because Liveblocks requires the initial data to be in a specific format.
// Each key in the initial data is converted to a LiveObject, which is then used to synchronize the data in the room.
function convertInitialDataToLiveStorage<TInitialData extends Record<string, JsonObject>>(
  initialData: TInitialData,
): Record<keyof TInitialData, LiveObject<TInitialData[keyof TInitialData]>> {
  const result: Partial<Record<keyof TInitialData, LiveObject<TInitialData[keyof TInitialData]>>> = {};

  Object.keys(initialData).forEach((key) => {
    const value = initialData[key];
    result[key as keyof TInitialData] = new LiveObject(value) as LiveObject<TInitialData[keyof TInitialData]>;
  });

  return result as Record<keyof TInitialData, LiveObject<TInitialData[keyof TInitialData]>>;
}

export class YDocProviderImp<TRoomEvent extends GenericEvent> implements YDocProvider {
  public room: Room<RoomMember, {}, {}, TRoomEvent>;
  private eventSubject$: Subject<YjsEvent>;
  public provider: LiveblocksProvider<RoomMember, {}, BaseUserMeta, {}> | null = null;
  private yDoc: Doc | null = null;
  private yText: Text | null = null;
  private undoManager: UndoManager | null = null;
  constructor(room: Room<RoomMember, {}, {}, TRoomEvent>, docName: string, statement: string) {
    this.room = room;
    this.eventSubject$ = new Subject<YjsEvent>();
    this.createYProvider(docName, statement);
  }
  private createYProvider(docName: string, statement: string = "") {
    const yDoc = new Doc();
    const provider = new LiveblocksProvider(this.room, yDoc);
    const member = this.room.getPresence();
    provider.awareness.setLocalStateField("user", {
      name: member.username,
      color: member.color,
      colorLight: member.color + "70", // 6-digit hex code at 70% opacity
    });
    provider.once("sync", (isSynced: boolean) => {
      this.eventSubject$.next({
        type: "ROOM.YJS_PROVIDER.SYNCED",
        payload: isSynced,
      });
      this.setYTextIfEmpty(yDoc.getText(docName), statement);
    });
    this.yDoc = yDoc;
    const yText = yDoc.getText(docName);
    this.yText = yText;
    this.undoManager = new UndoManager(this.yText);
    this.provider = provider;
  }
  public destroy() {
    this.yDoc?.destroy();
    this.provider?.destroy();
  }
  // Observable stream of events for the room, including both system and custom events.
  public get event$(): Observable<YjsEvent> {
    return this.eventSubject$.asObservable();
  }
  public getAwareness(): LiveblocksProvider<RoomMember, {}, BaseUserMeta, {}>["awareness"] | null {
    return this.provider?.awareness ?? null;
  }
  public getYText() {
    return this.yText;
  }
  public getUndoManager() {
    return this.undoManager;
  }
  private setYTextIfEmpty(yText: Text, value: string) {
    if (yText.toString().length === 0 && !!value?.length) {
      yText.insert(0, value);
    }
  }
}
