import { Input, Modal } from "@fscrypto/ui";
import clsx from "clsx";
import { FileTreeIcon } from "~/features/file-explorer/components/file-explorer-row/file-tree-icon";
import { LoadingSkeleton } from "~/features/add-to-dashboard/add-to-dashboard";
import { convertVisType } from "~/features/file-explorer/components/file-explorer-row/default-content";
import type { WorkItem } from "@fscrypto/domain/work-item";
import { useSelectWorkItemMachine } from "~/state/machines/work-items-select/work-items-select.machine";
import { Search } from "lucide-react";

interface AddVisualizationProps {
  onCancel: () => void;
  onSelect: (id: string) => void;
}
export const AddVisualization = ({ onCancel, onSelect }: AddVisualizationProps) => {
  const { updateSearchTerm, searchTerm, searchView, isLoading, recentWorkItems, searchedWorkItems } =
    useSelectWorkItemMachine("visualization");
  return (
    <Modal open={true} setOpen={(e) => !e && onCancel()}>
      <div className={clsx("relative p-2")}>
        <div className="flex items-center">
          <FileTreeIcon type="visualization" />
          <h2 className="dark:text-gray-30 p-2 font-medium text-gray-600">Add visualization</h2>
        </div>
        <div className="min-h-[344px] space-y-2 p-2">
          <div>
            <FilterInput filterString={searchTerm} onSetFilter={updateSearchTerm} />
            {isLoading ? (
              <div className="mt-4 h-60 overflow-auto">
                <LoadingSkeleton />
              </div>
            ) : (
              <div className="mt-4 h-60 overflow-auto">
                {searchView
                  ? searchedWorkItems.map((item) => (
                      <VisualizationListItem item={item} onSelect={onSelect} key={item.id} />
                    ))
                  : recentWorkItems.map((item) => (
                      <VisualizationListItem item={item} onSelect={onSelect} key={item.id} />
                    ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface FilterInputProps {
  filterString: string;
  onSetFilter: (filter: string) => void;
}

export const FilterInput = ({ filterString, onSetFilter }: FilterInputProps) => {
  return (
    <div className="relative mt-1 rounded-md">
      <Input
        prefix={<Search />}
        value={filterString}
        onChange={(e) => onSetFilter(e.target.value)}
        name="visualization"
        className="w-full"
        placeholder="Find a visualization..."
      />
    </div>
  );
};

export const VisualizationListItem = ({ item, onSelect }: { item: WorkItem; onSelect: (id: string) => void }) => {
  const visTypeConverted = convertVisType(item.visType);
  const parentNames = item?.path?.split(",").slice(0, -1);
  return (
    <div
      className="hover:bg-gray-10 dark:text-gray-30 mb-2 flex cursor-pointer items-center rounded p-2 dark:hover:bg-gray-100"
      onClick={() => {
        onSelect(item.id);
      }}
    >
      <FileTreeIcon type="visualization" visType={visTypeConverted} />
      <p className="text-gray-70 dark:text-gray-30 ml-2">{item.name}</p>
      {parentNames?.length && (
        <div className="dark:text-gray-40 ml-2 text-sm text-gray-50">{parentNames.map((name) => ` / ${name}`)}</div>
      )}
    </div>
  );
};
