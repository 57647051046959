import { Select, TabsSquare, Tooltip, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@fscrypto/ui";
import { DashboardTab } from "./dashboard-tab";
import { useDashboardTabsMachine } from "~/state/machines/dashboard/dashboard-tabs/dashboard-tabs.machine";
import { PlusIcon } from "lucide-react";

interface DashboardTabsProps {
  dashboardId: string;
  variant: "published" | "draft";
}

export const DashboardTabs = ({ dashboardId, variant }: DashboardTabsProps) => {
  const { tabs, activeTabId, activateTabMode, createTab, setActiveTabId, reorderItems } = useDashboardTabsMachine(
    dashboardId,
    variant,
  );
  const isEditable = variant === "draft";
  if (tabs.length === 0 && isEditable) {
    return <ActivateTabMode onClick={activateTabMode} />;
  }
  if (tabs.length === 0) {
    return null;
  }

  const tabsForSelect = tabs.map((tab) => {
    return {
      name: tab.title,
      value: tab.id,
      icon: tab.url ? (
        <img src={tab.url} alt="db_img" className="ml-1 mr-2 h-5 w-5 rounded-full object-contain" />
      ) : null,
    };
  });

  return (
    <>
      <div className="hidden items-center px-1 py-3 sm:flex">
        <TabsSquare.Root
          disableDrag={!isEditable}
          reorderItems={(ids) => reorderItems(ids)}
          rightSlot={isEditable && <AddTab onClick={createTab} />}
        >
          {tabs.map((tab) => (
            <DashboardTab
              isEditable={isEditable}
              tabId={tab.id}
              tabRef={tab.ref}
              key={tab.id}
              activeId={activeTabId}
              onSetActive={(id) => setActiveTabId(id)}
            />
          ))}
        </TabsSquare.Root>
      </div>
      <div className="p-3 sm:hidden">
        <Select onValueChange={(tabId) => setActiveTabId(tabId)}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select tab" />
          </SelectTrigger>
          <SelectContent>
            {tabsForSelect.map((tab) => (
              <SelectItem value={tab.value} key={tab.value}>
                {tab.icon}
                {tab.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </>
  );
};

const AddTab = ({ onClick }: { onClick: React.MouseEventHandler<HTMLDivElement> }) => {
  return (
    <Tooltip content="Create new" side="top">
      <div onClick={onClick}>
        <PlusIcon className="hover:bg-gray-20 hover:text-gray-70 text-muted-foreground mx-2 cursor-pointer rounded" />
      </div>
    </Tooltip>
  );
};

const ActivateTabMode = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      onClick={onClick}
      className="text-gray-40 dark:bg-gray-80 dark:border-gray-80 m-2 flex items-center justify-center rounded border-2 border-dashed bg-white p-4"
    >
      Click here to activate tab mode
    </div>
  );
};
