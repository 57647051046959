import type { AnyActorRef, ActorRefFrom } from "xstate";
import { createMachine, assign, spawn } from "xstate";
import type { CustomParameter } from "../../../state/machines/dashboard/dashboard-parameters/dashboard-parameters.machine";
import { Subject } from "rxjs";
import type { EpicEvent } from "./epics";
import { createEpics } from "./epics";
import invariant from "tiny-invariant";
import { eventBus } from "~/state/events";
import { actorSystem } from "~/state/system";
import { compass } from "@fscrypto/compass";
import { queryRun } from "@fscrypto/domain";

interface CreateEphemeralQueryMachineProps {
  queryId: string;
  statement: string;
  parameters: CustomParameter[];
  dashboardId: string;
  executionType: compass.EphemeralExecutionType;
}

export const createEphemeralQueryMachine = ({
  queryId,
  statement,
  parameters,
  executionType,
  dashboardId,
}: CreateEphemeralQueryMachineProps) => {
  const actions$ = new Subject<EpicEvent>();
  return createMachine(
    {
      predictableActionArguments: true,
      id: `dashboardEphemeralQuery`,
      tsTypes: {} as import("./query-run-ephemeral.machine.typegen").Typegen0,
      schema: {
        context: {} as EphemeralQueryContext,
        events: {} as EpicEvent | EphemeralQueryRunRefEvent,
      },
      initial: "executeQuery",
      context: {
        queryId,
        statement,
        parameters,
        dashboardId,
        executionType,
      },
      entry: ["setupEpic"],
      states: {
        idle: {
          on: {
            "EPHEMERAL_QUERY_RUN.EXECUTE": {
              target: "executeQuery",
            },
          },
        },
        executeQuery: {
          entry: "executeEphemeralQueryRunEpic",
          on: {
            "EPHEMERAL_QUERY_RUN.EPIC.CREATE_SUCCESS": {
              target: "polling",
              actions: ["setToken", "handlePolling"],
            },
            "EPHEMERAL_QUERY_RUN.EPIC.CREATE_ERROR": {
              target: "#error",
            },
          },
        },
        polling: {
          entry: ["pollQueryRunEpic"],
          on: {
            "EPHEMERAL_QUERY_RUN.EPIC.POLL_SUCCESS": {
              actions: ["handleSuccess"],
              target: "#success",
            },
            "EPHEMERAL_QUERY_RUN.EPIC.POLL_FAILURE": {
              actions: ["handleError"],
              target: "#error",
            },
            "EPHEMERAL_QUERY_RUN.EPIC.POLL_STATUS": {
              actions: ["handlePolling"],
            },
          },
        },
        success: {
          id: "success",
          type: "final",
          entry: ["cleanupSystem"],
        },
        canceled: {
          id: "canceled",
          type: "final",
          entry: ["cleanupSystem"],
        },
        error: {
          id: "error",
          type: "final",
          entry: ["cleanupSystem"],
        },
      },
    },
    {
      actions: {
        // this create all the query run epics and stores them in context
        setupEpic: assign((ctx, _e) => {
          if (ctx.epic$) return {};
          const epic$ = createEpics(actions$);
          return {
            epic$: spawn(epic$),
          };
        }),
        cleanupSystem: () => {
          actorSystem.unregister(`queryRunEphemeral-${queryId}`);
        },
        executeEphemeralQueryRunEpic: (ctx) => {
          actions$.next({
            type: "EPHEMERAL_QUERY_RUN.EPIC.CREATE",
            payload: {
              queryId: ctx.queryId,
              statement: ctx.statement,
              parameters: ctx.parameters ?? [],
              executionType: ctx.executionType,
              dashboardId: ctx.dashboardId,
            },
          });
        },
        setToken: assign((context, event) => {
          return { token: event.payload.token };
        }),
        pollQueryRunEpic: (ctx) => {
          invariant(ctx.token, "Token must be set");
          actions$.next({ type: "EPHEMERAL_QUERY_RUN.EPIC.POLL", payload: { token: ctx.token } });
        },
        handlePolling: () => {
          eventBus.send({ type: "GLOBAL.EPHEMERAL_QUERY_RUN.POLLING", payload: { queryId } });
        },
        handleError: () => {
          eventBus.send({ type: "GLOBAL.EPHEMERAL_QUERY_RUN.ERROR", payload: { queryId } });
        },
        handleSuccess: (ctx, e) => {
          eventBus.send({
            type: "GLOBAL.EPHEMERAL_QUERY_RUN.FINISHED",
            payload: { queryId, result: e.payload },
          });
        },
      },
      services: {},
    },
  );
};

interface EphemeralQueryContext {
  dashboardId?: string;
  executionType: compass.EphemeralExecutionType;
  queryId: string;
  statement: string;
  parameters?: CustomParameter[];
  token?: string;
  result?: queryRun.QueryRunResult;
  epic$?: AnyActorRef;
}

export type EphemeralQueryRunRefEvent = { type: "EPHEMERAL_QUERY_RUN.EXECUTE" };

export type GlobalEphemeralQueryRunEvent =
  | { type: "GLOBAL.EPHEMERAL_QUERY_RUN.EXECUTED"; payload: { queryId: string } }
  | { type: "GLOBAL.EPHEMERAL_QUERY_RUN.POLLING"; payload: { queryId: string } }
  | { type: "GLOBAL.EPHEMERAL_QUERY_RUN.FINISHED"; payload: { queryId: string; result: queryRun.QueryRunResult } }
  | { type: "GLOBAL.EPHEMERAL_QUERY_RUN.ERROR"; payload: { queryId: string; error?: Error } };

export type Ref = ActorRefFrom<typeof createEphemeralQueryMachine>;

export const createEphemeralQueryRun = ({
  queryId,
  statement = "",
  parameters,
  executionType,
  dashboardId,
}: CreateEphemeralQueryMachineProps) => {
  let ref = actorSystem.get<Ref>(`queryRunEphemeral-${queryId}`);
  if (!ref) {
    const machine = createEphemeralQueryMachine({ queryId, statement, parameters, executionType, dashboardId });
    ref = actorSystem.registerMachine(machine, `queryRunEphemeral-${queryId}`);
  }
  if (ref) {
    ref.send({ type: "EPHEMERAL_QUERY_RUN.EXECUTE" });
  }
  return ref;
};
