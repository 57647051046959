import type { query } from "@fscrypto/domain";
import { isValid } from "date-fns";
import { Flex, Input, Text } from "@fscrypto/ui";
import { SettingsIcon } from "lucide-react";

interface TypedParamInputProps {
  parameter: query.QueryParameter;
  onChange: (param: query.QueryParameter) => void;
  onSettingsClick?: (param: query.QueryParameter) => void;
  disabled?: boolean;
}
const TypedParamInput = ({ parameter, onChange, onSettingsClick, disabled }: TypedParamInputProps) => {
  const handleChange = (value: string) => {
    onChange({ ...parameter, value, restrictedValues: parameter.type === "list" ? parameter.restrictedValues : null });
  };

  const getTypedValue = () => {
    switch (parameter.type) {
      case "date":
        return isValid(new Date(parameter.value)) ? parameter.value : "";
      case "number":
      default:
        return parameter.value;
    }
  };
  const value = getTypedValue();
  return (
    <div className="relative flex items-center rounded-md">
      {parameter.type === "list" && (
        <div className="bg-muted ring-content/20 relative ml-px flex h-9 items-center rounded-md text-xs ring-1">
          {onSettingsClick && (
            <div className="text-content/40 flex items-center">
              <button onClick={() => onSettingsClick(parameter)}>
                <SettingsIcon className="size-5 pl-1" />
              </button>
            </div>
          )}
          <Text size="xs" color="content" className="px-1">
            {parameter.name}
          </Text>
          <select
            className="min-w-10 bg-background ml-[1px] flex h-9 w-full rounded-md rounded-l-none border-none text-xs ring-0"
            value={value}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          >
            {parameter.restrictedValues?.split(",").map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </select>
        </div>
      )}
      {parameter.type === "datetime" && (
        <Input
          startAddon={
            <Flex gapX={2}>
              <SettingsIcon className="size-4" onClick={() => onSettingsClick?.(parameter)} />
              <Text color="content">{parameter.name}</Text>
            </Flex>
          }
          type="datetime-local"
          disabled={disabled}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          name="value"
          className="min-w-10 ml-[1px] flex w-full rounded-l-none"
          value={value}
        />
      )}
      {(parameter.type === "text" || parameter.type === "number" || parameter.type === "date") && (
        <Input
          startAddon={
            <Flex gapX={2}>
              <SettingsIcon className="size-4" onClick={() => onSettingsClick?.(parameter)} />
              <Text color="content">{parameter.name}</Text>
            </Flex>
          }
          type={parameter.type}
          className="min-w-10 ml-[1px] flex w-full rounded-l-none"
          disabled={disabled}
          onChange={(e) => handleChange(e.target.value)}
          value={value}
        />
      )}
    </div>
  );
};

interface ParamInputProps {
  parameter: query.QueryParameter;
  onChange: (param: query.QueryParameter) => void;
  disabled?: boolean;
  onSettingsClick?: (param: query.QueryParameter) => void;
}
export const ParamInput = ({ onChange, parameter, disabled, onSettingsClick }: ParamInputProps) => {
  return (
    <TypedParamInput parameter={parameter} onChange={onChange} onSettingsClick={onSettingsClick} disabled={disabled} />
  );
};
