import type { visualization } from "@fscrypto/domain";
import type { WorkItem, WorkItemType } from "@fscrypto/domain/work-item";
import { ConditionalLink } from "./conditional-link";
import { ExpandHandler } from "./expand-handler";
import { FileTreeIcon } from "./file-tree-icon";
import { InlineTextEditor } from "./inline-text-editor";
import { useCallback } from "react";

interface ContentProps {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onUpdateName: (name: string) => void;
  onCancelUpdate: () => void;
  isSelected: boolean;
  isEditing: boolean;
  name: string;
  typename: WorkItemType;
  isLeaf: boolean;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  id: string;
  visType: WorkItem["visType"];
  parentId?: string | null;
  fetchChildren: () => void;
}

export const DefaultContent = ({
  isEditing,
  onClick,
  onUpdateName,
  onCancelUpdate,
  name,
  typename,
  isOpen,
  setOpen,
  isLeaf,
  id,
  visType,
  parentId,
  fetchChildren,
}: ContentProps) => {
  // we want the chevron to open all types and fetch children, we only want the entire element to fetch children if it is a collection
  const setOpenHandler = useCallback(
    (isChevronClick: boolean) => {
      if (isChevronClick) {
        setOpen(!isOpen);
        if (!isOpen) {
          fetchChildren();
        }
        return;
      } else if (typename === "collection") {
        if (!isOpen) fetchChildren();
        setOpen(!isOpen);
      } else {
        return;
      }
    },
    [typename, isOpen, setOpen, fetchChildren],
  );
  const visTypeConverted = convertVisType(visType);
  return (
    <div
      className="flex-1 overflow-hidden"
      onClick={() => setOpenHandler(false)}
      data-testid={`mywork-${typename}-${name.replaceAll(" ", "-").toLowerCase()}`}
    >
      <ConditionalLink isEditing={isEditing} typename={typename} id={id} name={name} parentId={parentId}>
        <div className="flex w-full items-center justify-between" onClick={onClick}>
          <ExpandHandler typename={typename} isLeaf={isLeaf} isOpen={isOpen} setOpen={() => setOpenHandler(true)} />
          <FileTreeIcon type={typename} active={isOpen} visType={visTypeConverted ?? undefined} />
          <InlineTextEditor name={name} onUpdate={onUpdateName} onCancel={onCancelUpdate} isEditing={isEditing} />
        </div>
      </ConditionalLink>
    </div>
  );
};

// all possible work item v2/v3 types
// "donut" | "big_number" | "area" | "line" | "bar" | "scatter" | "bar_line" | "line2" | "bar2" | "multi" | "bar-line" | "bar-normalized" | "bar-horizontal" | "bar-horizontal-normalized" | "area-normalized" | "pie"

// v2 types that determine the icon
// "area" | "bar" | "line" | "scatter" | "big_number" | "donut" | "bar_line"

export function convertVisType(visType: WorkItem["visType"]): visualization.VisualizationType | "table" | "histogram" {
  if (!visType) return "bar";
  if (["donut", "pie"].includes(visType)) return "donut";
  if (visType.includes("line")) return "line";
  if (visType.includes("bar")) return "bar";
  if (visType.includes("area")) return "area";
  if (visType.includes("big")) return "big_number";
  if (visType.includes("scatter")) return "scatter";
  if (visType.includes("table")) return "table";
  if (visType.includes("histogram")) return "histogram";
  return "bar";
}
