import { Button, TwitterIcon } from "@fscrypto/ui";
import type { Profile } from "@fscrypto/domain/profile";
import { useDashboard } from "~/state/machines/dashboard";
import { useToaster } from "~/features/toasts/toast.machine";

export const TwitterShare = ({ owner, dashboardId }: { owner: Profile; dashboardId: string }) => {
  const { getUrl } = useCopyDashboardToClipboard(owner, dashboardId);
  return (
    <a
      href={` https://twitter.com/share?url=${getUrl()}`}
      target="_blank"
      rel="noreferrer"
      className="w-full flex-1"
      data-testid="dashboard-twitter-share"
    >
      <Button variant="secondary" size="sm" className="mx-1 flex-1" asChild>
        <div className="text-gray-70">
          <TwitterIcon className="mr-1 h-4 w-4 fill-[#1DA1F2] text-[#1DA1F2]" />
          Share
        </div>
      </Button>
    </a>
  );
};

export const useCopyDashboardToClipboard = (owner: Profile, dashboardId: string) => {
  const { dashboard, isReady } = useDashboard(dashboardId);
  const { notify } = useToaster();
  if (!isReady)
    return {
      copyUrl: () => null,
      dashboardAddress: "",
      getUrl: () => "",
    };

  const dashboardAddress = `/${owner.slug}/${dashboard.latestSlug}`;

  const copyUrl = async () => {
    const baseUrl = window.ENV.BASE_URL;
    const url = baseUrl + dashboardAddress;
    await navigator.clipboard.writeText(url);

    notify?.({ type: "success", title: "Copied to clipboard", description: "Paste the link to share your dashboard" });
  };
  const getUrl = () => {
    const baseUrl = window.ENV.BASE_URL;
    const url = baseUrl + dashboardAddress;
    return url;
  };
  return { copyUrl, dashboardAddress, getUrl, title: dashboard.title, description: dashboard.description };
};
