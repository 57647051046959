import type { dashboard } from "@fscrypto/domain";
import ErrorBoundary from "~/features/errors/ErrorBoundary";
import { PanelError } from "./panel-error";
import Switch from "react-switch-case";
import * as reactSwitchCase from "react-switch-case";
const { Default } = reactSwitchCase;
interface PanelSwitchProps {
  cell: dashboard.Cell;
  children: React.ReactNode;
}

export const DashboardGridPanelSwitch = ({ cell, children }: PanelSwitchProps) => {
  return (
    <>
      <ErrorBoundary renderError={(error: Error | null) => <PanelError error={error} />}>
        <Switch condition={cell.component.type}>
          {children}
          <Default>
            <span>No Panel Type</span>
          </Default>
        </Switch>
      </ErrorBoundary>
    </>
  );
};
