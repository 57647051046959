import { Button } from "@fscrypto/ui";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import MarketingAuthModal from "~/features/app-shell/marketing/marketing-auth-modal";
import { useCurrentUser } from "~/features/current-user";
import { ParamInput } from "~/features/query/ui/query-params/param-input";
import { useDashboardParameters } from "~/state/machines/dashboard/dashboard-parameters/dashboard-parameters.machine";
import { tracking } from "~/utils/tracking";
import { SelectInput } from "./select-input";

export const DashboardParameters = ({ dashboardId }: { dashboardId: string }) => {
  const { currentUser } = useCurrentUser();
  const { parameters, updateParameter, applyParameters, latestSlug = "" } = useDashboardParameters(dashboardId);

  const [openAuthModal, setOpenAuthModal] = useState(false);
  const onOpenAuthModalChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpenAuthModal(false);
    }
  };

  return (
    <AnimatePresence>
      {parameters.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          layout
          className="flex flex-wrap items-center px-3"
        >
          {parameters.map((param) =>
            param.restrictedValues ? (
              <div data-testid="dashboard-parameter-input" className="m-2" key={param.id}>
                <SelectInput
                  name={param.name}
                  onChange={(e) => updateParameter(param.name, e.target.value)}
                  key={param.name}
                  restrictedValues={param.restrictedValues}
                  disabled={!currentUser}
                  value={param.value}
                />
              </div>
            ) : (
              <div data-testid="dashboard-parameter-input" className="m-2" key={param.id}>
                <ParamInput parameter={param} onChange={(p) => updateParameter(param.name, p.value)} key={param.name} />
              </div>
            ),
          )}
          <Button
            variant="primary"
            size="sm"
            className="p-[14px]"
            onClick={() => {
              tracking("apply_parameters_to_dashboard", "Dashboard Parameters", {
                latestSlug,
                parametersApplied: JSON.stringify(parameters),
                dashboardId,
              });
              if (currentUser) {
                applyParameters();
              } else {
                setOpenAuthModal(true);
              }
            }}
            data-testid="dashboard-apply-all-parameters-button"
          >
            Apply all parameters
          </Button>
          <MarketingAuthModal
            open={openAuthModal}
            redirectBackOnClose={false}
            defaultOpen={false}
            onOpenChange={onOpenAuthModalChange}
            persona="viewer"
            origin="dashboard-parameters"
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
