import { Button, Modal } from "@fscrypto/ui";
import { UploadIcon } from "lucide-react";

interface ImageUploaderProps {
  onCancel: () => void;
  onSelectFile: (file: File) => void;
  selectedFile?: File;
  hasSelection: boolean;
  onStartUpload: () => void;
  isUploading: boolean;
}

export const ImageUploader = ({
  onCancel,
  onSelectFile,
  selectedFile,
  onStartUpload,
  isUploading,
}: ImageUploaderProps) => {
  const fileSource = selectedFile ? URL.createObjectURL(selectedFile) : null;

  return (
    <Modal open={true} setOpen={(e) => !e && onCancel()} className="sm:w-md sm:max-w-md">
      <div className="p-6">
        <h2 className="mb-4 font-medium text-gray-600 dark:text-white">Embed Image</h2>
        {fileSource ? (
          <img src={fileSource} alt="db_img" className="h-60 w-full object-contain" />
        ) : (
          <div className="flex h-60 justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5">
            <div className="flex flex-col items-center justify-center space-y-1">
              <UploadIcon className="text-muted-foreground size-10 m-2" />
              <div className="text-gray-70 flex">
                <label
                  htmlFor="file"
                  className="dark:bg-gray-30 relative m-2 cursor-pointer rounded-md bg-white p-2 font-medium text-blue-50"
                >
                  <span className="ml-1 font-semibold text-blue-50">Browse files to upload</span>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const file = e?.target?.files?.[0];
                      if (file) {
                        onSelectFile(file);
                      }
                    }}
                  />
                </label>
              </div>
              <p className="text-sm text-gray-50">Supported: PNG, SVG, GIF, JPEG</p>
            </div>
          </div>
        )}
        {/* <div className="inline-flex justify-center items-center w-full">
            <hr className="my-8 w-full h-px bg-gray-200 border-0 dark:bg-gray-700" />
            <span className="absolute left-1/2 px-3 font-medium text-gray-50 bg-white -translate-x-1/2 dark:text-white dark:bg-gray-900">
              OR
            </span>
          </div>
          <div className="my-1">
            <Input name="image url" placeholder="Image URL" />
          </div> */}
        <div className="flex space-x-2 pt-8">
          <Button
            variant="secondary"
            size="md"
            className="w-full"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="md"
            className="w-full disabled:opacity-20"
            disabled={!fileSource || isUploading}
            onClick={onStartUpload}
          >
            Embed
          </Button>
        </div>
      </div>
    </Modal>
  );
};
