import { useEffect } from "react";
import { type queryRun } from "@fscrypto/domain";
import type { Event as EpicEvent } from "./epics";
import { createEpics } from "./epics";
import type { ActorRefFrom, AnyActorRef } from "xstate";
import { assign, createMachine, spawn } from "xstate";
import { Subject } from "rxjs";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";
import { actorSystem } from "~/state";
import { useActorFromSystem } from "~/state/system";

type RefEvent =
  | { type: "QUERY_RUN.RUN_QUERY" }
  | { type: "QUERY_RUN.SET_QUERY_RUN"; payload: { queryRun: queryRun.QueryRun } }
  | { type: "QUERY_RUN.SET_STATEMENT"; payload: { statement: string } }
  | { type: "QUERY_RUN.FETCH_RESULTS" }
  | { type: "QUERY_RUN.CANCEL_QUERY" };

export type GlobalQueryRunEvent =
  | {
      type: "GLOBAL.QUERY_RUN.EXECUTED";
      payload: { queryId: string; queryRun: Context["queryRun"] };
      queryId: string;
      src?: "realtime";
    }
  | { type: "GLOBAL.QUERY_RUN.POLLING"; payload: { queryId: string } }
  | { type: "GLOBAL.QUERY_RUN.FINISHED"; payload: { queryId: string; queryRunId: string; endedAt?: Date } }
  | { type: "GLOBAL.QUERY_RUN.FAILED"; payload: { queryId: string; queryRunId: string } }
  | { type: "GLOBAL.QUERY_RUN.CANCEL_SUCCESS"; queryId: string; src?: "realtime" }
  | { type: "GLOBAL.QUERY_RUN.CANCEL"; queryId: string; src?: "realtime" };

const createQueryRunMachine = (queryId: string) => {
  const actions$ = new Subject<EpicEvent>();
  return createMachine(
    {
      id: `queryRun-${queryId}`,
      predictableActionArguments: true,
      tsTypes: {} as import("./query-run.machine.typegen").Typegen0,
      schema: {
        context: {} as Context,
        events: {} as RefEvent | EpicEvent | GlobalEvent,
      },
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      context: {
        queryId,
      },
      entry: ["setupEpic"],
      initial: "idle",
      on: {
        "QUERY_RUN.SET_STATEMENT": {
          actions: ["setStatement"],
        },
        "QUERY_RUN.SET_QUERY_RUN": [
          {
            actions: ["setQueryRun"],
            target: "fetchingResults",
            cond: "hasNoResults",
          },
          {
            actions: ["setQueryRun"],
            target: "polling",
            cond: "isRunning",
          },
          {
            actions: ["setQueryRun"],
            target: "idle",
          },
        ],
      },
      states: {
        idle: {
          on: {
            "QUERY_RUN.RUN_QUERY": "executing",
            "GLOBAL.QUERY_RUN.EXECUTED": {
              actions: ["setQueryRun"],
              target: "polling",
              cond: "isQueryId",
            },
          },
        },
        executing: {
          description: "When entering this state we create a query run and then poll for results",
          entry: ["runQueryEpic"],
          on: {
            "QUERY_RUN.EPIC.CREATE_SUCCESS": [
              {
                description: "When the query run is successfully created we start polling for results",
                actions: ["handleCreateSuccess", "globalExecuted"],
                target: "polling",
                cond: "executeSuccess",
              },
              {
                description: "When the query run is successfully created we start polling for results",
                actions: ["handleCreateSuccessFail"],
                target: "error.execute",
              },
            ],
            "QUERY_RUN.EPIC.CREATE_ERROR": {
              actions: ["handleCreateError"],
              target: "error.execute",
            },
          },
        },
        loading: {},
        polling: {
          description: `When entering this state we poll for the query run status. 
            If the query run is complete we fetch the results. 
            If the query run is canceled we go to the canceled state. If the query run is errored we go to the error state.`,
          entry: ["pollQueryRunEpic", "globalPolling"],
          on: {
            "QUERY_RUN.EPIC.POLL_SUCCESS": {
              actions: ["handlePollSuccess"],
              target: "fetchingResults",
            },
            "QUERY_RUN.EPIC.POLL_FAILURE": {
              actions: ["handlePollFailure"],
              target: "error.poll",
            },
            "QUERY_RUN.EPIC.POLL_STATUS": {
              actions: ["handlePollStatus"],
            },
            "QUERY_RUN.CANCEL_QUERY": "canceling",
            "GLOBAL.QUERY_RUN.CANCEL": {
              target: "canceling",
              cond: "isQueryId",
            },
          },
        },
        fetchingResults: {
          description: `When entering this state we fetch the results of the query run.`,
          entry: ["fetchResultsEpic"],
          on: {
            "QUERY_RUN.EPIC.FETCH_RESULTS_SUCCESS": [
              {
                actions: ["handleFetchResultsSuccess"],
                target: "complete",
                cond: "isSuccess",
              },
              {
                actions: ["handleFetchError"],
                target: "error.fetch",
              },
            ],
          },
        },
        canceling: {
          entry: ["cancelQueryEpic", "globalCancelQuery"],
          on: {
            "QUERY_RUN.EPIC.CANCEL_SUCCESS": {
              actions: ["handleCancelSuccess", "globalCancelQuerySuccess"],
              target: "canceled",
            },
            "GLOBAL.QUERY_RUN.CANCEL_SUCCESS": {
              target: "canceled",
              cond: "isQueryId",
            },
          },
        },
        complete: {
          entry: ["globalFinished"],
          on: {
            "QUERY_RUN.RUN_QUERY": "executing",
            "GLOBAL.QUERY_RUN.EXECUTED": {
              actions: ["setQueryRun"],
              target: "polling",
              cond: "isQueryId",
            },
          },
        },
        canceled: {
          on: {
            "QUERY_RUN.RUN_QUERY": "executing",
            "GLOBAL.QUERY_RUN.EXECUTED": {
              actions: ["setQueryRun"],
              target: "polling",
              cond: "isQueryId",
            },
          },
        },
        error: {
          entry: ["globalFailed"],
          on: {
            "QUERY_RUN.RUN_QUERY": "executing",
            "GLOBAL.QUERY_RUN.EXECUTED": {
              actions: ["setQueryRun"],
              target: "polling",
              cond: "isQueryId",
            },
          },
          states: {
            execute: {},
            poll: {},
            fetch: {},
            cancel: {},
          },
        },
      },
    },
    {
      actions: {
        // this stores the query run in context
        setQueryRun: assign((_, e) => {
          return {
            queryRun: e.payload.queryRun,
          };
        }),
        // this create all the query run epics and stores them in context
        setupEpic: assign((ctx, _e) => {
          if (ctx.epic$) return {};
          const epic$ = createEpics(actions$);
          return {
            epic$: spawn(epic$),
          };
        }),
        setStatement: assign((_ctx, e) => {
          return {
            statement: e.payload.statement,
          };
        }),
        runQueryEpic: (ctx) => {
          actions$.next({ type: "QUERY_RUN.EPIC.CREATE", payload: { queryId: ctx.queryId } });
        },
        fetchResultsEpic: (ctx) => {
          if (!ctx.queryRun) return;
          actions$.next({ type: "QUERY_RUN.EPIC.FETCH_RESULTS", payload: { queryId: ctx.queryId } });
        },
        pollQueryRunEpic: (ctx) => {
          if (!ctx.queryRun) return;
          actions$.next({ type: "QUERY_RUN.EPIC.POLL", payload: { queryRunId: ctx.queryRun.id } });
        },
        cancelQueryEpic: (ctx) => {
          if (!ctx.queryRun) return;
          actions$.next({ type: "QUERY_RUN.EPIC.CANCEL", payload: { queryRunId: ctx.queryRun.id } });
        },
        handleCreateSuccess: assign({ queryRun: (_, e) => e.payload }),
        handleCreateSuccessFail: assign({ queryRun: (_, e) => e.payload }),
        handleCreateError: assign({ errorMessage: (_ctx, e) => e.error }),
        handleFetchError: assign({ errorMessage: (_ctx, e) => e.payload.errorMessage }),
        handlePollSuccess: assign({ queryRun: (_ctx, e) => e.payload }),
        handlePollFailure: assign({ queryRun: (_ctx, e) => e.payload }),
        handlePollStatus: assign({ queryRun: (context, e) => e.payload }),
        handleFetchResultsSuccess: assign({ queryRunResult: (_ctx, e) => e.payload }),
        handleCancelSuccess: assign({ queryRun: (_ctx, e) => e.payload }),
        globalExecuted: (ctx) =>
          eventBus.send({
            type: "GLOBAL.QUERY_RUN.EXECUTED",
            payload: { queryId: ctx.queryId, queryRun: ctx.queryRun },
            queryId,
          }),
        globalPolling: (ctx) => eventBus.send({ type: "GLOBAL.QUERY_RUN.POLLING", payload: { queryId: ctx.queryId } }),
        globalCancelQuery: (ctx) => eventBus.send({ type: "GLOBAL.QUERY_RUN.CANCEL", queryId: ctx.queryId }),
        globalFinished: (ctx) =>
          eventBus.send({
            type: "GLOBAL.QUERY_RUN.FINISHED",
            payload: {
              queryId: ctx.queryId,
              queryRunId: ctx.queryRun?.id!,
              endedAt: ctx.queryRunResult?.endedAt ? new Date(ctx.queryRunResult?.endedAt) : new Date(),
            },
          }),
        globalFailed: (ctx) =>
          eventBus.send({
            type: "GLOBAL.QUERY_RUN.FAILED",
            payload: { queryId: ctx.queryId, queryRunId: ctx.queryRun?.id! },
          }),
        globalCancelQuerySuccess: (ctx) =>
          eventBus.send({ type: "GLOBAL.QUERY_RUN.CANCEL_SUCCESS", queryId: ctx.queryId }),
      },
      guards: {
        hasNoResults: (ctx, evt) =>
          !ctx.queryRunResult &&
          evt.payload.queryRun.status !== "running" &&
          evt.payload.queryRun.status !== "queued" &&
          evt.payload.queryRun.status !== "failed",
        isRunning: (_ctx, evt) =>
          evt.payload.queryRun?.status === "running" || evt.payload.queryRun?.status === "queued",
        isQueryId: (ctx, evt) => ctx.queryId === evt.queryId,
        isSuccess: (ctx, evt) => {
          return evt.payload.status === "finished";
        },
        executeSuccess: (ctx, evt) => {
          return evt.payload.status !== "failed";
        },
      },
      services: {
        globalEvents: () => eventBus.events$,
      },
    },
  );
};

interface Context {
  queryId: string;
  queryRun?: queryRun.QueryRun;
  queryRunResult?: queryRun.QueryRunResult;
  errorMessage?: string;
  statement?: string;
  epic$?: AnyActorRef;
}
export type Ref = ActorRefFrom<typeof createQueryRunMachine>;

export const createQueryRun = (queryId: string, queryRun?: queryRun.QueryRun, statement?: string) => {
  let ref = actorSystem.get<Ref>(`queryRun-${queryId}`);
  if (!ref) {
    const machine = createQueryRunMachine(queryId);
    ref = actorSystem.registerMachine(machine, `queryRun-${queryId}`);
  }
  if (queryRun) ref.send({ type: "QUERY_RUN.SET_QUERY_RUN", payload: { queryRun } });
  if (statement) ref.send({ type: "QUERY_RUN.SET_STATEMENT", payload: { statement } });
  return ref;
};

export const useQueryRunByQueryId = (queryId: string) => {
  const [state, ref] = useActorFromSystem<Ref>(`queryRun-${queryId}`);
  useEffect(() => {
    if (ref) return;
    createQueryRun(queryId);
  }, [queryId, ref]);

  if (!ref || !state) {
    return {
      isReady: false,
    } as const;
  }
  return {
    isReady: true,
    queryRun: state.context.queryRun,
    results: state.context.queryRunResult,
    errorMessage: state.context.errorMessage,
    runQuery: () => ref.send({ type: "QUERY_RUN.RUN_QUERY" }),
    cancelQuery: () => ref.send({ type: "QUERY_RUN.CANCEL_QUERY" }),
    isIdle: state.matches("idle"),
    isQueueing: state.matches("executing"),
    isRunning: state.matches("polling"),
    isDownloading: state.matches("fetchingResults"),
    isCanceling: state.matches("canceling"),
    isCanceled: state.matches("canceled"),
    isComplete: state.matches("complete"),
    isError: state.matches("error"),
  };
};
