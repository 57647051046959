///////Members//////////

export type RoomMember = {
  // Unique identifier for each user in the room.
  userId: string;

  // Represents the current position of the user's cursor in the room, allowing for tracking of user movement or interaction points.
  // - `x`: The horizontal position of the cursor in the room.
  // - `y`: The vertical position of the cursor in the room.
  cursor: {
    x: number;
    y: number;
  };

  // Optional property to represent the user's chosen color in the room. This can be used for customizing the cursor or other UI elements to differentiate users visually.
  // If not specified, a default color may be used.
  color?: string;

  // Optional username for the user. This can be displayed in the UI to identify the user by a human-readable name rather than by their `userId`.
  username?: string;
  // Optional avatarUrl for the user. This can be displayed in the UI to identify the user.
  avatarUrl?: string;
};

export const createInitialMemberData = (userId: string, username?: string, avatarUrl?: string) => ({
  userId,
  cursor: {
    x: 0,
    y: 0,
  } satisfies RoomMember["cursor"],
  color: getColorFromUUID(userId),
  username,
  avatarUrl,
});

const colors = [
  "#FF65A2", // Pink
  "#FF563F", // Orange
  "#31B967", // Green
  "#00FFFF", // Cyan
  "#5C77FF", // Blue
  "#4845D4", // Indigo
  "#8E0FC9", // Purple
];

export const getColorFromUUID = (uuid: string) => {
  let hash = 0;
  for (let i = 0; i < uuid.length; i++) {
    hash = ((hash << 5) - hash + uuid.charCodeAt(i)) | 0; // Convert to 32bit integer
  }
  const index = Math.abs(hash) % colors.length;
  return colors[index];
};
