import { HttpClient, Client } from "@fscrypto/http";
import { RealtimeVisualization } from "@fscrypto/domain/liveblocks";
import {
  EnterRoomArgs,
  RealtimeClient,
  RealtimeClientImp,
  RealtimeRoom,
  RealtimeUserData,
  RoomEvents,
} from "@fscrypto/realtime";
import { liveblocks, visualization } from "@fscrypto/domain";
import { eventBus } from "~/state/events";
import { Visualization, VisualizationNew } from "@fscrypto/domain/visualization";
import { VisualizationRoomCustomEvent } from "../state/visualization-room/machine";
import { $path } from "remix-routes";

export class ClientVisualization {
  constructor(
    private readonly client: HttpClient,
    private readonly realtimeClient: RealtimeVisualizationClient,
  ) {
    this.client = client;
    this.realtimeClient = realtimeClient;
  }

  enterRoom({
    userData,
    visualization,
  }: {
    userData: RealtimeUserData;
    visualization: Visualization;
  }): VisualizationRoom {
    const visData = liveblocks.visualizationStorageSchema.parse(visualization);
    const room = this.realtimeClient.enterRoom({
      roomId: `fscrypto:visualization-${visualization.id}`,
      initialData: { visualization: visData },
      initialYjsData: {},
      userData,
    });
    // send events to the event bus
    room.customEvent$.subscribe((event) => {
      eventBus.send(event);
    });
    return room;
  }
  async updateRealtimeVisualization({ room, visualization }: UpdateVisualizationArgs): Promise<void> {
    const visData = liveblocks.visualizationStorageSchema.parse(visualization);
    room?.storage.visualization.update(visData);
  }

  async persistVisualization(visualizationId: string): Promise<void> {
    this.client.post<{ Visualization: Visualization }>(`/api/realtime/visualizations/${visualizationId}/update`, {});
  }

  async createVisualization(newVis: VisualizationNew): Promise<Visualization> {
    try {
      const data = await this.client.post<Visualization, VisualizationNew>(
        $path("/api/queries/:id/visualizations/create", { id: newVis.queryId }),
        newVis,
      );
      return data;
    } catch (e) {
      throw new Error("Error Updating Visualization");
    }
  }

  async updateVisualizationV3(visualization: visualization.v3.Visualization): Promise<visualization.v3.Visualization> {
    try {
      return this.client.post<visualization.v3.Visualization, visualization.v3.Visualization>(
        $path("/api/visualizations/:id/update", { id: visualization.id }),
        visualization,
      );
    } catch (e) {
      throw new Error("Error Updating Visualization");
    }
  }

  async createVisualizationV3(newVis: visualization.v3.VisualizationNew): Promise<visualization.v3.Visualization> {
    try {
      return this.client.post<visualization.v3.Visualization, visualization.v3.VisualizationNew>(
        $path("/api/queries/:id/visualizations/v3/create", { id: newVis.queryId }),
        newVis,
      );
    } catch (e) {
      throw new Error("Error Creating Visualization");
    }
  }

  async deleteVisualization(visualizationId: string): Promise<Visualization> {
    try {
      return this.client.post($path("/api/visualizations/:id/delete", { id: visualizationId }), {});
    } catch (e) {
      throw new Error("Error Deleting Visualization");
    }
  }

  async fetchVisualization(visualizationId: string): Promise<Visualization> {
    try {
      return this.client.get(`/api/visualizations/${visualizationId}`);
    } catch (e) {
      throw new Error("Error Getting Visualization");
    }
  }
}

type UpdateVisualizationArgs = {
  room?: VisualizationRoom;
  visualization: Visualization;
};
export const visClient = new ClientVisualization(new Client(), new RealtimeClientImp("/api/liveblocks-auth"));

export type VisualizationRoomStorage = {
  visualization: RealtimeVisualization;
};

export type VisualizationRoomEvent = RoomEvents<VisualizationRoomStorage>;
export type VisualizationRoom = RealtimeRoom<VisualizationRoomCustomEvent, VisualizationRoomStorage, {}>;
export type RealtimeVisualizationClient = RealtimeClient<VisualizationRoomStorage, VisualizationRoomCustomEvent>;
export type VisualizationEnterRoom = EnterRoomArgs<VisualizationRoomStorage, {}>;
