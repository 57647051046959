import type { Dashboard } from "@fscrypto/domain/dashboard";
import type { Room, User as LiveblocksUser, StorageStatus, Status } from "@liveblocks/client";
import { LiveObject } from "@liveblocks/client";
import { liveblocksClient } from "~/state/liveblocks.client";
import { liveblocks as liveblocksDomain } from "@fscrypto/domain";
import { RealtimeDashboard } from "@fscrypto/domain/liveblocks";
import { getColorFromUUID } from "~/shared/utils/realtime-user-color";
import deepEquals from "fast-deep-equal";
import { eventBus } from "~/state/events";
import { uniqBy } from "lodash-es";

export type DashboardRoomStorage = {
  dashboardData: LiveObject<RealtimeDashboard>;
};

export type DashboardRoom = Room<DashboardPresence, DashboardRoomStorage, RoomUserInfo, {}>;
export type RoomUserInfo = { id?: string; info: { username: string; avatarUrl: string } };
export type DashboardPresence = {
  cursor: {
    x: number;
    y: number;
  };
  color: string | undefined;
};

export const getInfoFromOthers = (others: readonly LiveblocksUser<DashboardPresence, RoomUserInfo>[]) => {
  const uniqueUsers = uniqBy(others, "id");
  const users = uniqueUsers.map((user) => {
    return {
      id: user.id,
      username: user.info.username,
      avatarUrl: user.info.avatarUrl,
      presence: user.presence,
    };
  });

  return users;
};

const initialOptions = (dashboard: Dashboard, userId?: string) => ({
  initialPresence: {
    cursor: {
      x: 0,
      y: 0,
    } as DashboardPresence["cursor"],
    color: getColorFromUUID(userId ?? ""),
  },
  initialStorage: {
    dashboardData: new LiveObject(liveblocksDomain.dashboardStorageSchema.parse(dashboard)),
  },
});

export const createDashboardRoom = (roomId: string, dashboard: Dashboard, userId?: string) => {
  const { room, leave } = liveblocksClient.enterRoom<DashboardPresence, DashboardRoomStorage, RoomUserInfo>(
    roomId,
    initialOptions(dashboard, userId),
  );
  return { room, leave };
};

/**
 * Determines if there is a mismatch between between initialRealtime and initialData.
 *
 * @param {Dashboard | undefined | null} dashboard - The dashboard data to be checked. It might be undefined or null.
 * @param {RealtimeDashboard | undefined} rtDashboard - The realtime dashboard data to be checked. It might be undefined.
 *
 */
export const determineInitialParity = (dashboard?: Dashboard | null, rtDashboard?: RealtimeDashboard) => {
  if (!dashboard || !rtDashboard) return;
  const dashboardParsed = liveblocksDomain.dashboardStorageSchema.safeParse(dashboard);
  const rtDashboardParsed = liveblocksDomain.dashboardStorageSchema.safeParse(rtDashboard);
  if (rtDashboard && dashboard && dashboardParsed.success && rtDashboardParsed.success) {
    if (deepEquals(dashboardParsed.data, rtDashboardParsed.data)) return;
    eventBus.send({
      type: "GLOBAL.DASHBOARD_REALTIME.UPDATE_DATA",
      payload: { dashboardData: { ...rtDashboard } },
      dashboardId: dashboard.id,
    });
    eventBus.send({
      type: "GLOBAL.DASHBOARD.SET_DATA",
      payload: { dashboard: { ...rtDashboard } },
      dashboardId: dashboard.id,
    });
  }
};

export const determineRoomError = (roomStatus?: Status | null, storageStatus?: StorageStatus | null) => {
  if (roomStatus === "reconnecting" || roomStatus === "disconnected" || storageStatus === "not-loaded") {
    return true;
  }
  return false;
};
