import type { query } from "@fscrypto/domain";
import { LogoIcon, Tooltip } from "@fscrypto/ui";
import React from "react";
import { Link } from "@remix-run/react";
import { $path } from "remix-routes";
import clsx from "clsx";
import { useWindowSize } from "usehooks-ts";
import { formatDateTimeAgo } from "~/utils/helpers";
import { useEmbedded } from "~/hooks/useEmbedded";
import { useTabs } from "~/state/machines/explorer-tabs/explorer-tabs-machine";
import { profile } from "@fscrypto/domain";
import { OwnerPanel } from "../dashboard-panel-visualizations/visualization-panel-container";
import { useCurrentUser } from "~/features/current-user";
import { CheckCircleIcon } from "lucide-react";

interface TablePanelContainerProps {
  children: React.ReactNode;
  title?: string;
  owner?: profile.Profile;
  query: query.Query;
  isEditable: boolean;
}

const TablePanelContainer = ({ children, title, owner, query, isEditable }: TablePanelContainerProps) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const [isHovering, setIsHovering] = React.useState(false);
  const { linkTarget } = useEmbedded();
  const { currentUser } = useCurrentUser();
  const isOwner = currentUser?.currentProfileId === owner?.id;
  const { addTab, isReady } = useTabs();
  if (!isReady) return null;

  const isPublic = query?.visibility === "public";

  return (
    <div
      className="flex h-full w-full flex-col"
      onMouseEnter={() => isPublic && setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="flex h-10 w-full items-center justify-between px-3" data-testid="dashboard-table-panel">
        {query && (isPublic || isOwner) ? (
          <Link
            onClick={() => {
              isOwner && addTab({ name: title!, id: query.id!, type: "query" });
            }}
            className="flex items-center overflow-hidden"
            to={
              isOwner
                ? $path("/studio/queries/:id", { id: query.id })
                : $path("/:owner/q/:slugId/:slug", {
                    owner: owner?.slug!,
                    slugId: query.slugId!,
                    slug: query.latestSlug!,
                  })
            }
            target={linkTarget}
          >
            <div className="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded bg-black dark:bg-white">
              <LogoIcon className="h-4 w-4 fill-white dark:fill-black" />
            </div>
            <Tooltip content={title} side="top">
              <p className={clsx("truncate px-2 text-sm dark:text-white", { underline: isHovering })}>{title}</p>
            </Tooltip>
          </Link>
        ) : (
          <div className="flex items-center overflow-hidden">
            <div className="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded bg-black dark:bg-white">
              <LogoIcon className="h-4 w-4 fill-white dark:fill-black" />
            </div>
            <p
              className={clsx("text select truncate px-2 text-sm dark:text-white", {
                underline: isHovering && !isMobile,
              })}
            >
              {title}
            </p>
          </div>
        )}
        <div className="flex flex-shrink-0 items-center">
          {owner && <OwnerPanel owner={owner} />}
          {/* this provides space if in edit mode for closing the panel */}
          {isEditable && <div className="hidden w-[100px] md:block"></div>}
        </div>
      </div>
      <div className="max-h-full flex-1">{children}</div>
      <div className="items0-center flex h-10 w-full justify-end px-3">
        {!isMobile && (
          <div className="dark:text-gray-60 flex items-center text-xs text-gray-50">
            {query.lastSuccessfulExecutionAt && (
              <p>Updated {formatDateTimeAgo(new Date(query.lastSuccessfulExecutionAt))}</p>
            )}
            <CheckCircleIcon className="text-muted-foreground ml-2" />
          </div>
        )}
        {/* this provides space if in edit mode for closing the panel */}
        {isEditable && <div className="hidden w-10 md:block"></div>}
      </div>
    </div>
  );
};

export default TablePanelContainer;
