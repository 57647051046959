import { dashboard as dashboardDomain } from "@fscrypto/domain";
import type { Dashboard } from "@fscrypto/domain/dashboard";
import { Status, StorageStatus } from "@liveblocks/client";
import { useLocation } from "@remix-run/react";
import { useEffect } from "react";
import invariant from "tiny-invariant";
import type { ActorRefFrom, StateFrom } from "xstate";
import { assign, createMachine } from "xstate";
import { useInitialTabIndex } from "~/routes/__shell/$owner/$dashboardSlug";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";
import type { DashboardGridActorRef } from "~/state/machines/dashboard/dashboard-grid/dashboard-grid.machine";
import type { DashboardParametersActorRef } from "~/state/machines/dashboard/dashboard-parameters/dashboard-parameters.machine";
import type { DashboardRefreshActorRef } from "~/state/machines/dashboard/dashboard-refresh/dashboard-refresh.machine";
import { actorSystem } from "~/state/system";
import { useActorFromSystem } from "~/state/system";
import type { PersistDataActorRef } from "./dashboard-persist/dashboard-persist-data.machine";
import type { DashboardPublishActorRef } from "./dashboard-publish/dashboard-publish.machine";
import { createDashboardRoomOthersState } from "./dashboard-room/dashboard-others.machine";
import { createDashboardRoomState } from "./dashboard-room/dashboard-room.machine";
import { determineRoomError } from "./dashboard-room/util";
import type { DashboardTabsActorRef } from "./dashboard-tabs/dashboard-tabs.machine";
import { spawnInitialMachines } from "./util";

export const createDashboardMachine = (dashboardId: string, initialIndex: number = 0, useRealtime = true) => {
  const machine = createMachine(
    {
      id: "dashboardMachine",
      tsTypes: {} as import("./dashboard.machine.typegen").Typegen0,
      predictableActionArguments: true,
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      schema: {
        context: {} as DashboardContext,
        events: {} as DashboardEvent | GlobalEvent,
      },
      context: {
        dashboardId,
        isRoomReady: false,
        dashboard: null,
        persistDashboardData: null,
        dashboardGrid: null,
        dashboardTabs: null,
        dashboardPublish: null,
        dashboardParameters: null,
        dashboardRefresh: null,
        roomStatus: null,
        storageStatus: null,
      },
      initial: "initializingDashboardMachines",
      states: {
        initializingDashboardMachines: {
          description: "this state is used to spawn all the sub-machines for the dashboard",
          on: {
            "DASHBOARD.SET_INITIAL_DATA": {
              target: "waitingForRoom",
              actions: "initializeDashboardMachines",
            },
          },
        },
        waitingForRoom: {
          always: [
            {
              target: "ready",
              cond: "isRoomReady",
            },
          ],
          on: {
            "GLOBAL.DASHBOARD_REALTIME.ROOM_READY": {
              target: "ready",
              actions: ["setRoomReady"],
              cond: "isDashboardId",
            },
          },
        },
        ready: {
          description: "this state is entered when all of the dashboard sub-machines are created",
          on: {
            "GLOBAL.DASHBOARD.LEAVE_DASHBOARD": {
              target: "exited",
              cond: "isDashboardIdAndRealtime",
            },
          },
        },
        exited: {
          on: {
            "GLOBAL.DASHBOARD_REALTIME.LEAVE_ROOM": {
              cond: "isDashboardId",
            },
            "GLOBAL.DASHBOARD.ENTER_DASHBOARD": {
              actions: ["setRoomNotReady", "createRoomInstance"],
              target: "waitingForRoom",
              cond: "isDashboardId",
            },
          },
        },
      },
      on: {
        "GLOBAL.DASHBOARD.SET_TITLE": {
          actions: ["setTitle", "persistDashboardData"],
          cond: "isDashboardId",
        },
        "GLOBAL.DASHBOARD.SET_DATA": {
          actions: ["setDashboardData", "persistDashboardData"],
          cond: "isDashboardId",
        },
        "GLOBAL.DASHBOARD.UPDATE_SUCCESS": {
          actions: ["setUpdatedAt"],
          cond: "isDashboardId",
        },

        "GLOBAL.WORK_ITEM.UPDATE_REQUEST": {
          actions: ["externalSetTitle"],
          cond: "isWorkItemId",
        },
        "WORK_ITEM.MOVE_REQUEST": {
          actions: ["externalSetCollectionId"],
          cond: "isWorkItemId",
        },
        "GLOBAL.DASHBOARD.REFRESH.REFRESH_FINISHED": {
          actions: ["setRefreshedAt"],
          cond: "isDashboardId",
        },
        "GLOBAL.DASHBOARD_REALTIME.UPDATE_DATA": {
          actions: ["realtimeUpdate"],
          cond: "isDashboardId",
        },
        "GLOBAL.DASHBOARD_PUBLISH.PUBLISH_SUCCESS": {
          actions: ["setPublishedData"],
          cond: "isDashboardId",
        },
        "GLOBAL.DASHBOARD_PUBLISH.UNPUBLISH_SUCCESS": {
          actions: ["setUnpublishedDate"],
          cond: "isDashboardId",
        },
        "GLOBAL.SHARE_SETTINGS.UPDATE_VISIBILITY": {
          actions: ["updateVisibility", "persistDashboardData"],
          cond: "isWorkItemId",
        },
        "GLOBAL.DASHBOARD_REALTIME.STATUS_UPDATE": {
          actions: ["setRoomStatus"],
          cond: "isDashboardId",
        },
        "GLOBAL.WORK_ITEM.RENAME": {
          actions: ["externalSetTitle"],
          cond: "isWorkItemId",
        },
      },
    },
    {
      actions: {
        // -----> This creates all the various sub-machines for the dashboard
        initializeDashboardMachines: assign((_, event) =>
          spawnInitialMachines(event.payload, initialIndex, useRealtime),
        ),
        // ----->
        setRoomReady: assign((context, e) => {
          return {
            isRoomReady: true,
          };
        }),
        setRoomNotReady: assign((context, e) => {
          return {
            isRoomReady: false,
          };
        }),
        externalSetTitle: assign((context, event) => {
          if (!context.dashboard) return {};
          return {
            dashboard: { ...context.dashboard, title: event.payload.name },
          };
        }),
        externalSetCollectionId: assign((context, event) => {
          if (!context.dashboard) return {};
          return {
            dashboard: { ...context.dashboard, collectionId: event.payload.parentId },
          };
        }),
        setTitle: assign((context, event) => {
          if (!context.dashboard) return {};
          return {
            dashboard: { ...context.dashboard, title: event.payload },
          };
        }),
        setDashboardData: assign((context, event) => {
          if (!context.dashboard) return {};
          return {
            dashboard: { ...context.dashboard, ...event.payload.dashboard },
          };
        }),
        setUpdatedAt: assign((context, event) => {
          if (!context.dashboard) return {};
          return {
            dashboard: { ...context.dashboard, updatedAt: event.payload.dashboard.updatedAt },
          };
        }),
        setRefreshedAt: assign((context, event) => {
          if (!context.dashboard) return {};
          return {
            dashboard: {
              ...context.dashboard,
              lastRefreshedAt: event.payload.dashboard.lastRefreshedAt,
              updatedAt: new Date(event.payload.dashboard.updatedAt),
            },
          };
        }),
        setPublishedData: assign((context, event) => {
          if (!context.dashboard) return {};
          return {
            dashboard: {
              ...context.dashboard,
              publishedAt: event.payload.publishedAt,
              updatedAt: event.payload.updatedAt,
              coverImage: event.payload.coverImage,
            },
          };
        }),
        setUnpublishedDate: assign((context, event) => {
          if (!context.dashboard) return {};
          return {
            dashboard: {
              ...context.dashboard,
              publishedAt: null,
              updatedAt: event.payload.updatedAt,
            },
          };
        }),
        updateVisibility: assign((context, event) => {
          if (!context.dashboard) return {};
          return {
            dashboard: {
              ...context.dashboard,
              visibility: event.payload.visibility,
            },
          };
        }),
        realtimeUpdate: assign((context, event) => {
          if (!context.dashboard) return {};
          return {
            dashboard: {
              ...context.dashboard,
              description: event.payload.dashboardData.description,
              draft: event.payload.dashboardData.draft,
              title: event.payload.dashboardData.title,
            },
          };
        }),
        persistDashboardData: (context) => {
          if (!context.dashboard) return {};
          dashboardDomain.updateSchema.parse(context.dashboard);
          eventBus.send({
            type: "GLOBAL.DASHBOARD.PERSIST_UPDATES",
            payload: { dashboard: context.dashboard },
            dashboardId: context.dashboard?.id,
          });
        },
        createRoomInstance: (ctx) => {
          invariant(ctx.dashboard, "dashboard should be initialized");
          createDashboardRoomState(ctx.dashboard.id, ctx.dashboard);
          createDashboardRoomOthersState(ctx.dashboard.id);
        },
        setRoomStatus: assign((ctx, e) => {
          if (e.payload.statusType === "room") {
            return {
              roomStatus: e.payload.status as Status,
            };
          } else if (e.payload.statusType === "storage") {
            return {
              storageStatus: e.payload.status as StorageStatus,
            };
          }
          return {};
        }),
      },
      services: {
        globalEvents: () => eventBus.events$,
      },
      guards: {
        isDashboardId: (context, event) => {
          return event.dashboardId === context.dashboard?.id;
        },
        isDashboardIdAndRealtime: (context, event) => {
          return event.dashboardId === context.dashboard?.id;
        },
        isWorkItemId: (context, event) => {
          if (event.type === "GLOBAL.SHARE_SETTINGS.UPDATE_VISIBILITY") {
            return context.dashboard?.id === event.workItemId;
          }
          if (event.type === "GLOBAL.WORK_ITEM.RENAME") {
            return context.dashboard?.id === event.payload.id;
          }
          return context.dashboard?.id === event.id || context.dashboard?.id === event.payload.id;
        },
        isRoomReady: (context) => {
          return context.isRoomReady;
        },
      },
    },
  );
  return machine;
};

interface DashboardContext {
  dashboardId: string;
  dashboard: Dashboard | null;
  persistDashboardData: PersistDataActorRef | null;
  dashboardGrid: DashboardGridActorRef | null;
  dashboardTabs: DashboardTabsActorRef | null;
  dashboardPublish: DashboardPublishActorRef | null;
  dashboardParameters: DashboardParametersActorRef | null;
  dashboardRefresh: DashboardRefreshActorRef | null;
  isRoomReady: boolean;
  roomStatus: Status | null;
  storageStatus: StorageStatus | null;
}

export type DashboardEvent = {
  type: "DASHBOARD.SET_INITIAL_DATA";
  payload: Dashboard;
};

export type GlobalDashboardEvent =
  | {
      type: "GLOBAL.DASHBOARD.PERSIST_UPDATES";
      payload: {
        dashboard: Dashboard;
      };
      dashboardId: string;
    }
  | {
      type: "GLOBAL.DASHBOARD.UPDATE_SUCCESS";
      payload: {
        dashboard: Dashboard;
      };
      dashboardId: string;
    }
  | {
      type: "GLOBAL.DASHBOARD.SET_DATA";
      payload: {
        dashboard: Partial<Dashboard>;
      };
      dashboardId: string;
    }
  | {
      type: "GLOBAL.DASHBOARD.SET_TITLE";
      payload: string;
      dashboardId: string;
    }
  | {
      type: "GLOBAL.DASHBOARD.DELETE_REQUEST";
      dashboardId: string;
    }
  | {
      type: "GLOBAL.DASHBOARD.RESET_PANELS";
      payload: {
        dashboardId: string;
      };
    }
  | {
      type: "GLOBAL.DASHBOARD.LEAVE_DASHBOARD";
      dashboardId: string;
    }
  | {
      type: "GLOBAL.DASHBOARD.ENTER_DASHBOARD";
      dashboardId: string;
    };

export type DashboardActorRef = ActorRefFrom<ReturnType<typeof createDashboardMachine>>;
export type DashboardState = StateFrom<ReturnType<typeof createDashboardMachine>>;
export type Ref = ActorRefFrom<typeof createDashboardMachine>;

export const createDashboardState = (
  dashboardId: string,
  dashboard?: Dashboard,
  initialIndex?: number,
  isRealtime?: boolean,
) => {
  let ref = actorSystem.get<Ref>(`dashboard-${dashboardId}`);
  if (!ref) {
    const machine = createDashboardMachine(dashboardId, initialIndex, isRealtime ?? false);
    ref = actorSystem.registerMachine(machine, `dashboard-${dashboardId}`);
  }
  if (dashboard) {
    eventBus.send({ type: "GLOBAL.DASHBOARD.RESET_PANELS", payload: { dashboardId: dashboard.id } });
    ref.send({ type: "DASHBOARD.SET_INITIAL_DATA", payload: dashboard });
  }
  return ref;
};

export const useDashboard = (id: string) => {
  const [state, dashboardRef] = useActorFromSystem<DashboardActorRef>(`dashboard-${id}`);
  const initialIndex = useInitialTabIndex();
  const { pathname } = useLocation();
  useEffect(() => {
    if (dashboardRef) return;
    const isRealtime = pathname.includes("edit") || pathname.includes("studio");
    createDashboardState(id, undefined, initialIndex, isRealtime);
  }, [id, dashboardRef, initialIndex, pathname]);
  const persistDashboardDataRef = dashboardRef?.getSnapshot()?.context.persistDashboardData;

  if (!dashboardRef || !state || !state.context.dashboard)
    return {
      isReady: false,
    } as const;

  const dashboard = state.context.dashboard;

  const updateDashboard = (dashboard: Partial<Dashboard>) =>
    eventBus.send({ type: "GLOBAL.DASHBOARD.SET_DATA", payload: { dashboard }, dashboardId: id });

  const updateTitle = (title: string) => {
    eventBus.send({ type: "GLOBAL.DASHBOARD.SET_TITLE", payload: title, dashboardId: dashboard.id });
  };

  const deleteDashboard = () => eventBus.send({ type: "GLOBAL.DASHBOARD.DELETE_REQUEST", dashboardId: id });

  const isSaving = persistDashboardDataRef?.getSnapshot()?.matches("saving") ?? false;
  return {
    dashboard,
    updateDashboard,
    isSaving,
    deleteDashboard,
    updateTitle,
    isReady: true,
    isRoomReady: !!state?.context.isRoomReady,
    roomStatus: state?.context.roomStatus,
    storageStatus: state?.context.storageStatus,
    isRoomError: determineRoomError(state?.context.roomStatus, state?.context.storageStatus),
  };
};
