import invariant from "tiny-invariant";

import { spawn } from "xstate";
import type { Cell } from "@fscrypto/domain/dashboard";
import type { TextPanelActorRef } from "~/state/machines/dashboard/dashboard-panel-text/dashboard-panel-text.machine";
import { createTextPanelMachine } from "~/state/machines/dashboard/dashboard-panel-text/dashboard-panel-text.machine";
import type { HeadingPanelActorRef } from "~/state/machines/dashboard/dashboard-panel-heading/dashboard-panel-heading.machine";
import { createHeadingPanelMachine } from "~/state/machines/dashboard/dashboard-panel-heading/dashboard-panel-heading.machine";
import type { ImagePanelActorRef } from "~/state/machines/dashboard/dashboard-panel-image/dashboard-panel-image.machine";
import { createImagePanelMachine } from "~/state/machines/dashboard/dashboard-panel-image/dashboard-panel-image.machine";
import type { VisualizationPanelActorRef } from "~/state/machines/dashboard/dashboard-panel-visualization/dashboard-panel-visualization.machine";
import { createVisualizationPanelMachine } from "~/state/machines/dashboard/dashboard-panel-visualization/dashboard-panel-visualization.machine";
import type { TablePanelActorRef } from "../dashboard-panel-table/dashboard-table-panel.machine";
import { createTablePanelMachine } from "../dashboard-panel-table/dashboard-table-panel.machine";
import type { dashboard } from "@fscrypto/domain";
import type { Layout } from "react-grid-layout";
import { EmbedPanelActorRef, createEmbedPanelMachine } from "../dashboard-panel-embed/dashboard-panel-embed.machine";

export const spawnPanelMachine = (cell: Cell, dashboardId: string) => {
  invariant(cell.id, "cell id is required");
  switch (true) {
    case cell.component.type === "QueryVisual":
      if (cell.formula && "visId" in cell.formula) {
        return spawn(
          createVisualizationPanelMachine({
            cellId: cell.id,
            cell,
            visId: cell.formula?.visId,
            dashboardId,
          }),
        );
      }
    case cell.component.type === "QueryTable":
      if (cell.formula && "queryId" in cell.formula) {
        return spawn(
          createTablePanelMachine({
            cellId: cell.id,
            queryId: cell.formula?.queryId,
            dashboardId,
          }),
        );
      }
    case cell.component.type === "Image":
      if (cell.formula && "imageName" in cell.formula) {
        return spawn(createImagePanelMachine({ cellId: cell.id, url: cell.formula.imageName }));
      }
    case cell.component.type === "Text":
      if (cell.formula && "text" in cell.formula) {
        return spawn(createTextPanelMachine({ cellId: cell.id, text: cell.formula.text, dashboardId }));
      }
    case cell.component.type === "Heading":
      if (cell.formula && "text" in cell.formula) {
        const styles = cell.styles ?? {};
        return spawn(createHeadingPanelMachine({ cellId: cell.id, text: cell.formula.text, styles, dashboardId }));
      }
    case cell.component.type === "Embed":
      if (cell.formula && "url" in cell.formula) {
        return spawn(createEmbedPanelMachine({ cellId: cell.id, url: cell.formula.url }));
      }
    default:
      return spawn(createTextPanelMachine({ cellId: cell.id, text: "default cell", dashboardId }));
  }
};

export type CellWithRef = Cell & {
  ref:
    | HeadingPanelActorRef
    | ImagePanelActorRef
    | TablePanelActorRef
    | VisualizationPanelActorRef
    | TextPanelActorRef
    | EmbedPanelActorRef;
};

/** this loops through the components and normalizes the data
 * to a format compatible with GridLayout. For responsiveness,
 * when the width is below the breakpoint, each item is full width.
 */

export const createLayout = (
  cells: dashboard.Cell[],
  containerWidth: number,
  activeTabId?: string,
): dashboard.Component[] => {
  if (!cells.length) {
    return [];
  }

  let layout: dashboard.Component[] = cells
    .map((cell: dashboard.Cell) => ({
      ...cell.component,
      i: cell.id,
    }))
    .filter((cell) => {
      if (activeTabId !== undefined) {
        return cell.t === activeTabId;
      }
      return true;
    });

  //Sort for mobile
  if (containerWidth !== 0 && containerWidth <= 800) {
    layout = sortLayoutItemsByRowCol(layout).map((component, index) => ({ ...component, w: 12, y: index, x: 0 }));
  }

  return layout;
};

/**
 * Sort layout items by row ascending and column ascending.
 */
export const sortLayoutItemsByRowCol = (layout: dashboard.Component[]): dashboard.Component[] => {
  //clone array as sort modifies
  return [...layout].sort(function (a, b) {
    if (a.y > b?.y || (a.y === b.y && a.x > b.x)) {
      return 1;
    } else if (a.y === b.y && a.x === b.x) {
      return 0;
    }
    return -1;
  });
};

interface FilterCellsArgs {
  cells: CellWithRef[];
  activeTabId?: string;
}

export const filterTabCells = ({ cells, activeTabId }: FilterCellsArgs) => {
  const draftCells = (cells ?? []).filter((cell) => {
    if (activeTabId !== undefined) {
      return cell.component.t === activeTabId;
    }
    return true;
  });
  return draftCells;
};

interface DetermineShouldPersistParams {
  newLayout: Layout[];
  oldLayout: Layout[];
  cells: CellWithRef[];
}

export const determineShouldPersist = ({ newLayout, cells, oldLayout }: DetermineShouldPersistParams) => {
  if (newLayout.some((l) => l.i === "dropped_item")) {
    return false;
  }
  //todo: types
  //@ts-expect-error
  if (cells.some((c) => c?.formula?.imageName === "")) {
    return false;
  }
  //todo: types
  //@ts-expect-error
  if (cells.some((c) => c?.formula?.queryId === "")) {
    return false;
  }
  //todo: types
  //@ts-expect-error
  if (cells.some((c) => c?.formula?.visId === "")) {
    return false;
  }

  const areEqual = areArraysEqual(newLayout, oldLayout);
  if (areEqual) {
    return false;
  }

  return true;
};

function areArraysEqual(array1: Layout[], array2: Layout[]) {
  // Check if both arrays are of the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Iterate through the first array
  for (let obj1 of array1) {
    // Find the object in the second array with the same 'i' value
    let obj2 = array2.find((o) => o.i === obj1.i);

    // If no object is found, or if any of the specified properties don't match,
    // the arrays are not equal
    if (!obj2 || obj1.h !== obj2.h || obj1.w !== obj2.w || obj1.x !== obj2.x || obj1.y !== obj2.y) {
      return false;
    }
  }

  // If all checks pass, the arrays are equal
  return true;
}
