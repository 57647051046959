import { tracking } from "~/utils/tracking";

interface DashboardNameProps {
  className?: string;
  onChange?: (value: string) => void;
  value: string;
  id: string;
}

const defaultStyles =
  "p-1 border rounded border-transparent hover:border-gray-30 w-80 dark:bg-gray-90  dark:text-gray-40";

const DashboardTitle = ({ className, value, onChange, id }: DashboardNameProps) => {
  return (
    <input
      placeholder="Add a title..."
      name="dashboardName"
      value={value}
      className={className || defaultStyles}
      onChange={(e) => {
        onChange && onChange(e.target.value);
      }}
      onBlur={() => {
        onChange && tracking("rename_dashboard", "Dashboard Editor", { dashboard_id: id });
      }}
    />
  );
};

export default DashboardTitle;
