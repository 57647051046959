import type { dashboard } from "@fscrypto/domain";
import { ImageUploader } from "./image-uploader";
import type { ImagePanelActorRef } from "~/state/machines/dashboard/dashboard-panel-image/dashboard-panel-image.machine";
import { useImagePanelMachine } from "~/state/machines/dashboard/dashboard-panel-image/dashboard-panel-image.machine";
import type { CellWithRef } from "~/state/machines/dashboard/dashboard-grid/util";

export type CellWithImagePanelActorRef = dashboard.Cell & { ref: ImagePanelActorRef };

export const DashboardImagePanel = ({ cell, isEditable }: { cell: CellWithRef; isEditable?: boolean }) => {
  const { startUpload, cancelUpload, selectFile, hasSelection, selectedFile, isSaving, showUpload, imageUrl } =
    useImagePanelMachine(cell.ref as ImagePanelActorRef);
  if (showUpload && isEditable) {
    return (
      <ImageUploader
        onCancel={cancelUpload}
        onSelectFile={(file) => selectFile(file)}
        hasSelection={hasSelection}
        selectedFile={selectedFile}
        onStartUpload={startUpload}
        isUploading={isSaving}
      />
    );
  }
  if (!imageUrl) return null;
  return (
    <div className="h-full w-full p-2">
      <img src={gumbyOrVelocityImageDeterminator(imageUrl)} alt="db_img" className="h-full w-full object-contain" />
    </div>
  );
};

const gumbyOrVelocityImageDeterminator = (url: string) => {
  if (url.includes("res.cloudinary.com")) {
    return url.replace("upload/", `upload/f_auto,q_auto/`);
  } else {
    return `https://uploads.flipsidecrypto.com/product/images/${url}`;
  }
};
