import type { query, queryRun } from "@fscrypto/domain";
import type { ActorRefFrom, AnyActorRef, StateFrom } from "xstate";
import { createMachine, assign, sendParent, spawn } from "xstate";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";
import type { CustomParameter } from "../dashboard-parameters/dashboard-parameters.machine";
import { useSelector } from "@xstate/react";
import type { Profile } from "@fscrypto/domain/profile";
import type { EpicEvent } from "./epics";
import { createEpics } from "./epics";
import { Subject } from "rxjs";
import invariant from "tiny-invariant";
import { createEphemeralQueryRun } from "../../query-run-ephemeral/query-run-ephemeral.machine";

interface TablePanelProps {
  queryId?: string;
  cellId: string;
  dashboardId: string;
}

export const createTablePanelMachine = ({ queryId, cellId, dashboardId }: TablePanelProps) => {
  const actions$ = new Subject<EpicEvent>();
  const machine = createMachine(
    {
      id: `tablePanel-${queryId}`,
      tsTypes: {} as import("./dashboard-table-panel.machine.typegen").Typegen0,
      schema: {
        context: {} as TablePanelContext,
        events: {} as TablePanelEvent | GlobalEvent | EpicEvent,
      },
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      entry: ["setupEpic"],
      context: {
        dashboardId,
        queryId,
        data: {},
        latestParameters: [],
        enteredViewport: false,
        filterString: "",
        cellId,
      },
      initial: "selecting",
      states: {
        selecting: {
          description:
            "this will determine if the panel needs to remain in selecting mode. If it doesn't have an id it'll stay here",
          always: [
            {
              target: "ready",
              cond: "hasQueryId",
            },
          ],
          on: {
            "DASHBOARD.TABLE_PANEL.CANCEL_ADD": {
              target: "idle",
              actions: ["removeCell"],
            },
            "DASHBOARD.TABLE_PANEL.SELECT_ITEM": {
              actions: ["updateCellFormula", "uploadParentCellFormula"],
              target: "loading",
            },
          },
        },
        loading: {
          id: "loading",
          initial: "request",
          states: {
            request: {
              entry: "fetchQueryDataEpic",
              on: {
                "DASHBOARD_TABLE.EPIC.FETCH_SUCCESS": {
                  target: "#loading.complete",
                  actions: ["hydrateTable"],
                },
                "DASHBOARD_TABLE.EPIC.FETCH_FAILURE": {
                  target: "#error",
                },
              },
            },
            complete: {
              always: [
                {
                  description: "one the data has loaded, check to see if params have been triggered and use them",
                  target: "#fetchingEphemeralQuery",
                  cond: "shouldUseParamData",
                },
                {
                  target: "#idle",
                },
              ],
            },
          },
        },
        ready: {
          on: {
            "GLOBAL.DASHBOARD_PARAMETERS.APPLY_PARAMETERS": {
              actions: ["updateParams"],
            },
            "DASHBOARD.TABLE_PANEL.ENTERED_VIEWPORT": [
              {
                target: "fetchingEphemeralQuery",
                actions: ["enteredViewport"],
                cond: "shouldUseParamData",
                description:
                  "if the tab is set to active, check if the panel has params to be applied and the params have been applied previously",
              },
              {
                target: "loading",
                cond: "noDataYet",
                description: "if there is no data fetch it",
                actions: ["enteredViewport"],
              },
              {
                target: "idle",
                actions: ["enteredViewport"],
              },
            ],
          },
        },
        idle: {
          id: "idle",
          on: {
            "GLOBAL.DASHBOARD_PARAMETERS.APPLY_PARAMETERS": {
              target: "fetchingEphemeralQuery",
              actions: ["updateParams"],
              cond: "hasParamsToApply",
            },
          },
        },
        fetchingEphemeralQuery: {
          id: "fetchingEphemeralQuery",
          entry: "createEphemeralQuery",
          initial: "executingQuery",
          on: {
            "GLOBAL.EPHEMERAL_QUERY_RUN.FINISHED": {
              target: "#idle",
              cond: "isQuery",
              actions: ["setQueryRunData"],
            },
          },
          states: {
            executingQuery: {
              on: {
                "GLOBAL.EPHEMERAL_QUERY_RUN.POLLING": {
                  target: "polling",
                  cond: "isQuery",
                },
              },
            },
            polling: {},
          },
        },
        error: {
          id: "error",
          after: {
            2000: "idle",
          },
        },
        refreshing: {
          on: {
            "GLOBAL.DASHBOARD.REFRESH.REFRESH_FINISHED": {
              target: "loading",
              cond: "isDashboard",
            },
          },
        },
      },
      on: {
        "GLOBAL.DASHBOARD.REFRESH.QUERY_RUN_EXECUTED": {
          target: "refreshing",
          cond: "isDashboard",
        },
        "GLOBAL.DASHBOARD.RESET_PANELS": {
          actions: "resetData",
          target: "loading",
          cond: "alreadyHasData",
        },
      },
    },
    {
      services: {
        globalEvents: () => eventBus.events$,
      },
      actions: {
        setupEpic: assign((ctx, _e) => {
          if (ctx.epic$) return {};
          const epic$ = createEpics(actions$);
          return {
            epic$: spawn(epic$),
          };
        }),
        fetchQueryDataEpic: (ctx) => {
          invariant(ctx.queryId, "Query Id must be set to fetch table data");
          actions$.next({ type: "DASHBOARD_TABLE.EPIC.FETCH", payload: { queryId: ctx.queryId } });
        },
        createEphemeralQuery: (ctx) => {
          const dashboardId = ctx.dashboardId;
          const queryId = ctx.data.query?.id;
          const statement = ctx.data.query?.statement ?? "";
          const parameters = ctx.latestParameters ?? [];
          invariant(queryId, "Query Id must be set to fetch table data");
          createEphemeralQueryRun({ queryId, statement, parameters, executionType: "DASHBOARD", dashboardId });
        },
        enteredViewport: assign((_) => ({ enteredViewport: true })),
        setQueryRunData: assign((context, event) => {
          return {
            data: {
              ...context.data,
              queryRun: event.payload.result,
            },
            latestParameters: [],
          };
        }),
        updateParams: assign((context, event) => ({
          latestParameters: event.payload.parameters,
        })),
        hydrateTable: assign((context, event) => {
          return { data: event.payload };
        }),
        removeCell: sendParent((context) => {
          return {
            type: "DASHBOARD.GRID.REMOVE_CELL",
            payload: { cellId: context.cellId },
          };
        }),
        uploadParentCellFormula: sendParent((context, event) => {
          return {
            type: "DASHBOARD.GRID.UPDATE_CELL_FORMULA",
            payload: {
              cellId: context.cellId,
              formula: { queryId: event.id },
            },
          };
        }),
        updateCellFormula: assign((context, event) => {
          return {
            queryId: event.id,
          };
        }),
        resetData: assign((_) => {
          return {
            data: {},
          };
        }),
      },
      guards: {
        hasQueryId: (context) => {
          return !!context.queryId;
        },
        hasParamsToApply: (context, event) => {
          const isDashboard = dashboardId === event.payload.dashboardId;
          return isDashboard && (context.data.query?.parameters.length ?? 0) > 0;
        },
        shouldUseParamData: (context, event) => {
          const queryParams = context.data.query?.parameters ?? [];
          const latestParams = context.latestParameters ?? [];
          return (
            queryParams.length > 0 &&
            latestParams.length > 0 &&
            (context.enteredViewport || event.type === "DASHBOARD.TABLE_PANEL.ENTERED_VIEWPORT")
          );
        },
        noDataYet: (context) => {
          return !context.data.queryRun;
        },
        isDashboard: (context, event) => {
          return dashboardId === event.payload.dashboardId;
        },
        alreadyHasData: (context, event) => {
          return !!context.data.queryRun && dashboardId === event.payload.dashboardId;
        },
        isQuery: (context, event) => {
          return context.data.query?.id === event.payload.queryId;
        },
      },
    },
  );
  return machine;
};

interface TablePanelContext {
  filterString: string;
  cellId: string;
  dashboardId: string;
  queryId?: string;
  data: { query?: query.Query; queryRun?: queryRun.QueryRunResult; owner?: Profile };
  latestParameters: CustomParameter[];
  enteredViewport: boolean;
  epic$?: AnyActorRef;
}

type TablePanelEvent =
  | {
      type: "DASHBOARD.TABLE_PANEL.ENTERED_VIEWPORT";
    }
  | {
      type: "REFRESH_QUERY_RUN_EXECUTED";
    }
  | {
      type: "DASHBOARD.TABLE_PANEL.CANCEL_ADD";
    }
  | {
      type: "DASHBOARD.TABLE_PANEL.SELECT_ITEM";
      id: string;
    };

export type TablePanelActorRef = ActorRefFrom<ReturnType<typeof createTablePanelMachine>>;
export type TablePanelState = StateFrom<ReturnType<typeof createTablePanelMachine>>;

export const useTablePanelMachine = (cellRef: TablePanelActorRef) => {
  const isLoading = useSelector(cellRef, isLoadingSelector);
  const isFetchingEphemeralQuery = useSelector(cellRef, isFetchingEphemeralQuerySelector);
  const isReady = useSelector(cellRef, isReadySelector);
  const isRefreshing = useSelector(cellRef, isRefreshingSelector);
  const isSelecting = useSelector(cellRef, isSelectingSelector);
  const data = useSelector(cellRef, dataSelector);
  const enteredViewport = useSelector(cellRef, enteredViewportSelector);

  const selectItem = (id: string) => cellRef.send({ type: "DASHBOARD.TABLE_PANEL.SELECT_ITEM", id });
  const cancelAdd = () => cellRef.send({ type: "DASHBOARD.TABLE_PANEL.CANCEL_ADD" });
  const onEnterViewport = () => cellRef.send({ type: "DASHBOARD.TABLE_PANEL.ENTERED_VIEWPORT" });
  return {
    isLoading,
    isFetchingEphemeralQuery,
    isReady,
    isRefreshing,
    isSelecting,
    data,
    enteredViewport,
    selectItem,
    cancelAdd,
    onEnterViewport,
  };
};

const isLoadingSelector = (state: TablePanelState) => state.matches("loading");
const isFetchingEphemeralQuerySelector = (state: TablePanelState) => state.matches("fetchingEphemeralQuery");
const isReadySelector = (state: TablePanelState) => state.matches("ready");
const isRefreshingSelector = (state: TablePanelState) => state.matches("refreshing");
const isSelectingSelector = (state: TablePanelState) => state.matches("selecting");

const dataSelector = (state: TablePanelState) => state.context.data;
const enteredViewportSelector = (state: TablePanelState) => state.context.enteredViewport;
