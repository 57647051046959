import { createMachine } from "xstate";
import type { OutputEvent as EpicEvent } from "./epic";
import { Visualization, VisualizationUpdate } from "@fscrypto/domain/visualization";
import { VisualizationRoomCustomEvent } from "../visualization-room/machine";
import { GlobalEventSelector } from "~/state/events";

interface Context {
  visualization: Visualization;
  visId: string;
}

// A union of all events externally sent to the actor
type RefEvent =
  | { type: "VISUALIZATION.UPDATE"; payload: { visualization: VisualizationUpdate } }
  | { type: "VISUALIZATION.DELETE" }
  | { type: "VISUALIZATION.FETCH_DATA" }
  | { type: "VISUALIZATION.UPDATE_TITLE"; payload: { visualization: { title: string } } };

type Event =
  | RefEvent
  | EpicEvent
  | GlobalEventSelector<
      | "GLOBAL.WORK_ITEM.UPDATE_REQUEST"
      | "GLOBAL.VISUALIZATION.REALTIME_STORAGE_UPDATE"
      | "GLOBAL.QUERY_VISUALIZATION_PANEL.DELETE_VISUALIZATION"
    >;

// All dependencies necessary for initializing the actor
export interface Deps {
  visualization: Visualization;
  visId: string;
}

export type SystemKey = `visualization-${string}-root`;

export type EventBusEvent =
  | { type: "GLOBAL.VISUALIZATION.ENTER"; payload: { query: { id: string }; visualization: { id: string } } }
  | { type: "GLOBAL.VISUALIZATION.EXIT"; payload: { query: { id: string }; visualization: { id: string } } }
  | { type: "GLOBAL.VISUALIZATION.DELETE"; payload: { visualization: Visualization; query: { id: string } } }
  | { type: "GLOBAL.VISUALIZATION.UPDATE"; payload: { visualization: Visualization; query: { id: string } } }
  | VisualizationRoomCustomEvent;

export const createVisualizationRootMachine = (id: SystemKey, deps: Deps) => {
  return createMachine({
    id,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    initial: "idle",
    context: {
      visualization: deps.visualization,
      visId: deps.visId,
    },
    on: {
      "VISUALIZATION.UPDATE": {
        actions: ["updateVisualization", "broadcastUpdate"],
        target: "saving",
      },
      "VISUALIZATION.UPDATE_TITLE": {
        actions: ["updateVisualization", "broadcastUpdate"],
        target: "saving",
      },
      "GLOBAL.WORK_ITEM.UPDATE_REQUEST": {
        actions: ["setVisData", "broadcastUpdate"],
        cond: "isWorkItemId",
      },
      "GLOBAL.VISUALIZATION.REALTIME_STORAGE_UPDATE": {
        actions: ["updateVisualization"],
        cond: "isVisualization",
      },
      "GLOBAL.QUERY_VISUALIZATION_PANEL.DELETE_VISUALIZATION": {
        target: "deleted",
        cond: "isVisualization",
      },
    },
    states: {
      idle: {
        on: {
          "VISUALIZATION.DELETE": {
            target: "deleting",
          },
        },
      },
      saving: {
        entry: ["persistVisualizationEpic"],
        on: {
          "VISUALIZATION.EPIC.UPDATE_SUCCESS": {
            target: "idle",
          },
          "VISUALIZATION.EPIC.UPDATE_FAILURE": {
            target: "idle",
          },
        },
      },
      deleting: {
        entry: ["deleteVisEpic"],
        on: {
          "VISUALIZATION.EPIC.DELETE_SUCCESS": {
            actions: ["broadcastDelete", "trackingDelete"],
            target: "deleted",
          },
          "VISUALIZATION.EPIC.DELETE_FAILURE": {
            target: "idle",
          },
        },
      },
      deleted: {
        type: "final",
      },
    },
  });
};

export type Options = Parameters<ReturnType<typeof createVisualizationRootMachine>["withConfig"]>["0"];
