import type { BaseUserMeta, JsonObject, Client as LiveblocksClient, LsonObject } from "@liveblocks/client";
import { createClient } from "@liveblocks/client";

type EnterOptions<TPresence extends JsonObject, TStorage extends LsonObject = LsonObject> = {
  initialPresence: TPresence | ((roomId: string) => TPresence);
  initialStorage?: TStorage | ((roomId: string) => TStorage);
};

const createLiveblocksClient = () => {
  let liveblocksClient: LiveblocksClient | null = null;
  return {
    client: () => liveblocksClient,
    enterRoom: <
      TPresence extends JsonObject,
      TStorage extends LsonObject = LsonObject,
      TUserMeta extends BaseUserMeta = BaseUserMeta,
    >(
      roomId: string,
      options: EnterOptions<TPresence, TStorage>,
    ) => {
      // invariant(liveblocksClient !== null, "liveblocks client must be initialized before creating a room");
      if (liveblocksClient === null) {
        liveblocksClient = createClient({
          authEndpoint: "/api/liveblocks-auth",
        });
      }
      const { room, leave } = liveblocksClient.enterRoom<TPresence, TStorage, TUserMeta>(roomId, options);
      return { room, leave };
    },
    isInitialized: () => liveblocksClient !== null,
  };
};

export const liveblocksClient = createLiveblocksClient();
